import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, getIn } from 'formik';
import { Row, Col, Form, Accordion, Card } from 'react-bootstrap';
import * as Yup from 'yup';
import EmployeePrevisionTab, { yupPrevision } from './EmployeePrevisionTab';
import { rutFormat, validRutInput, isAlphanumeric } from '../../../services/utils';
import Icon from '../../../components/Icons';
import {
  BankAccount,
  CheckBoxBtn,
  FormikCheckBox,
  FormikDatePicker,
  FormikInput,
  FormikNumber,
  FormikSelect,
  FormikMaterialUiTimePicker,
  InputSelect,
  NestedAttributes,
  SimpleCenteredModal,
  UploadFile,
  FormikRangePicker,
  RegionCommune
} from '../../../components';
import {
  contractTypes,
  foreignTechnicianOptions,
  gratificationTypes,
  gratificationPeriods,
  gratificationConditions,
  incomeTaxTypes,
  paymentSchedules,
  workSchedules,
  workScheduleTooltipText,
  workerQualities,
  disabilityOptions
} from './FormOptions';

import { indexBalancesRequest, debounceIndexBalancesRequest } from '../../../requests/balances';
import { debounceIndexBranchOfficesRequest } from '../../../requests/branchOffices';
import { debounceIndexSubsidiariesRequest } from '../../../requests/subsidiaries';
import { debounceIndexCostCentersRequest } from '../../../requests/costCenters';
import { debounceIndexEmployeesRequest } from '../../../requests/employees';
import { debounceIndexJobManagementsRequest } from '../../../requests/jobManagements';
import { debounceIndexShiftsRequest } from '../../../requests/shifts';
import { debounceIndexMarkingTypesRequest } from '../../../requests/markingTypes';

import { indexJobTitleRequest } from '../../../requests/jobTitles';
import { debounceIndexSyndicatesRequest } from '../../../requests/syndicates';
import { indexDocumentTemplateRequest } from '../../../requests/documentTemplates';
import { AbilityContext } from '../../../config/abilityContext';
import '../../../services/yupCustomMethods';
import CustomFieldTab from './CustomFieldTab';
import FamilyGroupTab, { yupFamilyGroup } from './FamilyGroupTab';
import BalanceInput from './BalanceInput';
import WithHoldings from './WithHoldings';

const unitOfAccounts = [
  { value: 'pesos', label: '$' },
  { value: 'uf', label: 'UF' },
  { value: 'percentage', label: '%' },
  { value: 'utm', label: 'UTM' }
];

class contractTab extends Component {
  state = {
    assets: [],
    discounts: [],
    retentions: [],
    simpleModalShow: false,
    benefitsTouched: false,
    othersTouched: false,
    modalTitle: '',
    modalBody: {}
  };

  componentDidMount() {
    this.fetchInitialBalance('asset');
    this.fetchInitialBalance('discount', { filter_type: 'discount', filter_retention: false });
    this.fetchInitialBalance('retention', { filter_type: 'discount', filter_retention: true });
  }

  fetchInitialBalance = (type, customParams = {}) => {
    const { dispatch } = this.props;
    indexBalancesRequest({
      dispatch,
      params: {
        actives: true,
        filter_type: type,
        sort_column: 'name',
        paginate: false,
        filter_contractual: true,
        ...customParams
      },
      successCallback: response => {
        const options = this.resultFetchData(response);
        this.setState({ [`${type}s`]: options });
      }
    });
  };

  fetchBalanceAssets = (inputValue, callback) => {
    const { dispatch } = this.props;
    debounceIndexBalancesRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        filter_type: 'asset',
        sort_column: 'name',
        show_payroll: false,
        filter_contractual: true,
        paginate: false
      },
      successCallback: response => {
        callback(this.resultFetchData(response));
      }
    });
  };

  fetchBalanceDiscounts = (inputValue, callback, customParams = {}) => {
    const { dispatch } = this.props;
    debounceIndexBalancesRequest({
      dispatch,
      params: {
        query: inputValue,
        filter_type: 'discount',
        sort_column: 'name',
        show_payroll: false,
        paginate: false,
        filter_contractual: true,
        ...customParams
      },
      successCallback: response => {
        callback(this.resultFetchData(response));
      }
    });
  };

  fetchBranchOffices = (inputValue, callback) => {
    const { dispatch } = this.props;
    debounceIndexBranchOfficesRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: data => {
        callback(this.resultFetchData(data));
      }
    });
  };

  fetchSubsidiaries = (inputValue, callback) => {
    const { dispatch } = this.props;
    debounceIndexSubsidiariesRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: data => {
        callback(this.resultFetchData(data));
      }
    });
  };

  fetchCostCenters = (inputValue, callback) => {
    const { dispatch } = this.props;
    debounceIndexCostCentersRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 50
      },
      successCallback: data => {
        callback(this.resultFetchData(data));
      }
    });
  };

  fetchDocumentTemplates = (inputValue, callback) => {
    const { dispatch } = this.props;
    indexDocumentTemplateRequest({
      dispatch,
      params: {
        query: inputValue,
        template_type: 'work_contract',
        sort_column: 'name',
        display_length: 150
      },
      successCallback: response => callback(response.data?.data)
    });
  };

  fetchEmployees = (inputValue, callback) => {
    const { dispatch, employee } = this.props;
    debounceIndexEmployeesRequest({
      dispatch,
      params: {
        exclude_id: employee.id,
        filter_name: inputValue,
        sort_column: 'name',
        is_dt: false,
        paginate: false,
        active: true,
        active_contracts: true
      },
      successCallback: response => {
        callback(response.data.data);
      }
    });
  };

  fetchJobManagments = (inputValue, callback) => {
    const { dispatch } = this.props;
    debounceIndexJobManagementsRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        sort_column: 'name',
        display_length: 500
      },
      successCallback: data => {
        callback(this.resultFetchData(data));
      }
    });
  };

  fetchContractShift = (inputValue, callback) => {
    const { dispatch } = this.props;
    debounceIndexShiftsRequest({
      dispatch,
      params: {
        query: inputValue,
        sort_column: 'name',
        display_length: 40,
        active: true
      },
      successCallback: data => {
        callback(this.resultFetchData(data));
      }
    });
  };

  fetchMarkingTypes = (inputValue, callback) => {
    const { dispatch } = this.props;
    debounceIndexMarkingTypesRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: data => {
        callback(this.resultFetchData(data));
      }
    });
  };

  fetchJobTitles = (inputValue, callback) => {
    const { dispatch } = this.props;
    let type = false;
    indexJobTitleRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        display_length: 500,
        type
      },
      successCallback: data => {
        callback(this.resultFetchData(data, (type = true)));
      }
    });
  };

  fetchSyndicates = (inputValue, callback) => {
    const { dispatch } = this.props;
    debounceIndexSyndicatesRequest({
      dispatch,
      params: {
        query: inputValue,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: data => {
        callback(this.resultFetchData(data));
      }
    });
  };

  resultFetchData = (response, type) => {
    const result = response.data.data;
    return result.map(element => ({
      ...element,
      label: `${type ? `${element.code} - ` : ''} ${element.name}`,
      value: element.id
    }));
  };

  balancesInputs = () => {
    const {
      contract,
      errors,
      touched,
      values,
      setFieldValue,
      canCreateAsset,
      canCreateDiscount,
      canDestroyAsset,
      canDestroyDiscount
    } = this.props;
    const { assets, discounts } = this.state;
    const { contract: vContract } = values.employee;
    const { contractBalancesAttributes: valuesAttributes } = vContract;
    const { contractBalancesAttributes } = contract;
    const resultAssets = [];
    const resultDiscounts = [];

    valuesAttributes.forEach((body, index) => {
      if (body._destroy) {
        resultAssets.push(undefined);
        resultDiscounts.push(undefined);
        return;
      }
      const title = body.balanceType === 'asset' ? 'Haber' : 'Descuento';
      const key =
        body.balanceType === 'asset' ? `balance-asset-${index.toString()}` : `balance-discount-${index.toString()}`;
      const fetch = body.balanceType === 'asset' ? this.fetchBalanceAssets : this.fetchBalanceDiscounts;
      const defaultOptions = body.balanceType === 'asset' ? assets : discounts;
      const defaultValue = this.initialValueBalance(contractBalancesAttributes, body);
      const unitOfAccountValue = body.unitOfAccount;
      const addon = unitOfAccounts.find(unit => unit.value === body.unitOfAccount)?.label;
      const disabledAmount = false;
      const response = (
        <BalanceInput
          contractValuePath="contractBalancesAttributes"
          key={key}
          addon={addon}
          defaultOptions={defaultOptions}
          defaultValue={defaultValue}
          disabledAmount={disabledAmount}
          unitOfAccounts={unitOfAccounts}
          unitOfAccountValue={unitOfAccountValue}
          errors={errors}
          fetch={fetch}
          index={index}
          setFieldValue={setFieldValue}
          touched={touched}
          title={title}
          startDate={body.startDate}
          endDate={body.endDate}
        />
      );
      if (body.balanceType === 'asset') {
        resultAssets.push(response);
        resultDiscounts.push(undefined);
      } else {
        resultAssets.push(undefined);
        resultDiscounts.push(response);
      }
    });

    resultAssets.push(
      <>
        <Col md={3} className="sample-row">
          <FormikSelect label="Haber" placeholder="Seleccionar Haber" isDisabled />
        </Col>
        <Col md={2} className="sample-row">
          <FormikSelect label="Moneda" placeholder="Seleccionar Moneda" isDisabled />
        </Col>
        <Col md={2} className="sample-row">
          <FormikInput label="Monto" leftAddon="$" disabled />
        </Col>
        <Col md={4} className="sample-row">
          <FormikRangePicker startDateName="" endDateName="" labelLeft="Desde" labelRight="Hasta" showClearDates />
        </Col>
      </>
    );

    resultDiscounts.push(
      <>
        <Col md={3} className="sample-row">
          <FormikSelect label="Descuento" placeholder="Seleccionar Descuento" isDisabled />
        </Col>
        <Col md={2} className="sample-row">
          <FormikSelect label="Moneda" placeholder="Seleccionar Moneda" isDisabled />
        </Col>
        <Col md={2} className="sample-row">
          <FormikInput label="Monto" leftAddon="$" disabled />
        </Col>
        <Col md={4} className="sample-row">
          <FormikRangePicker startDateName="" endDateName="" labelLeft="Desde" labelRight="Hasta" showClearDates />
        </Col>
      </>
    );

    return (
      <>
        <NestedAttributes
          mapInputs={resultAssets}
          arrayValues={valuesAttributes}
          setFieldValue={setFieldValue}
          valuePath="employee[contract][contractBalancesAttributes]"
          newAttributes={{ balanceId: '', amount: '', balanceType: 'asset' }}
          removeFirstItem
          canCreate={canCreateAsset}
          canDestroy={canDestroyAsset}
        />
        <h4 className="text-uppercase mb-1 mt-2">Descuentos</h4>
        <NestedAttributes
          mapInputs={resultDiscounts}
          arrayValues={valuesAttributes}
          setFieldValue={setFieldValue}
          valuePath="employee[contract][contractBalancesAttributes]"
          newAttributes={{ balanceId: '', amount: '', balanceType: 'discount' }}
          removeFirstItem
          canCreate={canCreateDiscount}
          canDestroy={canDestroyDiscount}
        />
      </>
    );
  };

  handleNewMulti = (data, field, allowEmpty = false) => {
    const { setFieldValue } = this.props;
    const newData = data.map(element => element.value);
    if (allowEmpty && !newData.length) {
      newData.push('');
    }
    setFieldValue(field, newData);
  };

  initialValueBalance = (attributes, value) => {
    if (value && value.id) {
      const vAttribute = attributes.filter(e => value.balanceId === e.balanceId);
      if (vAttribute.length) {
        return { label: vAttribute[0].balanceName, value: value.balanceId };
      }
    }
    return undefined;
  };

  showModalTooltip = (title, body) => {
    this.setState({
      simpleModalShow: true,
      modalTitle: title,
      modalBody: body
    });
  };

  handleModalClose = () => {
    this.setState({ simpleModalShow: false });
  };

  handleBenefitsTouched = () => {
    const { benefitsTouched } = this.state;

    if (benefitsTouched) return;
    this.setState({ benefitsTouched: true });
  };

  handleOthersTouched = () => {
    const { othersTouched } = this.state;

    if (othersTouched) return;
    this.setState({ othersTouched: true });
  };

  handleOutSourceNationalIdentification = e => {
    const { setFieldValue } = this.props;
    if (validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  setGratificationType = (field, data) => {
    const { setFieldValue } = this.props;
    if (!data?.value || data.value === '') {
      setFieldValue('employee[contract][gratificationPeriod]', '');
    } else if (data.value === 'unsure') {
      setFieldValue('employee[contract][gratificationPeriod]', 'annual');
    } else if (data.value === 'without_gratification') {
      setFieldValue('employee[contract][gratificationPeriod]', '');
    }
    setFieldValue('employee[contract][gratificationCondition]', '');
    setFieldValue(field.name, data ? data.value : '');
  };

  render() {
    const {
      errors,
      touched,
      setFieldValue,
      setFieldTouched,
      values,
      contract,
      contractCustomFields,
      canPrevision,
      setAccordion,
      accordion,
      remunerations
    } = this.props;

    const { benefitsTouched, simpleModalShow, othersTouched, modalTitle, modalBody } = this.state;
    const {
      employee: { contract: vContract }
    } = values;
    const {
      contractType,
      workSchedule,
      foreignTechnician,
      gratificationType,
      gratificationPeriod,
      gratificationCondition,
      incomeTaxType,
      paymentSchedule,
      workerQuality,
      disability
    } = contract;
    const { context } = this;
    const reqGratification =
      remunerations && (vContract.gratificationType === 'unsure' || vContract.gratificationType === 'guaranteed');
    return (
      <>
        <Accordion defaultActiveKey="0" className="ml-2 mt-2 mr-2">
          <Col
            className={`${accordion === 'contract' || accordion === 'profile' ? 'top-header-green' : 'top-header-light-gray'
              } br-5`}
          >
            <Accordion.Toggle
              as={Col}
              eventKey="0"
              onClick={() => setAccordion('contract')}
              className="card-header-title text-uppercase"
            >
              Contrato {vContract.correlativeIdentification && `N°${vContract.correlativeIdentification}`}
            </Accordion.Toggle>
          </Col>

          <Accordion.Collapse eventKey="0">
            <Card.Body className="div-content">
              <Row>
                <Col md={4}>
                  <Field name="employee[contract][jobManagementId]">
                    {({ field }) => (
                      <InputSelect
                        {...field}
                        label="Área"
                        placeholder="Seleccionar Área"
                        values={values.employee.contract}
                        model={[contract, 'jobManagement']}
                        request={this.fetchJobManagments}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="employee[contract][branchOfficeIds]">
                    {({ field }) => (
                      <InputSelect
                        {...field}
                        abbr
                        isMulti
                        label="Lugar de Prestación de Servicios"
                        placeholder="Seleccionar Lugar de Prestación de Servicios"
                        values={values.employee.contract}
                        model={[contract, 'branchOffice']}
                        request={this.fetchBranchOffices}
                        onChange={data => this.handleNewMulti(data || [], field.name, true)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="employee[contract][costCenterId]">
                    {({ field }) => (
                      <InputSelect
                        {...field}
                        label="Centro de Costos"
                        placeholder="Seleccionar Centro de Costos"
                        values={values.employee.contract}
                        model={[contract, 'costCenter']}
                        request={this.fetchCostCenters}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Field name="employee[contract][jobTitleId]">
                    {({ field }) => (
                      <InputSelect
                        {...field}
                        abbr
                        label="Cargo"
                        placeholder="Seleccionar Cargo"
                        values={values.employee.contract}
                        model={[contract, 'jobTitle']}
                        request={this.fetchJobTitles}
                        onChange={data => setFieldValue(field.name, data.value)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="employee[contract][parentId]">
                    {({ field }) => (
                      <InputSelect
                        {...field}
                        label="Jefe"
                        placeholder="Seleccionar Jefe"
                        tooltipText="Este campo afectará a la visualización en el organigrama y a las solicitudes que genere el sistema"
                        values={values.employee.contract}
                        model={[contract, 'parent']}
                        request={this.fetchEmployees}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        isClearable
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="employee[contract][subsidiaryId]">
                    {({ field }) => (
                      <InputSelect
                        {...field}
                        label="Sucursal"
                        placeholder="Seleccionar la Sucursal"
                        values={values.employee.contract}
                        model={[contract, 'subsidiary']}
                        request={this.fetchSubsidiaries}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={4}>
                  <Field name="employee[contract][workerQuality]">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr
                        label="Calidad del Trabajador"
                        placeholder="Seleccionar Calidad del Trabajador"
                        options={workerQualities}
                        defaultValue={workerQuality}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="employee[contract][contractType]">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr
                        label="Tipo de Contrato"
                        placeholder="Seleccionar Tipo de Contrato"
                        options={contractTypes}
                        defaultValue={contractType}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="employee[contract][documentTemplateId]">
                    {({ field }) => (
                      <InputSelect
                        {...field}
                        label="Plantilla Contrato"
                        placeholder="Seleccionar Plantilla Contrato"
                        values={values.employee.contract}
                        model={[contract, 'documentTemplate']}
                        request={this.fetchDocumentTemplates}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Field name="employee[contract][startDate]">
                    {({ field }) => (
                      <FormikDatePicker
                        name={field.name}
                        value={field.value}
                        abbr
                        isOutsideRange={() => false}
                        label="Fecha de Inicio"
                        placeholder="dd/mm/aaaa"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                {vContract.contractType !== 'undefined_term' && (
                  <Col md={4}>
                    <Field name="employee[contract][endDate]">
                      {({ field }) => (
                        <FormikDatePicker
                          name={field.name}
                          value={field.value}
                          abbr
                          isOutsideRange={() => false}
                          label="Fecha de Término"
                          placeholder="dd/mm/aaaa"
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                )}
                <Col md={4}>
                  <Field name="employee[contract][weeklyHours]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        abbr
                        inputType="number"
                        min={0}
                        max={45}
                        label="Horas Semanales"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="employee[contract][syndicateId]">
                    {({ field }) => (
                      <InputSelect
                        {...field}
                        label="Sindicato"
                        placeholder="Seleccionar Sindicato"
                        values={values.employee.contract}
                        model={[contract, 'syndicate']}
                        request={this.fetchSyndicates}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        isClearable
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="employee[contract][incomeTaxType]">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        label="Tipo de Impuesto a la Renta"
                        placeholder="Seleccionar Tipo de Impuesto a la Renta"
                        options={incomeTaxTypes}
                        defaultValue={incomeTaxType}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="employee[contract][foreignTechnician]">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        label="T.E. exención de cotización"
                        tooltipText=" En este campo se debe ingresar si es Técnico Extranjero con exención de cotizaciones previsionales"
                        placeholder="Seleccione Técnico Extranjero exención de cotizaciones previsionales"
                        options={foreignTechnicianOptions}
                        defaultValue={foreignTechnician}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <Row>
                {context.can('private_role', 'Employee') && (
                  <Col md={4}>
                    <Field name="employee[contract][privateRole]">
                      {({ field }) => (
                        <FormikCheckBox
                          {...field}
                          margin="mt-4"
                          field={field}
                          label="Rol Privado"
                          type="switch"
                          tooltipText="Al habilitar este campo el sueldo del trabajador quedará oculto para todos los que no tengan el rol privado entre sus perfiles"
                        />
                      )}
                    </Field>
                  </Col>
                )}
              </Row>

              <Row>
                <Col md={2} className="d-flex align-items-center mt-2">
                  <Field name="employee[contract][outSourced]">
                    {({ field }) => (
                      <FormikCheckBox {...field} field={field} label="SubContratado" custom tooltipText="" />
                    )}
                  </Field>
                </Col>
              </Row>

              {vContract.outSourced && (
                <>
                  <Row>
                    <Col md={4}>
                      <Field name="employee[contract][outSourceNationalIdentification]">
                        {({ field }) => (
                          <FormikInput
                            {...field}
                            abbr
                            label="Rut"
                            onChange={e =>
                              this.handleOutSourceNationalIdentification(
                                e,
                                values.employee.identificationType === 'rut'
                              )
                            }
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>

                    <Col md={4}>
                      <Field name="employee[contract][outSourceBusinessName]">
                        {({ field }) => (
                          <FormikInput
                            {...field}
                            abbr
                            label="Razón social de la empresa"
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>

                    <Col md={4}>
                      <Field name="employee[contract][outSourceEstablishmentName]">
                        {({ field }) => (
                          <FormikInput
                            {...field}
                            abbr
                            label="Nombre de establecimiento"
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Field name="employee[contract][outSourceAddress]">
                        {({ field }) => (
                          <FormikInput
                            {...field}
                            abbr
                            label="Dirección"
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col md={2}>
                      <Field name="employee[contract][outSourceAddressNumber]">
                        {({ field }) => (
                          <FormikInput
                            {...field}
                            abbr
                            label="Número"
                            inputType="number"
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col md={2}>
                      <Field name="employee[contract][outSourceAddressFlat]">
                        {({ field }) => (
                          <FormikInput
                            {...field}
                            label="Piso"
                            inputType="number"
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col md={2}>
                      <Field name="employee[contract][outSourceAddressOffice]">
                        {({ field }) => (
                          <FormikInput
                            {...field}
                            label="Oficina"
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                    <RegionCommune
                      regionAbbr
                      communeAbbr
                      modelKey="employee[contract]"
                      nameFieldCommune="outSourceCommuneId"
                      nameFieldRegion="outSourceRegionId"
                    />
                  </Row>
                </>
              )}

              <Row>
                <Col md={4} className="mt-4">
                  <Field name="employee[contract][outSourceExceptionalSystem]">
                    {({ field }) => (
                      <FormikCheckBox {...field} field={field} label="Afecto a un sistema excepcional" custom />
                    )}
                  </Field>
                </Col>
                {vContract.outSourceExceptionalSystem && (
                  <>
                    <Col md={4}>
                      <Field name="employee[contract][outSourceExceptionalSystemNumber]">
                        {({ field }) => (
                          <FormikInput
                            {...field}
                            abbr
                            label="Número de sistema excepcional"
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                  </>
                )}
              </Row>
            </Card.Body>
          </Accordion.Collapse>

          <Col className={`${accordion === 'journey' ? 'top-header-green' : 'top-header-light-gray'} br-5 mt-2`}>
            <Accordion.Toggle
              as={Col}
              eventKey="1"
              onClick={() => setAccordion('journey')}
              className="card-header-title text-uppercase"
            >
              JORNADA
            </Accordion.Toggle>
          </Col>

          <Accordion.Collapse eventKey="1">
            <Card.Body className="div-content">
              <Row>
                <Col md={5}>
                  <Field name="employee[contract][workSchedule]">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr
                        label="Tipo de Jornada"
                        placeholder="Seleccionar Tipo de Jornada"
                        options={workSchedules}
                        defaultValue={workSchedule}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={1} className="d-flex align-items-center">
                  <Form.Label>
                    <Icon
                      className="ml-1"
                      height="20px"
                      icon="help-circle"
                      style={{ cursor: 'pointer' }}
                      width="20px"
                      onClick={() =>
                        this.showModalTooltip(
                          'Distribuciones de Jornada',
                          <>
                            <ol type="a">
                              <li>{workScheduleTooltipText.hour45}</li>
                              <br />
                              <li>{workScheduleTooltipText.article22}</li>
                              <br />
                              <li>{workScheduleTooltipText.partTime}</li>
                            </ol>
                          </>
                        )
                      }
                    />
                  </Form.Label>
                </Col>
                <Col md={6}>
                  <Form.Group className={`button-group ${errors ? 'is-invalid' : ''}`}>
                    <Form.Label>
                      Distribución de la Jornada
                      {vContract.workSchedule !== 'article_22' && <abbr className="text-danger ml-1">*</abbr>}
                    </Form.Label>
                    <Row className="px-3">
                      <Field name="employee[contract][monday]">
                        {({ field }) => <CheckBoxBtn {...field} id="monday" label="Lu" checked={vContract.monday} />}
                      </Field>
                      <Field name="employee[contract][tuesday]">
                        {({ field }) => <CheckBoxBtn {...field} id="tuesday" label="Ma" checked={vContract.tuesday} />}
                      </Field>
                      <Field name="employee[contract][wednesday]">
                        {({ field }) => (
                          <CheckBoxBtn {...field} id="wednesday" label="Mi" checked={vContract.wednesday} />
                        )}
                      </Field>
                      <Field name="employee[contract][thursday]">
                        {({ field }) => (
                          <CheckBoxBtn {...field} id="thursday" label="Ju" checked={vContract.thursday} />
                        )}
                      </Field>
                      <Field name="employee[contract][friday]">
                        {({ field }) => <CheckBoxBtn {...field} id="friday" label="Vi" checked={vContract.friday} />}
                      </Field>
                      <Field name="employee[contract][saturday]">
                        {({ field }) => (
                          <CheckBoxBtn {...field} id="saturday" label="Sa" checked={vContract.saturday} />
                        )}
                      </Field>
                      <Field name="employee[contract][sunday]">
                        {({ field }) => <CheckBoxBtn {...field} id="sunday" label="Do" checked={vContract.sunday} />}
                      </Field>
                    </Row>
                    {getIn(errors, 'employee.contract.errorDays') && (
                      <Form.Text className="text-danger">{getIn(errors, 'employee.contract.errorDays')}</Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Field name="employee[contract][workStartTime]">
                    {({ field }) => (
                      <FormikMaterialUiTimePicker
                        {...field}
                        abbr={vContract.workSchedule && vContract.workSchedule !== 'article_22'}
                        timeSelector
                        label="Hora de Entrada"
                        onChange={time => setFieldValue(field.name, time)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={6}>
                  <Field name="employee[contract][workEndTime]">
                    {({ field }) => (
                      <FormikMaterialUiTimePicker
                        {...field}
                        abbr={vContract.workSchedule && vContract.workSchedule !== 'article_22'}
                        timeSelector
                        label="Hora de Salida"
                        onChange={time => setFieldValue(field.name, time)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col md={3} className="mt-4">
                  <Field name="employee[contract][checkAssistance]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Marca Asistencia" custom />}
                  </Field>
                </Col>
                {vContract.checkAssistance && (
                  <>
                    <Col md={3} className="mt-4">
                      <Field name="employee[contract][presenceCheckAssistance]">
                        {({ field }) => <FormikCheckBox {...field} field={field} label="Marcaje Presencial" custom />}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field name="employee[contract][markingTypeIds]">
                        {({ field }) => (
                          <InputSelect
                            {...field}
                            abbr
                            isMulti
                            label="Tipo de Marcaje"
                            placeholder="Seleccionar Tipo de Marcaje"
                            values={values.employee.contract || {}}
                            model={[contract, 'markingType']}
                            request={this.fetchMarkingTypes}
                            onChange={data => this.handleNewMulti(data || [], field.name, true)}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                {vContract.workSchedule !== 'article_22' && (
                  <Col md={6}>
                    <Field name="employee[contract][shiftIds]">
                      {({ field }) => (
                        <InputSelect
                          {...field}
                          isMulti
                          label="Turno"
                          placeholder="Seleccionar Turno"
                          values={values.employee.contract}
                          model={[contract, 'shift']}
                          request={this.fetchContractShift}
                          onChange={data => this.handleNewMulti(data || [], field.name, true)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                )}
                <Col md={3}>
                  <Field name="employee[contract][telecommuting]">
                    {({ field }) => (
                      <FormikCheckBox
                        {...field}
                        margin="mt-4"
                        field={field}
                        label="Teletrabajo"
                        type="switch"
                        tooltipText="Indica si el colaborador cumple jornada bajo la modalidad de Teletrabajo"
                      />
                    )}
                  </Field>
                </Col>
                <Col md={3}>
                  <Field name="employee[contract][vacationCalculationArticle67]">
                    {({ field }) => (
                      <FormikCheckBox
                        {...field}
                        margin="mt-4"
                        field={field}
                        label="Cálculo de Vacaciones Art. 67"
                        type="switch"
                        tooltipText="Indica si al colaborador se le calcularán las vacaciones conforme al Artículo 67 del Código del Trabajo."
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Field name="employee[contract][disability]">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr
                        label="Discapacidad"
                        menuPlacement="top"
                        placeholder="Seleccionar Discapacidad"
                        options={disabilityOptions}
                        isClearable
                        defaultValue={disability}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={1} className="d-flex align-items-center">
                  <Form.Label>
                    <Icon
                      className="ml-1"
                      height="20px"
                      icon="help-circle"
                      style={{ cursor: 'pointer' }}
                      width="20px"
                      onClick={() =>
                        this.showModalTooltip(
                          'Discapacidad',
                          <>
                            <ol type="a">
                              <li>
                                La ley 21.015 de inclusión exige a los organismos públicos y las empresas con 100 o más
                                trabajadores y trabajadoras deberán contratar al menos el 1% de personas con
                                discapacidad. Para ser beneficiario de esta ley, debe poseer una Pensión de Invalidez de
                                cualquier régimen previsional o tener cualquier discapacidad calificada previamente por
                                las Comisiones de Medicina Preventiva e Invalidez (COMPIN), dependientes del Ministerio
                                de Salud y a las instituciones públicas o privadas, reconocidas para estos efectos por
                                ese Ministerio.
                              </li>
                            </ol>
                          </>
                        )
                      }
                    />
                  </Form.Label>
                </Col>
                {vContract.disability && vContract.disability !== 'no_disability' && (
                  <>
                    <Col md={4}>
                      <Field name="employee[contract][disabilityNationalIdentification]">
                        {({ field }) => (
                          <FormikInput
                            {...field}
                            abbr
                            label="Número de Credencial de Discapacidad"
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col md={4}>
                      <Field name="employee[contract][disabilityCertificate]">
                        {({ field }) => (
                          <UploadFile
                            {...field}
                            label="Certificado de Discapacidad"
                            tooltipText="Solo formato PDF permitido"
                            formats={['.pdf']}
                            name={vContract.disabilityFileInfo?.filename || 'Adjuntar Certificado de Discapacidad'}
                            onChange={file => setFieldValue(field.name, file)}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                            isClearable
                          />
                        )}
                      </Field>
                    </Col>
                  </>
                )}
              </Row>
            </Card.Body>
          </Accordion.Collapse>

          <Col className={`${accordion === 'salary' ? 'top-header-green' : 'top-header-light-gray'} br-5 mt-2`}>
            <Accordion.Toggle
              as={Col}
              eventKey="2"
              onClick={() => setAccordion('salary')}
              className="card-header-title text-uppercase"
            >
              SUELDO
            </Accordion.Toggle>
          </Col>

          <Accordion.Collapse eventKey="2">
            <Card.Body className="div-content">
              <Row>
                {context.can('private_role', 'Employee') && (
                  <Col md={4}>
                    <Field name="employee[contract][parsedSalary]">
                      {({ field }) => (
                        <FormikNumber
                          {...field}
                          abbr={remunerations}
                          leftAddon="$"
                          fieldName="employee[contract][salary]"
                          value={vContract.salary}
                          setFieldValue={setFieldValue}
                          label="Sueldo Base"
                          errors={errors}
                          touched={touched}
                        />
                      )}
                    </Field>
                  </Col>
                )}

                <Col md={4}>
                  <Field name="employee[contract][paymentSchedule]">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr={remunerations}
                        isClearable
                        label="Tipo de Pago"
                        placeholder="Seleccionar Tipo de Pago"
                        options={paymentSchedules}
                        defaultValue={paymentSchedule}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                <BankAccount
                  modelKey="employee[contract]"
                  currentModel={contract}
                  paymentTypeAbbr={remunerations}
                  remunerations={remunerations}
                />
              </Row>
              <Row>
                <Col md={4}>
                  <Field name="employee[contract][gratificationType]">
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr={remunerations}
                        isClearable
                        label="Tipo de Gratificación"
                        placeholder="Seleccionar Tipo de Gratificación"
                        options={gratificationTypes}
                        defaultValue={gratificationType}
                        onChange={data => this.setGratificationType(field, data)}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                {vContract.gratificationType === 'guaranteed' && (
                  <>
                    <Col md={4}>
                      <Field name="employee[contract][gratificationPeriod]">
                        {({ field }) => (
                          <FormikSelect
                            {...field}
                            abbr={reqGratification}
                            label="Régimen de Gratificación"
                            placeholder="Seleccionar régimen de gratificación"
                            options={gratificationPeriods}
                            defaultValue={gratificationPeriod}
                            onChange={data => setFieldValue(field.name, data ? data.value : '')}
                            setFieldTouched={() => setFieldTouched(field.name)}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col md={4}>
                      <Field name="employee[contract][gratificationCondition]">
                        {({ field }) => (
                          <FormikSelect
                            {...field}
                            abbr={remunerations}
                            label="Base de Cálculo de Gratificación"
                            placeholder="Seleccionar base de cálculo"
                            options={gratificationConditions}
                            defaultValue={gratificationCondition}
                            onChange={data => setFieldValue(field.name, data ? data.value : '')}
                            setFieldTouched={() => setFieldTouched(field.name)}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                  </>
                )}
              </Row>
            </Card.Body>
          </Accordion.Collapse>

          <Col className={`${accordion === 'discounts' ? 'top-header-green' : 'top-header-light-gray'} br-5 mt-2`}>
            <Accordion.Toggle
              as={Col}
              eventKey="4"
              onClick={() => setAccordion('discounts')}
              className="card-header-title text-uppercase"
            >
              Haberes, Descuentos y Beneficios
            </Accordion.Toggle>
          </Col>

          <Accordion.Collapse eventKey="4">
            <Card.Body className="div-content">
              {getIn(errors, 'employee[contract][contractBalancesAttributes]') ===
                'No pueden haber conceptos repetidos' && (
                  <Row>
                    <Col md={12}>
                      <span style={{ color: 'red' }}>
                        <Icon icon="alert-circle" style={{ marginRight: '5px', height: '15px', width: '15px' }} />
                        {'No pueden haber conceptos repetidos'}
                      </span>
                    </Col>
                  </Row>
                )}
              <h4 className="text-uppercase mb-1 mt-2">Haberes</h4>
              {this.balancesInputs()}
              <WithHoldings
                fetchBalanceDiscounts={this.fetchBalanceDiscounts}
                initialValueBalance={this.initialValueBalance}
                unitOfAccounts={unitOfAccounts}
                {...this.props}
                {...this.state}
              />
              <h4 className="text-uppercase mb-1">Beneficios</h4>
              <Row className={!benefitsTouched && 'sample-row'} onClick={() => this.handleBenefitsTouched()}>
                <Col md={4}>
                  <Field name="employee[contract][additionalHolidays]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        inputType="number"
                        min={0}
                        label="Días Adicionales de Vacaciones"
                        tooltipText="Corresponde a los días adicionales pactados con el empleador. Estos días impactan en el
                               cálculo del finiquito y cuanto devengan mensualmente."
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={!benefitsTouched}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="employee[contract][additionalHolidaysStartDate]">
                    {({ field }) => (
                      <FormikDatePicker
                        name={field.name}
                        value={field.value}
                        isOutsideRange={() => false}
                        label="Valido desde la fecha"
                        placeholder="dd/mm/aaaa"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={!benefitsTouched}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4} className="align-self-end">
                  <Field name="employee[contract][acumulativeAdditionalHolidays]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="¿Beneficio acumulable?" />}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="employee[contract][administrativeDays]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        inputType="number"
                        min={0}
                        label="Días Administrativos"
                        tooltipText="Permiso con goce de sueldo otorgado por el empleador."
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={!benefitsTouched}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="employee[contract][administrativeStartDate]">
                    {({ field }) => (
                      <FormikDatePicker
                        name={field.name}
                        value={field.value}
                        isOutsideRange={() => false}
                        label="Valido desde la fecha"
                        placeholder="dd/mm/aaaa"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={!benefitsTouched}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4} className="align-self-end">
                  <Field name="employee[contract][acumulativeAdministrative]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="¿Beneficio acumulable?" />}
                  </Field>
                </Col>
                <Col md={6}>
                  <Field name="employee[contract][recognizedMonths]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        min={0}
                        inputType="number"
                        label="Meses Reconocidos"
                        tooltipText="Ingresar los meses imponibles reconocidos con otros empleadores hasta el tope de 120 meses."
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={!benefitsTouched}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={6}>
                  <Field name="employee[contract][recognizedMonthsFrom]">
                    {({ field }) => (
                      <FormikDatePicker
                        name={field.name}
                        value={field.value}
                        isOutsideRange={() => false}
                        label="Meses Reconocidos Desde"
                        placeholder="dd/mm/aaaa"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={!benefitsTouched}
                      />
                    )}
                  </Field>
                </Col>
                {benefitsTouched && vContract.recognizedMonths > 0 ? (
                  <Col md={6}>
                    <Field name="employee[contract][progressiveVacationsCertificate]">
                      {({ field }) => (
                        <UploadFile
                          {...field}
                          label="Certificado de Vacaciones Progresivas"
                          tooltipText="Solo formato PDF permitido"
                          formats={['.pdf']}
                          name={
                            vContract.progressiveFileInfo?.filename || 'Adjuntar Certificado de Vacaciones Progresivas.'
                          }
                          onChange={file => setFieldValue(field.name, file)}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                ) : (
                  undefined
                )}
              </Row>
            </Card.Body>
          </Accordion.Collapse>

          <Col className={`${accordion === 'others' ? 'top-header-green' : 'top-header-light-gray'} br-5 mt-2`}>
            <Accordion.Toggle
              as={Col}
              eventKey="5"
              onClick={() => setAccordion('others')}
              className="card-header-title text-uppercase"
            >
              Otros
            </Accordion.Toggle>
          </Col>

          <Accordion.Collapse eventKey="5">
            <Card.Body className="div-content">
              <Row className={!othersTouched && 'sample-row'} onClick={() => this.handleOthersTouched()}>
                <Col md={4}>
                  <Field name="employee[contract][pantsSize]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        label="Talla Pantalón"
                        onChange={data => isAlphanumeric(data.target.value, field, setFieldValue)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={!othersTouched}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="employee[contract][shirtSize]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        label="Talla Camisa"
                        onChange={data => isAlphanumeric(data.target.value, field, setFieldValue)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={!othersTouched}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="employee[contract][tShirtSize]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        label="Talla Polera"
                        onChange={data => isAlphanumeric(data.target.value, field, setFieldValue)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={!othersTouched}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="employee[contract][jacketSize]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        label="Talla Chaqueta"
                        onChange={data => isAlphanumeric(data.target.value, field, setFieldValue)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={!othersTouched}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={4}>
                  <Field name="employee[contract][shoesSize]">
                    {({ field }) => (
                      <FormikInput
                        {...field}
                        label="Talla Zapatos"
                        onChange={data => isAlphanumeric(data.target.value, field, setFieldValue)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={!othersTouched}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
          {canPrevision && (
            <>
              <Col
                className={`${accordion === 'prevision' ? 'top-header-green' : 'top-header-light-gray'} br-5 mt-2 mb-2`}
              >
                <Accordion.Toggle
                  as={Col}
                  eventKey="6"
                  onClick={() => setAccordion('prevision')}
                  className="card-header-title text-uppercase"
                >
                  PREVISIÓN
                </Accordion.Toggle>
              </Col>

              <Accordion.Collapse eventKey="6">
                <Card.Body className="div-content">
                  <>
                    <EmployeePrevisionTab
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      employeePrevision={contract.employeePrevisionAttributes}
                      remunerations={remunerations}
                    />
                  </>
                </Card.Body>
              </Accordion.Collapse>
            </>
          )}

          <Col className={`${accordion === 'family' ? 'top-header-green' : 'top-header-light-gray'} br-5 mt-2 mb-2`}>
            <Accordion.Toggle
              as={Col}
              eventKey="7"
              onClick={() => setAccordion('family')}
              className="card-header-title"
            >
              GRUPOS FAMILIARES
            </Accordion.Toggle>
          </Col>

          <Accordion.Collapse eventKey="7">
            <Card.Body className="div-content">
              <FamilyGroupTab
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
              />
            </Card.Body>
          </Accordion.Collapse>

          {contractCustomFields && contractCustomFields.length > 0 && (
            <>
              <Col
                className={`${accordion === 'contractCustomFields' ? 'top-header-green' : 'top-header-light-gray'
                  } br-5 mt-2 mb-2`}
              >
                <Accordion.Toggle
                  as={Col}
                  eventKey="8"
                  onClick={() => setAccordion('contractCustomFields')}
                  className="card-header-title"
                >
                  CAMPOS PERSONALIZADOS
                </Accordion.Toggle>
              </Col>

              <Accordion.Collapse eventKey="8">
                <Card.Body className="div-content">
                  <CustomFieldTab
                    values={values}
                    errors={errors}
                    touched={touched}
                    customFields={contractCustomFields}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </>
          )}
        </Accordion>
        <SimpleCenteredModal
          title={modalTitle}
          body={modalBody}
          show={simpleModalShow}
          onHide={this.handleModalClose}
        />
      </>
    );
  }
}

contractTab.contextType = AbilityContext;

export const yupContract = remunerations =>
  Yup.object().shape(
    {
      acumulativeAdditionalHolidays: Yup.boolean(),
      additionalHolidays: Yup.number()
        .min(0, 'Debe ser mayor o igual a 0')
        .nullable(),
      additionalHolidaysStartDate: Yup.string().when('additionalHolidays', {
        is: additionalHolidays => {
          return additionalHolidays > 0;
        },
        then: schema => {
          // eslint-disable-next-line func-names
          return schema.test(
            'beforeContractStartDate',
            'No puede ser menor a la fecha de inicio del contrato',
            function (currentAditionalHolidaysStartDate) {
              if (
                currentAditionalHolidaysStartDate !== '' &&
                typeof currentAditionalHolidaysStartDate !== 'undefined'
              ) {
                const [day, month, year] = currentAditionalHolidaysStartDate.split('/');
                const correctDate = new Date(year, month - 1, day);
                if (this.options.parent.startDate) {
                  const [days, months, years] = this.options.parent.startDate.split('/');
                  const correctStartDate = new Date(years, months - 1, days);
                  if (correctStartDate <= correctDate) {
                    return true;
                  }
                }
              }
              return false;
            }
          );
        },
        otherwise: schema => {
          return schema.nullable();
        }
      }),
      administrativeDays: Yup.number()
        .min(0, 'Debe ser mayor o igual a 0')
        .nullable(),
      administrativeStartDate: Yup.date()
        .formatdate()
        .when(['administrativeDays', 'startDate'], (administrativeDays, startDate, schema) => {
          return administrativeDays && administrativeDays > 0
            ? schema
              .min(
                startDate,
                'La fecha de inicio administrativa no puede ser anterior a la fecha de inicio del contrato'
              )
              .required('Fecha de inicio de días administrativos es requerida')
            : schema.nullable();
        }),
      bankAccount: Yup.string().when('paymentType', {
        is: val => val === 'bank_transfer',
        then: Yup.string()
          .required('Debes ingresar una cuenta bancaria')
          .typeError('Debes ingresar una cuenta bancaria'),
        otherwise: Yup.string().nullable()
      }),
      bankId: Yup.string().when('paymentType', {
        is: val => val === 'bank_transfer' || val === 'cashiers_check',
        then: Yup.string()
          .required('Debes seleccionar un banco')
          .typeError('Debes seleccionar un banco'),
        otherwise: Yup.string().nullable()
      }),
      bankAccountType: Yup.string().when('paymentType', {
        is: val => val === 'bank_transfer',
        then: Yup.string()
          .required('Debes seleccionar un tipo de cuenta')
          .typeError('Debes seleccionar un tipo de cuenta'),
        otherwise: Yup.string().nullable()
      }),
      subsidiaryId: Yup.string().nullable(),
      contractBalancesAttributes: Yup.array()
        .of(
          Yup.object().shape({
            amount: Yup.number()
              .positive('Debe ser mayor a 0')
              .required('Debes ingresar un monto'),
            balanceId: Yup.string().when('balanceType', {
              is: val => val === 'asset',
              then: Yup.string().required('Debes ingresar un haber'),
              otherwise: Yup.string().required('Debes ingresar un descuento/retención')
            }),
            code: Yup.string().nullable(),
            unitOfAccount: Yup.string().required('Debes seleccionar una unidad de cuenta'),
            startDate: Yup.date()
              .required('Debes seleccionar una fecha de inicio')
              .formatdate(),
            endDate: Yup.date().formatdate()
          })
        )
        .test('unique-balance', 'No pueden haber conceptos repetidos', value => {
          const balanceCodes = value.filter(balance => !balance._destroy).map(balance => balance.balanceId);
          return new Set(balanceCodes).size === balanceCodes.length;
        }),
      contractBalancesRetentionsAttributes: Yup.array()
        .of(
          Yup.object().shape({
            amount: Yup.number()
              .positive('Debe ser mayor a 0')
              .required('Debes ingresar un monto'),
            balanceId: Yup.string().when('balanceType', {
              is: val => val === 'asset',
              then: Yup.string().required('Debes ingresar un haber'),
              otherwise: Yup.string().required('Debes ingresar un descuento/retención')
            }),
            code: Yup.string().nullable(),
            unitOfAccount: Yup.string().required('Debes seleccionar una unidad de cuenta'),
            startDate: Yup.date()
              .required('Debes seleccionar una fecha de inicio')
              .formatdate(),
            endDate: Yup.date().formatdate(),
            contractRetention: Yup.object().shape({
              fileNumber: Yup.string()
                .max(50, 'Debe ser menor o igual a 50 caracteres')
                .required('Debes ingresar un número de expediente'),
              retentionDate: Yup.string().required('Debes seleccionar una fecha de retención'),
              competentCourt: Yup.string()
                .max(100, 'Debe ser menor o igual a 100 caracteres')
                .required('Debes ingresar un tribunal competente'),
              beneficiary: Yup.string()
                .max(100, 'Debe ser menor o igual a 100 caracteres')
                .required('Debes ingresar un beneficiario'),
              nationalIdentificationBeneficiary: Yup.string()
                .required('Debes ingresar un RUT del beneficiario')
                .rut('Debe ser un RUT válido'),
              paymentType: Yup.string().required('Debes seleccionar una forma de Pago'),
              bankAccount: Yup.string().when('paymentType', {
                is: val => val === 'bank_transfer',
                then: Yup.string()
                  .required('Debes ingresar una cuenta bancaria')
                  .max(20, 'Máximo 20 caracteres')
                  .typeError('Debes ingresar una cuenta bancaria'),
                otherwise: Yup.string().nullable()
              }),
              bankId: Yup.string().when('paymentType', {
                is: val => val === 'bank_transfer' || val === 'cashiers_check',
                then: Yup.string()
                  .required('Debes seleccionar un banco')
                  .typeError('Debes seleccionar un banco'),
                otherwise: Yup.string().nullable()
              }),
              bankAccountType: Yup.string().when('paymentType', {
                is: val => val === 'bank_transfer',
                then: Yup.string()
                  .required('Debes seleccionar un tipo de cuenta')
                  .typeError('Debes seleccionar un tipo de cuenta'),
                otherwise: Yup.string().nullable()
              })
            })
          })
        )
        .test('unique-retention-balance', 'No pueden haber conceptos repetidos', value => {
          const balanceCodes = value.filter(balance => !balance._destroy).map(balance => balance.balanceId);
          return new Set(balanceCodes).size === balanceCodes.length;
        }),
      contractType: Yup.string().required('Debes seleccionar un tipo de contrato'),
      costCenterId: Yup.string().nullable(),
      disability: Yup.string().required('Debes seleccionar un tipo de Discapacidad'),
      disabilityNationalIdentification: Yup.string().when('disability', {
        is: value => value && value !== 'no_disability',
        then: Yup.string()
          .required('Debes ingresar un número de credencial')
          .max(20, 'Deben ser menos de 20 caracteres'),
        otherwise: Yup.string().nullable()
      }),
      disabilityCertificate: Yup.mixed().when('disability', {
        is: val => val,
        then: Yup.mixed()
          .nullable()
          .notRequired()
          .test(
            'FILE_SIZE',
            'El archivo cargado excede el tamaño maximo permitido (5mb).',
            value => !value?.size || (value && value?.size <= 5242880)
          )
          .test(
            'FILE_FORMAT',
            'El archivo cargado tiene un formato no compatible.',
            value => (!value?.type && value?.type !== '') || (value && ['application/pdf'].includes(value?.type))
          )
      }),
      endDate: Yup.string().when('contractType', {
        is: val => val !== 'undefined_term',
        then: Yup.string()
          .required('Debes seleccionar una fecha de término')
          .typeError('Debes ingresar una fecha de término válida'),
        otherwise: Yup.string().nullable()
      }),
      familyGroupsAttributes: Yup.array().of(yupFamilyGroup),
      foreignTechnician: Yup.string().nullable(),
      friday: Yup.boolean().when('workSchedule', {
        is: val => val && val !== 'article_22',
        then: Yup.boolean().test('check-days', 'Debe seleccionar al menos un día', function oneBoolean(value) {
          const { monday, tuesday, wednesday, thursday, saturday, sunday } = this.parent;
          if (value || monday || tuesday || wednesday || thursday || saturday || sunday) {
            return true;
          }
          return this.createError({
            path: 'employee.contract.errorDays',
            message: 'Al menos debes seleccionar un día'
          });
        }),
        otherwise: Yup.boolean().nullable()
      }),
      gratificationType: Yup.string().when([], {
        is: () => remunerations === true,
        then: Yup.string().required('Debes introducir un Tipo de Gratificación')
      }),
      gratificationCondition: Yup.string().when('gratificationType', {
        is: val => val === 'guaranteed',
        then: Yup.string().required('Debes seleccionar una base de cálculo'),
        otherwise: Yup.string().nullable()
      }),
      gratificationPeriod: Yup.string().when('gratificationType', {
        is: val => val === 'unsure' || val === 'guaranteed',
        then: Yup.string().required('Debes seleccionar régimen de gratificación'),
        otherwise: Yup.string().nullable()
      }),
      incomeTaxType: Yup.string().nullable(),
      jacketSize: Yup.string()
        .max(20, 'Deben ser menos que 20 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos')
        .nullable(),
      jobManagementId: Yup.string().nullable(),
      jobTitleId: Yup.string().required('Debes seleccionar un cargo'),
      branchOfficeIds: Yup.string().required('Debes seleccionar un Lugar de Prestación de Servicios'),
      markingTypeIds: Yup.array().when('checkAssistance', {
        is: val => val === true,
        then: Yup.array()
          .required('debe seleccionar el tipo de marcaje')
          .test('len', 'Debe ingresar 2 registros en el tipo de marcaje', val => val && val.length === 2),
        otherwise: Yup.array().nullable()
      }),
      shiftIds: Yup.array().nullable(),
      monday: Yup.boolean().when('workSchedule', {
        is: val => val && val !== 'article_22',
        then: Yup.boolean().test('check-days', 'Debe seleccionar al menos un día', function oneBoolean(value) {
          const { tuesday, wednesday, thursday, friday, saturday, sunday } = this.parent;
          if (value || tuesday || wednesday || thursday || friday || saturday || sunday) {
            return true;
          }
          return this.createError({
            path: 'employee.contract.errorDays',
            message: 'Al menos debes seleccionar un día'
          });
        }),
        otherwise: Yup.boolean().nullable()
      }),
      outSourceBusinessName: Yup.string().when('outSourced', {
        is: true,
        then: Yup.string()
          .required('Debes ingresar una razón social de la empresa')
          .max(200, 'Deben ser menos que 200 caracteres')
          .alphanumeric('Deben ser caracteres alfanuméricos'),
        otherwise: Yup.string().nullable()
      }),
      outSourceEstablishmentName: Yup.string().when('outSourced', {
        is: true,
        then: Yup.string()
          .required('Debes ingresar nombre del establecimiento')
          .max(200, 'Deben ser menos que 50 caracteres')
          .alphanumeric('Deben ser caracteres alfanuméricos'),
        otherwise: Yup.string().nullable()
      }),
      outSourceAddress: Yup.string().when('outSourced', {
        is: true,
        then: Yup.string()
          .required('Debes ingresar una direccion')
          .max(100, 'Deben ser menos que 100 caracteres'),
        otherwise: Yup.string().nullable()
      }),
      outSourceAddressNumber: Yup.string().when('outSourced', {
        is: true,
        then: Yup.string()
          .required('Debes ingresar número')
          .max(6, 'Deben ser menos que 6 caracteres')
          .alphanumeric('Deben ser caracteres alfanuméricos'),
        otherwise: Yup.string().nullable()
      }),
      outSourceAddressFlat: Yup.string()
        .max(6, 'Deben ser menos que 6 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos'),
      outSourceAddressOffice: Yup.string()
        .max(6, 'Deben ser menos que 6 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos'),
      outSourceRegionId: Yup.string().when('outSourced', {
        is: true,
        then: Yup.string().required('Debes ingresar región'),
        otherwise: Yup.string().nullable()
      }),
      outSourceCommuneId: Yup.string().when('outSourceRegionId', {
        is: val => val,
        then: Yup.string().required('Debes ingresar comuna'),
        otherwise: Yup.string().nullable()
      }),
      outSourceExceptionalSystemNumber: Yup.string().when('outSourceExceptionalSystem', {
        is: true,
        then: Yup.string()
          .required('Debes introducir número de sistema excepcional')
          .max(10, 'Deben ser menos que 10 caracteres')
          .alphanumeric('Deben ser caracteres alfanuméricos')
          .typeError('Debes introducir numero de sistema excepcional'),
        otherwise: Yup.string().nullable()
      }),
      outSourceNationalIdentification: Yup.string().when('outSourced', {
        is: true,
        then: Yup.string().rut('Debe ingresar un RUT válido')
      }),
      nationalIdExpirationDate: Yup.string().nullable(),
      pantsSize: Yup.string()
        .max(20, 'Deben ser menos que 20 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos')
        .nullable(),
      parentId: Yup.string().nullable(),
      paymentSchedule: Yup.string().when([], {
        is: () => remunerations === true,
        then: Yup.string().required('Debes introducir un tipo de pago')
      }),
      paymentType: Yup.string().when([], {
        is: () => remunerations === true,
        then: Yup.string().required('Debes seleccionar una forma de Pago')
      }),
      privateRole: Yup.boolean(),
      telecommuting: Yup.boolean(),
      vacationCalculationArticle67: Yup.boolean(),
      recognizedMonths: Yup.number()
        .min(0, 'Debe ser mayor o igual a 0')
        .nullable(),
      recognizedMonthsFrom: Yup.string().when('recognizedMonths', {
        is: val => val > 0,
        then: Yup.string().required('Debes ingresar fecha de meses reconocidos'),
        otherwise: Yup.string().nullable()
      }),
      progressiveVacationsCertificate: Yup.mixed().when('recognizedMonths', {
        is: val => val > 0,
        then: Yup.mixed()
          .nullable()
          .notRequired()
          .test(
            'FILE_SIZE',
            'El archivo cargado excede el tamaño maximo permitido (5mb).',
            value => !value?.size || (value && value?.size <= 5242880)
          )
          .test(
            'FILE_FORMAT',
            'El archivo cargado tiene un formato no compatible.',
            value => (!value?.type && value?.type !== '') || (value && ['application/pdf'].includes(value?.type))
          )
      }),
      salary: Yup.number().when([], {
        is: () => remunerations === true,
        then: Yup.number()
          .positive('Debe ser mayor a 0')
          .required('Debes ingresar un sueldo')
          .max(10000000000000000000, 'Deben ser menos de 20 números')
      }),
      saturday: Yup.boolean().when('workSchedule', {
        is: val => val && val !== 'article_22',
        then: Yup.boolean().test('check-days', 'Debe seleccionar al menos un día', function oneBoolean(value) {
          const { monday, tuesday, wednesday, thursday, friday, sunday } = this.parent;
          if (value || monday || tuesday || wednesday || thursday || friday || sunday) {
            return true;
          }
          return this.createError({
            path: 'employee.contract.errorDays',
            message: 'Al menos debes seleccionar un día'
          });
        }),
        otherwise: Yup.boolean().nullable()
      }),
      shirtSize: Yup.string()
        .max(20, 'Deben ser menos que 20 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos')
        .nullable(),
      shoesSize: Yup.string()
        .max(20, 'Deben ser menos que 20 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos')
        .nullable(),
      startDate: Yup.string()
        .required('Debes seleccionar una fecha de inicio')
        .typeError('Debes ingresar una fecha de inicio válida'),
      sunday: Yup.boolean().when('workSchedule', {
        is: val => val && val !== 'article_22',
        then: Yup.boolean().test('check-days', 'Debe seleccionar al menos un día', function oneBoolean(value) {
          const { monday, tuesday, wednesday, thursday, friday, saturday } = this.parent;
          if (value || monday || tuesday || wednesday || thursday || friday || saturday) {
            return true;
          }
          return this.createError({
            path: 'employee.contract.errorDays',
            message: 'Al menos debes seleccionar un día'
          });
        }),
        otherwise: Yup.boolean().nullable()
      }),
      syndicateId: Yup.string().nullable(),
      thurday: Yup.boolean(),
      tShirtSize: Yup.string()
        .max(20, 'Deben ser menos que 20 caracteres')
        .alphanumeric('Deben ser caracteres alfanuméricos')
        .nullable(),
      tuesday: Yup.boolean().when('workSchedule', {
        is: val => val && val !== 'article_22',
        then: Yup.boolean().test('check-days', 'Debe seleccionar al menos un día', function oneBoolean(value) {
          const { monday, wednesday, thursday, friday, saturday, sunday } = this.parent;
          if (value || monday || wednesday || thursday || friday || saturday || sunday) {
            return true;
          }
          return this.createError({
            path: 'employee.contract.errorDays',
            message: 'Al menos debes seleccionar un día'
          });
        }),
        otherwise: Yup.boolean().nullable()
      }),
      thursday: Yup.boolean().when('workSchedule', {
        is: val => val && val !== 'article_22',
        then: Yup.boolean().test('check-days', 'Debe seleccionar al menos un día', function oneBoolean(value) {
          const { monday, tuesday, wednesday, friday, saturday, sunday } = this.parent;
          if (value || monday || tuesday || wednesday || friday || saturday || sunday) {
            return true;
          }
          return this.createError({
            path: 'employee.contract.errorDays',
            message: 'Al menos debes seleccionar un día'
          });
        }),
        otherwise: Yup.boolean().nullable()
      }),
      wednesday: Yup.boolean().when('workSchedule', {
        is: val => val && val !== 'article_22',
        then: Yup.boolean().test('check-days', 'Debe seleccionar al menos un día', function oneBoolean(value) {
          const { monday, tuesday, thursday, friday, saturday, sunday } = this.parent;
          if (value || monday || tuesday || thursday || friday || saturday || sunday) {
            return true;
          }
          return this.createError({
            path: 'employee.contract.errorDays',
            message: 'Al menos debes seleccionar un día'
          });
        }),
        otherwise: Yup.boolean().nullable()
      }),
      weeklyHours: Yup.number()
        .positive('Debe ser mayor a 0')
        .max(45, 'Debe ser menor o igual a 45')
        .required('Debes ingresar un número de horas'),
      workEndTime: Yup.string().when('workSchedule', {
        is: val => val && val !== 'article_22',
        then: Yup.string().required('Debes seleccionar una hora de término de jornada'),
        otherwise: Yup.string().nullable()
      }),
      workSchedule: Yup.string().required('Debes seleccionar un tipo de jornada'),
      workStartTime: Yup.string().when('workSchedule', {
        is: val => val && val !== 'article_22',
        then: Yup.string().required('Debes seleccionar una hora de inicio de jornada'),
        otherwise: Yup.string().nullable()
      }),
      workerQuality: Yup.string()
        .required('Debes seleccionar la calidad de trabajo')
        .nullable(),
      employeePrevisionAttributes: Yup.object().when([], {
        is: () => remunerations === true, // Condición cuando remunerations es true
        then: yupPrevision, // Validar con el esquema yupPrevision si remunerations es true
        otherwise: Yup.object().notRequired() // No se requiere si remunerations es false
      })
    },
    [
      ['gratificationType', 'gratificationCondition'],
      ['gratificationType', 'gratificationPeriod']
    ]
  );

export default connect()(contractTab);
