import React from 'react';
import memoize from 'memoize-one';
import { ButtonTooltip } from '../../components';
import DataProcessActions from './DataProcessActions';

const columns = memoize((clickHandler, ability) => [
  {
    name: 'RUT Y NOMBRE',
    selector: 'national_identification',
    cell: item => (
      <div
        className="d-flex flex-column"
        onClick={() => clickHandler(item, 'show')}
        role="presentation"
        aria-hidden="true"
      >
        <span className="text-muted" data-tag="allowRowEvents">
          {item?.national_identification || item?.nationalIdentification}
        </span>
        <span className="text-truncate" data-tag="allowRowEvents">
          {item?.full_name}
        </span>
      </div>
    ),
    sortable: true,
    grow: '2',
    wrap: true
  },
  {
    name: 'DÍAS DE AUSENCIAS',
    cell: item => (
      <ButtonTooltip
        variant="circle-info"
        className="btn-circle"
        size="sm"
        text="Ver detalle de ausencia"
        onClick={() => clickHandler(item, 'absence_details')}
      >
        {item.absence_details?.total_absences || 0}
      </ButtonTooltip>
    ),
    grow: '1',
    wrap: true
  },
  {
    name: 'ESTADO DEL TRABAJADOR',
    selector: 'employee_status',
    sortable: true,
    grow: '1',
    wrap: true
  },
  {
    name: 'CENTRO DE COSTO',
    selector: 'cost_center',
    sortable: true,
    grow: '2',
    wrap: true
  },
  {
    name: 'CONCEPTO',
    selector: 'balance_name',
    sortable: true,
    grow: '2',
    wrap: true
  },
  {
    name: 'ORIGEN',
    selector: 'source_type',
    sortable: true,
    grow: '2',
    wrap: true
  },
  {
    name: 'MONTO',
    selector: 'parsed_amount',
    sortable: true,
    grow: '1',
    wrap: true
  },
  {
    name: 'ESTADO DEL PROCESO',
    selector: 'data_process_status',
    sortable: true,
    grow: '2',
    wrap: true
  },
  {
    name: 'ACCIONES',
    cell: item => {
      return <DataProcessActions item={item} clickHandler={clickHandler} ability={ability} />;
    },
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

export default columns;
