import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { OvertimeDataTable } from '../../components/Overtime';
import overtimeColumns from '../Overtime/Columns';
import { DashboardCard } from '../../components';
import { overtimesDashboardRequest } from '../../requests/dashboards';
import { sendAlert } from '../../actions/utils';

const OvertimeTab = ({ moreData, setMoreData }) => {
  const [overtimeThisWeek, setOvertimeThisWeek] = useState(0);
  const [overtimeThisMonth, setOvertimeThisMonth] = useState(0);
  const dispatch = useDispatch();

  const handleSuccessRequest = response => {
    const { monthOvertimesCount, weekOvertimesCount } = camelCaseRecursive(response.data);

    setOvertimeThisWeek(weekOvertimesCount);
    setOvertimeThisMonth(monthOvertimesCount);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const fetchDashboardOvertimes = () => {
    const request = async () => {
      await overtimesDashboardRequest({
        dispatch,
        successCallback: handleSuccessRequest,
        failureCallback: handleFailureRequest
      });
    };
    request();
  };

  useEffect(fetchDashboardOvertimes, [moreData]);

  return (
    <>
      <Row>
        <Col>
          <h4 className="text-uppercase margin-fix">Hoy</h4>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={5}>
          <DashboardCard
            title="HORAS EXTRA ESTA SEMANA"
            counter={overtimeThisWeek}
            colorCard="success"
            colorText="white"
            colorBorder="success"
            icon="time"
          />
        </Col>
        <Col md={5}>
          <DashboardCard
            title="HORAS EXTRA ESTE MES"
            counter={overtimeThisMonth}
            colorCard="primary"
            colorText="white"
            colorBorder="success"
            icon="time"
          />
        </Col>
      </Row>
      <OvertimeDataTable
        moreData={moreData}
        setMoreData={setMoreData}
        columns={overtimeColumns}
        customParams={{ status: 'approved' }}
      />
    </>
  );
};

export default OvertimeTab;
