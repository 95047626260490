import React from 'react';
import memoize from 'memoize-one';
import { ApprovementStatus, BasicActionBtns, DatatableEmployeeName } from '../../components';

const columns = memoize(clickHandler => [
  {
    name: 'NOMBRE DEL TRABAJADOR',
    selector: 'name',
    cell: item => (
      <DatatableEmployeeName
        item={item.employee}
        tooltipText={item.translated_status === 'Pendiente' ? item.translated_status : ''}
        tooltipClass="dot-danger"
        withDot
        onClick={() => clickHandler(item, 'show')}
      />
    ),
    sortable: true,
    grow: '2'
  },
  {
    name: 'FECHA HORA EXTRA',
    selector: 'date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'HORA EXTRA',
    selector: 'hours',
    cell: item => item.full_time,
    sortable: true,
    grow: '1'
  },
  {
    name: 'TIPO HORA EXTRA',
    selector: 'overtime_type',
    cell: item => item.translated_overtime_type,
    sortable: true,
    grow: '1'
  },
  {
    name: 'VALOR',
    selector: 'hour_value',
    cell: item => item.translated_hour_value,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} onClick={() => clickHandler(item, 'show')} />,
    center: true,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <BasicActionBtns item={item} resource="Overtime" clickHandler={clickHandler} withShow={false} withEdit={false} />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

export default columns;
