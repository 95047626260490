import React from 'react';
import memoize from 'memoize-one';
import { Icon, BasicDropdown } from '../../components';

const items = (clickHandler, item, shiftActions) => {
  const actions = [];

  if (shiftActions[0]) {
    actions.push({
      key: 'edit',
      title: `Editar`,
      icon: <Icon icon="pencil" height="16px" width="16px" style={{ marginRight: 5 }} />,
      props: {
        onClick: () => clickHandler(item, 'edit')
      }
    });
  }

  if (shiftActions[1]) {
    actions.push({
      key: 'destroy',
      title: 'Eliminar',
      icon: <Icon icon="trash" height="16px" width="16px" style={{ marginRight: 5 }} />,

      props: {
        onClick: () => clickHandler(item, 'destroy'),
        disabled: item.enrolled_or_contract
      }
    });
  }

  if (shiftActions[2]) {
    actions.push({
      key: 'deactivate',
      title: item.status ? 'Desactivar' : 'Activar',
      icon: <Icon icon="archive" height="16px" width="16px" style={{ marginRight: 5 }} />,
      props: {
        onClick: () => clickHandler(item, 'deactivate')
      }
    });
  }

  if (shiftActions[3]) {
    actions.push({
      key: 'read',
      title: 'Ver',
      icon: <Icon icon="eye" height="16px" width="16px" style={{ marginRight: 5 }} />,
      props: {
        onClick: () => clickHandler(item, 'read')
      }
    });
  }

  return actions;
};

const columns = memoize((clickHandler, shift, shiftActions) => [
  {
    name: 'NOMBRE TURNO',
    selector: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TIPO',
    selector: 'shift_type',
    cell: item => (
      <>
        {item.flexible_shift ? (
          <span>{item.translated_shift_type}-Flexible</span>
        ) : (
          <span>{item.translated_shift_type}</span>
        )}
      </>
    ),
    sortable: true,
    grow: '1'
  },
  {
    name: 'HORARIO',
    selector: 'schedule_type',
    sortable: false,
    grow: '1'
  },
  {
    name: 'COLACIÓN',
    selector: 'colation',
    sortable: false,
    grow: '1'
  },
  {
    name: 'TOLERANCIA',
    selector: 'tolerance',
    cell: item => <span>{item.tolerance} min</span>,
    sortable: true,
    grow: '1'
  },
  {
    name: 'TRABAJADORES ASOCIADOS',
    selector: 'associated_employee',
    sortable: false,
    grow: '1',
    center: true
  },
  {
    name: 'ACTIVO',
    selector: 'status',
    cell: item => (
      <>{item.status ? <span className="text-success">Activo</span> : <span className="text-danger">Inactivo</span>}</>
    ),
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        {shiftActions.some(row => row) && (
          <BasicDropdown
            variant="outline-primary"
            noArrow
            block
            right
            titleDrop={
              <span className="align-middle">
                Acciones <Icon icon="ellipsis-vertical" height="20px" width="20px" />
              </span>
            }
            items={items(clickHandler, item, shiftActions)}
          />
        )}
      </>
    ),
    ignoreRowClick: true,
    grow: '1',
    minWidth: '140px',
    center: true
  }
]);

export default columns;
