const documentTemplate = {
  body: '',
  description: '',
  name: '',
  orientation: '',
  size: '',
  templateType: ''
};

export const documentGroups = {
  certificateTypes: [
    'administrative_days_certificate',
    'advance_payment_certificate',
    'certificate',
    'loan_certificate',
    'no_paid_leave_certificate',
    'paid_leave_certificate',
    'vacation_certificate',
    'overtime_certificate'
  ],
  letterTypes: [
    'congratulation_letter',
    'reprimand_document',
    'selection_process_acceptance_notice',
    'selection_process_rejection_notice',
    'termination_contract_notice_letter'
  ],
  otherDocuments: [
    'acceptance_riohs',
    'committee_hygiene_safety',
    'election_representatives_hygiene_safety',
    'emergency_evaluation',
    'induction_risk_prevention',
    'labor_direction',
    'loan_document',
    'meeting_hygiene_safety',
    'order_hygiene_safety',
    'other_documents',
    'payment_advance',
    'personal_protection',
    'report_occupational_hazards',
    'riohs_labor_inspection',
    'riohs_seremi',
    'voting_workers',
    'work_accidents'
  ],
  work_contract: ['work_contract'],
  contract_annex: ['contract_annex'],
  work_settlement: ['work_settlement']
};

const documentGroupToTab = {
  certificateTypes: 'certificate',
  letterTypes: 'letters',
  otherDocuments: 'other-documents',
  work_contract: 'work-contract',
  contract_annex: 'contract-annex',
  work_settlement: 'work-settlement'
};

export const findDocumentGroup = type => {
  return Object.keys(documentGroups).find(key => {
    return documentGroups[key].includes(type);
  });
};

export const findDocumentTab = type => {
  const group = findDocumentGroup(type);
  return documentGroupToTab[group] || 'other-documents';
};

export default documentTemplate;
