import React, { Component, useMemo } from 'react';
import { connect } from 'react-redux';
import { Field, getIn } from 'formik';
import { Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';

import moment from 'moment';
import {
  FormikInput,
  FormikSelect,
  NestedAttributes,
  FormikCheckBox,
  FormikNumber,
  FormikDatePicker
} from '../../../components';
import {
  heavyLaborAdditionals,
  ineCodeTypes,
  ipsTypes,
  isOldAgeOptions,
  paymentMethods,
  personalMovmentCodes,
  pensionSchemes,
  pensionerTypes,
  unitOfAccountTypes,
  unitOfAccountVoluntaryTypes,
  voluntaryPensionFundTypes,
  voluntaryPrevisionTypes,
  unemploymentInsuranceReasons
} from './FormOptions';
import indexHealthPrevisionsRequest from '../../../requests/healthPrevisions';
import indexPensionFundsRequest from '../../../requests/pensionFunds';
import indexVoluntaryPensionFundsRequest from '../../../requests/voluntaryPensionFunds';
import { rutFormat, validRutInput } from '../../../services/utils';

const VoluntaryPrevisionInput = ({
  setFieldValue,
  setFieldTouched,
  voluntaryPensionFunds,
  attributes,
  errors,
  touched,
  index
}) => {
  const {
    voluntaryPrevisionType,
    unitOfAccount,
    voluntaryPensionFundId,
    voluntaryPensionFundType,
    paymentMethod,
    personalMovmentCode
  } = attributes;
  const previsionApv = voluntaryPrevisionType === 'apv';
  const previsionCollectiveApv = voluntaryPrevisionType === 'collective_apv';
  const previsionIndemnityEvent = voluntaryPrevisionType === 'indemnity_event';
  const previsionVoluntaryAffiliate = voluntaryPrevisionType === 'voluntary_affiliate';
  const withTaxReduction = voluntaryPrevisionType === 'deposit_agreed' || voluntaryPrevisionType === 'collective_apv';
  const labelAgreedQuantity = previsionCollectiveApv ? 'Aporte Trabajador' : 'Cantidad Acordada';
  const labelAfp = previsionVoluntaryAffiliate ? 'Afp' : 'Institución';
  const isCountTwoType = voluntaryPrevisionType === 'count_two';
  const voluntaryPensionInstitution =
    isCountTwoType || previsionVoluntaryAffiliate
      ? voluntaryPensionFunds.filter(option => option.label.startsWith('AFP - '))
      : voluntaryPensionFunds;

  const name = useMemo(
    () => `employee[contract][employeePrevisionAttributes][voluntaryPrevisionsAttributes][${index}]`,
    [index]
  );
  let leftAddon = '$';
  if (unitOfAccount === 'uf') {
    leftAddon = 'UF';
  } else if (unitOfAccount === 'pesos') {
    leftAddon = '$';
  }

  const handleIdentificationNumberFormat = e => {
    if (validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    }
  };

  const voluntaryPrevisionChange = (filename, field, data) => {
    if (data) {
      setFieldValue(field.name, data.value);
      setFieldValue(`${filename}[periodTo]`, '');
      setFieldValue(`${filename}[periodFrom]`, '');
    } else {
      setFieldValue(field.name, '');
    }
  };

  return (
    <Col>
      <Row>
        <Col md={withTaxReduction ? 6 : 12}>
          <Field name={`${name}[voluntaryPrevisionType]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Tipo de Previsión Voluntaria"
                placeholder="Seleccionar Tipo de Previsión Voluntaria"
                options={voluntaryPrevisionTypes}
                defaultValue={voluntaryPrevisionType}
                onChange={data => voluntaryPrevisionChange(name, field, data)}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        {withTaxReduction && (
          <Field name={`${name}[taxReduction]`}>
            {({ field }) => (
              <FormikCheckBox {...field} margin="mt-4" field={field} label="Rebaja de la base tributaria" />
            )}
          </Field>
        )}
        {voluntaryPrevisionType !== 'voluntary_affiliate' && voluntaryPrevisionType !== 'indemnity_event' && (
          <>
            <Col md={6}>
              <Field name={`${name}[unitOfAccount]`}>
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Unidad de Pago"
                    placeholder="Seleccionar Unidad de Cuenta"
                    options={unitOfAccountVoluntaryTypes}
                    defaultValue={unitOfAccount}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>

            <Col md={6}>
              <Field name={`${name}[parsedAgreedQuantity]`}>
                {({ field }) => (
                  <FormikNumber
                    {...field}
                    abbr
                    decimalScale={leftAddon === '$' ? 0 : 2}
                    leftAddon={leftAddon}
                    fieldName={`${name}[agreedQuantity]`}
                    label={labelAgreedQuantity}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                  />
                )}
              </Field>
            </Col>
          </>
        )}

        {previsionCollectiveApv && (
          <Col md={6}>
            <Field name={`${name}[parsedEmployerContribution]`}>
              {({ field }) => (
                <FormikNumber
                  {...field}
                  abbr
                  decimalScale={leftAddon === '$' ? 0 : 2}
                  label="Aporte Empleador"
                  fieldName={`${name}[employerContribution]`}
                  leftAddon={leftAddon}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              )}
            </Field>
          </Col>
        )}
        {previsionVoluntaryAffiliate && (
          <>
            <Col md={6}>
              <Field name={`${name}[voluntaryIdentificationNumber]`}>
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Rut"
                    onChange={e => handleIdentificationNumberFormat(e)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    setFieldValue={setFieldValue}
                  />
                )}
              </Field>
            </Col>

            <Col md={6}>
              <Field name={`${name}[voluntaryName]`}>
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Nombre"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    setFieldValue={setFieldValue}
                  />
                )}
              </Field>
            </Col>

            <Col md={6}>
              <Field name={`${name}[voluntaryLastName]`}>
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Apellido Paterno"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    setFieldValue={setFieldValue}
                  />
                )}
              </Field>
            </Col>

            <Col md={6}>
              <Field name={`${name}[voluntarySecondLastName]`}>
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Apellido Materno"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    setFieldValue={setFieldValue}
                  />
                )}
              </Field>
            </Col>

            <Col md={6}>
              <Field name={`${name}[periodFrom]`}>
                {({ field }) => (
                  <FormikDatePicker
                    {...field}
                    abbr
                    name={field.name}
                    value={field.value}
                    isOutsideRange={() => false}
                    label="Fecha Desde"
                    placeholder="dd/mm/aaaa"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>

            <Col md={6}>
              <Field name={`${name}[periodTo]`}>
                {({ field }) => (
                  <FormikDatePicker
                    {...field}
                    abbr
                    name={field.name}
                    value={field.value}
                    isOutsideRange={() => false}
                    label="Fecha Hasta"
                    placeholder="dd/mm/aaaa"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>

            <Col md={6}>
              <Field name={`${name}[personalMovmentCode]`}>
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    label="Código Movimiento de Personal"
                    placeholder="Seleccionar Forma de Pago"
                    options={personalMovmentCodes}
                    defaultValue={personalMovmentCode}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </>
        )}
        <Col md={6}>
          <Field name={`${name}[voluntaryPensionFundId]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label={labelAfp}
                placeholder="Seleccionar Institución"
                options={voluntaryPensionInstitution}
                defaultValue={voluntaryPensionFundId}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        {previsionApv && (
          <>
            <Col md={6}>
              <Field name={`${name}[voluntaryPensionFundType]`}>
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Régimen Tributario"
                    placeholder="Seleccionar Régimen Tributario"
                    options={voluntaryPensionFundTypes}
                    defaultValue={voluntaryPensionFundType}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>

            <Col md={6}>
              <Field name={`${name}[contractNumber]`}>
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Número de Contrato"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    setFieldValue={setFieldValue}
                  />
                )}
              </Field>
            </Col>

            <Col md={6}>
              <Field name={`${name}[paymentMethod]`}>
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Forma de Pago"
                    placeholder="Seleccionar Forma de Pago"
                    options={paymentMethods}
                    defaultValue={paymentMethod}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </>
        )}
        {previsionCollectiveApv && (
          <>
            <Col md={6}>
              <Field name={`${name}[contractNumber]`}>
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Número de Contrato"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    setFieldValue={setFieldValue}
                  />
                )}
              </Field>
            </Col>

            <Col md={6}>
              <Field name={`${name}[paymentMethod]`}>
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Forma de Pago"
                    placeholder="Seleccionar Forma de Pago"
                    options={paymentMethods}
                    defaultValue={paymentMethod}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </>
        )}
        {previsionIndemnityEvent && (
          <>
            <Col md={6}>
              <Field name={`${name}[periodNumber]`}>
                {({ field }) => (
                  <FormikNumber
                    {...field}
                    abbr
                    label="Numero de Periodo"
                    fieldName={`${name}[periodNumber]`}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    maxLength="2"
                  />
                )}
              </Field>
            </Col>

            <Col md={6}>
              <Field name={`${name}[periodFrom]`}>
                {({ field }) => (
                  <FormikDatePicker
                    {...field}
                    abbr
                    name={field.name}
                    value={field.value}
                    isOutsideRange={() => false}
                    label="Periodo desde"
                    placeholder="dd/mm/aaaa"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>

            <Col md={6}>
              <Field name={`${name}[periodTo]`}>
                {({ field }) => (
                  <FormikDatePicker
                    {...field}
                    abbr
                    name={field.name}
                    value={field.value}
                    isOutsideRange={() => false}
                    label="Periodo hasta"
                    placeholder="dd/mm/aaaa"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>

            <Col md={6}>
              <Field name={`${name}[parsedIndemnificationRate]`}>
                {({ field }) => (
                  <FormikNumber
                    {...field}
                    abbr
                    decimalScale={2}
                    leftAddon="%"
                    fieldName={`${name}[indemnificationRate]`}
                    label="Tasa de indemnización"
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    maxLength="5"
                  />
                )}
              </Field>
            </Col>
          </>
        )}
        {previsionVoluntaryAffiliate && (
          <>
            <Col md={6}>
              <Field name={`${name}[unitOfAccount]`}>
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Unidad de Pago del Monto de Ahorro"
                    placeholder="Seleccionar Unidad de Cuenta"
                    options={unitOfAccountVoluntaryTypes}
                    defaultValue={unitOfAccount}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6}>
              <Field name={`${name}[parsedVoluntarySavingsAmount]`}>
                {({ field }) => (
                  <FormikNumber
                    {...field}
                    abbr
                    label="Monto Ahorro Voluntario"
                    fieldName={`${name}[voluntarySavingsAmount]`}
                    decimalScale={leftAddon === '$' ? 0 : 2}
                    leftAddon={leftAddon}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                  />
                )}
              </Field>
            </Col>
          </>
        )}
      </Row>
    </Col>
  );
};

class EmployeePrevisionTab extends Component {
  state = {
    healthPrevisions: [],
    pensionFunds: [],
    voluntaryPensionFunds: [],
    fonasaReadOnly: false,
    withHealthPrevision: true
  };

  componentDidMount() {
    this.fetchHealthPrevisions();
    this.fetchPensionFunds();
    this.fetchVoluntaryPensionFunds();
    this.checkFonasa();
  }

  componentDidUpdate(prevProps) {
    const {
      employeePrevision: { healthPrevision }
    } = this.props;
    const { healthPrevision: prevPrevision } = prevProps.employeePrevision;
    if (healthPrevision !== prevPrevision) {
      this.checkFonasa();
    }
  }

  checkFonasa = () => {
    const {
      employeePrevision: { healthPrevision }
    } = this.props;
    if (healthPrevision?.name?.toLowerCase() === 'fonasa') {
      this.setState({ fonasaReadOnly: true });
    } else if (healthPrevision?.name?.toLowerCase() === 'sin isapre' || !healthPrevision) {
      this.setState({ withHealthPrevision: false });
    }
  };

  voluntaryPrevisionsInputs = () => {
    const { voluntaryPensionFunds } = this.state;
    const { errors, touched, values, setFieldValue, setFieldTouched } = this.props;
    const { employeePrevisionAttributes = {} } = values.employee?.contract;
    const { voluntaryPrevisionsAttributes } = employeePrevisionAttributes;
    const mapResults = voluntaryPrevisionsAttributes.map((body, index) => {
      if (body._destroy) {
        return undefined;
      }

      return (
        <VoluntaryPrevisionInput
          key={`voluntary-prevision-${index.toString()}`}
          voluntaryPensionFunds={voluntaryPensionFunds}
          attributes={body}
          errors={errors}
          touched={touched}
          index={index}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
      );
    });

    mapResults.push(
      <Col>
        <Row>
          <Col className="sample-row">
            <FormikSelect
              label="Tipo de Previsión Voluntaria"
              placeholder="Seleccionar Tipo de Previsión Voluntaria"
              isDisabled
            />
          </Col>
        </Row>
      </Col>
    );

    return (
      <>
        <NestedAttributes
          removeFirstItem
          mapInputs={mapResults}
          arrayValues={voluntaryPrevisionsAttributes}
          setFieldValue={setFieldValue}
          valuePath="employee[contract][employeePrevisionAttributes][voluntaryPrevisionsAttributes]"
          newAttributes={{
            voluntaryPrevisionType: '',
            unitOfAccount: '',
            agreedQuantity: '',
            employerContribution: '',
            voluntaryPensionFundId: '',
            voluntaryPensionFundType: '',
            taxReduction: true,
            contractNumber: '',
            paymentMethod: '',
            periodNumber: '',
            periodFrom: '',
            periodTo: '',
            indemnificationRate: '',
            voluntaryIdentificationNumber: '',
            voluntaryName: '',
            voluntaryLastName: '',
            voluntarySecondLastName: '',
            personalMovmentCode: '',
            voluntarySavingsAmount: ''
          }}
        />
      </>
    );
  };

  // Fetch HealthPrevisions
  fetchHealthPrevisions = () => {
    const { dispatch } = this.props;
    const params = {
      sort_column: 'name',
      sort_direction: 'asc',
      paginate: false
    };
    indexHealthPrevisionsRequest({
      dispatch,
      params,
      successCallback: response => {
        const { data } = response.data;
        this.setState({ healthPrevisions: data });
      }
    });
  };

  // Fetch PensionFunds
  fetchPensionFunds = () => {
    const { dispatch } = this.props;
    const params = {
      sort_column: 'name',
      sort_direction: 'asc',
      paginate: false
    };
    indexPensionFundsRequest({
      dispatch,
      params,
      successCallback: response => {
        const { data } = response.data;
        this.setState({ pensionFunds: data });
      }
    });
  };

  // Fetch VoluntaryPensionFunds
  fetchVoluntaryPensionFunds = () => {
    const { dispatch } = this.props;
    const params = {
      sort_column: 'name',
      sort_direction: 'asc',
      paginate: false
    };
    indexVoluntaryPensionFundsRequest({
      dispatch,
      params,
      successCallback: response => {
        const { data } = response.data;
        this.setState({ voluntaryPensionFunds: data });
      }
    });
  };

  healthPrevisionChange = (field, data) => {
    const { setFieldValue } = this.props;
    this.setState({ fonasaReadOnly: false, withHealthPrevision: true });
    if (data) {
      setFieldValue(field, data.value);
      setFieldValue('employee[contract][employeePrevisionAttributes][healthPrevisionName]', data.label.toLowerCase());
      if (data.label.toLowerCase() === 'fonasa') {
        setFieldValue('employee[contract][employeePrevisionAttributes][unitOfAccount]', 'percentage');
        setFieldValue('employee[contract][employeePrevisionAttributes][agreedQuantity]', 7);
        setFieldValue('employee[contract][employeePrevisionAttributes][parsedAgreedQuantity]', 7);
        this.setState({ fonasaReadOnly: true });
      } else if (data.label.toLowerCase() === 'sin isapre') {
        this.setState({ withHealthPrevision: false });
        setFieldValue('employee[contract][employeePrevisionAttributes][fun]', '');
        setFieldValue('employee[contract][employeePrevisionAttributes][unitOfAccount]', '');
        setFieldValue('employee[contract][employeePrevisionAttributes][agreedQuantity]', '');
        setFieldValue('employee[contract][employeePrevisionAttributes][parsedAgreedQuantity]', '');
        setFieldValue('employee[contract][employeePrevisionAttributes][firstAgreedAmount]', '');
        setFieldValue('employee[contract][employeePrevisionAttributes][parsedFirstAgreedAmount]', '');
        setFieldValue('employee[contract][employeePrevisionAttributes][secondAgreedAmount]', '');
        setFieldValue('employee[contract][employeePrevisionAttributes][parsedSecondAgreedAmount]', '');
      }
    } else {
      this.setState({ withHealthPrevision: false });
      setFieldValue(field, '');
    }
  };

  unitOfAccountChange = (field, data) => {
    const { setFieldValue } = this.props;

    setFieldValue(field, data ? data.value : '');

    setFieldValue('employee[contract][employeePrevisionAttributes][agreedQuantity]', 0);
    setFieldValue('employee[contract][employeePrevisionAttributes][parsedAgreedQuantity]', 0);
    setFieldValue('employee[contract][employeePrevisionAttributes][firstAgreedAmount]', 0);
    setFieldValue('employee[contract][employeePrevisionAttributes][parsedFirstAgreedAmount]', 0);
    setFieldValue('employee[contract][employeePrevisionAttributes][secondAgreedAmount]', 0);
    setFieldValue('employee[contract][employeePrevisionAttributes][parsedSecondAgreedAmount]', 0);

    if (data) {
      if (
        data.value === 'seven_percent_uf_pesos' ||
        data.value === 'percentage' ||
        data.value === 'seven_percent_uf' ||
        data.value === 'seven_percent_pesos'
      ) {
        setFieldValue('employee[contract][employeePrevisionAttributes][agreedQuantity]', 7);
        setFieldValue('employee[contract][employeePrevisionAttributes][parsedAgreedQuantity]', 7);
      }
    } else {
      setFieldValue(field, 'percentage');
    }
  };

  agreedAmounts = () => {
    const { fonasaReadOnly } = this.state;
    const { errors, touched, setFieldValue, values } = this.props;
    const {
      employeePrevisionAttributes: { secondAgreedAmount, unitOfAccount }
    } = values.employee?.contract;
    let leftAddon = '%';
    if (!unitOfAccount) {
      leftAddon = '$';
      return (
        <Col md={6}>
          <Field name="employee[contract][employeePrevisionAttributes][parsedSecondAgreedAmount]">
            {({ field }) => (
              <FormikNumber
                {...field}
                abbr
                decimalScale={leftAddon === '$' ? 0 : 2}
                leftAddon={leftAddon}
                fieldName="employee[contract][employeePrevisionAttributes][secondAgreedAmount]"
                label="Cantidad acordada $"
                value={secondAgreedAmount}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                maxLength="20"
              />
            )}
          </Field>
        </Col>
      );
    }
    if (unitOfAccount.includes('uf')) {
      leftAddon = 'UF';
      return (
        <Col md={6}>
          <Field name="employee[contract][employeePrevisionAttributes][parsedFirstAgreedAmount]">
            {({ field }) => (
              <FormikNumber
                {...field}
                abbr
                decimalScale={leftAddon === '$' ? 0 : 10}
                leftAddon={leftAddon}
                fieldName="employee[contract][employeePrevisionAttributes][firstAgreedAmount]"
                label="Cantidad acordada UF"
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                maxLength="20"
              />
            )}
          </Field>
        </Col>
      );
    }
    if (unitOfAccount.includes('pesos')) {
      leftAddon = '$';
      return (
        <Col md={6}>
          <Field name="employee[contract][employeePrevisionAttributes][parsedSecondAgreedAmount]">
            {({ field }) => (
              <FormikNumber
                {...field}
                abbr
                decimalScale={leftAddon === '$' ? 0 : 2}
                leftAddon={leftAddon}
                fieldName="employee[contract][employeePrevisionAttributes][secondAgreedAmount]"
                label="Cantidad acordada $"
                value={secondAgreedAmount}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                maxLength="20"
              />
            )}
          </Field>
        </Col>
      );
    }
    return (
      <Col md={6}>
        <Field name="employee[contract][employeePrevisionAttributes][parsedAgreedQuantity]">
          {({ field }) => (
            <FormikNumber
              {...field}
              abbr
              decimalScale={leftAddon === '$' ? 0 : 2}
              leftAddon={leftAddon}
              fieldName="employee[contract][employeePrevisionAttributes][agreedQuantity]"
              readOnly={fonasaReadOnly}
              label="Cantidad acordada"
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              maxLength="20"
            />
          )}
        </Field>
      </Col>
    );
  };

  render() {
    const {
      employeePrevision,
      errors,
      touched,
      values,
      setFieldValue,
      setFieldTouched,
      remunerations = true
    } = this.props;
    const { healthPrevisions, pensionFunds, fonasaReadOnly, withHealthPrevision } = this.state;
    const { employeePrevisionAttributes = {} } = values.employee?.contract;
    const {
      healthPrevisionId,
      heavyLaborAdditional,
      ineCode,
      ipsType,
      pensionFundId,
      pensionScheme,
      pensioner,
      isOldAge,
      unemploymentInsuranceReason
    } = employeePrevision;

    const hasHeavyLabor =
      employeePrevisionAttributes.heavyLaborAdditional === 'less_heavy_labor' ||
      employeePrevisionAttributes.heavyLaborAdditional === 'with_heavy_labor';
    const isPensioner =
      employeePrevisionAttributes.pensioner === 'pensioned_and_contributing' ||
      employeePrevisionAttributes.pensioner === 'pensioned_and_not_contributing';

    const pensionerOptions =
      employeePrevisionAttributes.pensionScheme === 'not_listed'
        ? [
            { label: 'Pensionado y no cotiza', value: 'pensioned_and_not_contributing' },
            {
              label: 'Exento de cotizar (Mujer mayor de 60 años, Hombre mayor de 65 o Extranjero)',
              value: 'exempt_from_contributing'
            }
          ]
        : pensionerTypes;
    return (
      <>
        <Row>
          <Col md={6}>
            <Field name="employee[contract][employeePrevisionAttributes][pensionScheme]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr={remunerations}
                  isClearable
                  label="Régimen previsional"
                  placeholder="Seleccionar régimen previsional"
                  options={pensionSchemes}
                  defaultValue={pensionScheme}
                  onChange={data => {
                    setFieldValue(field.name, data ? data.value : '');
                    if (data?.value) {
                      setFieldValue('employee.contract.employeePrevisionAttributes.pensioner', '');
                    }
                  }}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          {employeePrevisionAttributes.pensionScheme === 'afp' && (
            <>
              <Col md={6}>
                <Field name="employee[contract][employeePrevisionAttributes][pensionAffiliationDate]">
                  {({ field }) => (
                    <FormikDatePicker
                      {...field}
                      isOutsideRange={() => false}
                      label="Fecha de Afiliación AFP"
                      tooltipText="Indica la fecha en la que se afilió por primera vez al sistema de AFP"
                      placeholder="dd/mm/aaaa"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={6}>
                <Field name="employee[contract][employeePrevisionAttributes][pensionFundId]">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      abbr
                      label="AFP"
                      placeholder="Seleccionar AFP"
                      options={pensionFunds}
                      defaultValue={pensionFundId}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      setFieldTouched={() => setFieldTouched(field.name)}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </>
          )}
          {employeePrevisionAttributes.pensionScheme === 'ips' && (
            <>
              <Col md={6}>
                <Field name="employee[contract][employeePrevisionAttributes][ipsType]">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      abbr
                      label="Fondo de Cotización"
                      placeholder="Seleccionar fondo de cotización"
                      options={ipsTypes}
                      defaultValue={ipsType}
                      onChange={data => setFieldValue(field.name, data ? data.value : '')}
                      setFieldTouched={() => setFieldTouched(field.name)}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={3}>
                <Field name="employee[contract][employeePrevisionAttributes][parsedPensionSchemeRate]">
                  {({ field }) => (
                    <FormikNumber
                      {...field}
                      abbr
                      label="Tasa del régimen general"
                      leftAddon="%"
                      fieldName="employee[contract][employeePrevisionAttributes][pensionSchemeRate]"
                      decimalScale={2}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      maxLength="5"
                    />
                  )}
                </Field>
              </Col>
              <Col md={3}>
                <Field name="employee[contract][employeePrevisionAttributes][parsedEvictionContributionRate]">
                  {({ field }) => (
                    <FormikNumber
                      {...field}
                      abbr
                      decimalScale={2}
                      leftAddon="%"
                      fieldName="employee[contract][employeePrevisionAttributes][evictionContributionRate]"
                      label="Tasa cot. Desahucio ex-régimen"
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      maxLength="5"
                    />
                  )}
                </Field>
              </Col>
            </>
          )}
          <Col md={isPensioner ? 3 : 6}>
            <Field name="employee[contract][employeePrevisionAttributes][pensioner]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr={remunerations}
                  label="Tipo de Trabajador"
                  placeholder="Seleccionar tipo de trabajador"
                  options={pensionerOptions}
                  defaultValue={pensioner}
                  onChange={data => {
                    if (['active_not_pensioned', 'active_over_65_years'].includes(data.value)) {
                      setFieldValue('employee.contract.employeePrevisionAttributes.isOldAge', '');
                    }
                    setFieldValue(field.name, data ? data.value : '');
                  }}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          {isPensioner && (
            <Col md={3}>
              <Field name="employee[contract][employeePrevisionAttributes][isOldAge]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Pensionado por Vejez"
                    placeholder="Seleccionar Opción"
                    options={isOldAgeOptions}
                    defaultValue={isOldAge}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          )}
        </Row>
        <Row>
          <Col md={6}>
            <Field name="employee[contract][employeePrevisionAttributes][healthPrevisionId]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr={remunerations}
                  label="Salud"
                  placeholder="Seleccionar institución"
                  options={healthPrevisions}
                  defaultValue={healthPrevisionId}
                  onChange={data => this.healthPrevisionChange(field.name, data)}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  isClearable
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          {fonasaReadOnly ? (
            <Col md={6}>
              <Field name="employee[contract][employeePrevisionAttributes][fun]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    label="FUN"
                    tooltipText="El Formulario Único de Notificación (FUN) es un documento que forma parte del contrato
                                 de salud, donde se detalla toda la información requerida del afiliado y sus
                                 beneficiarios, el cual está normado por la Superintendencia de Salud. En este campo
                                 debe incorporar el número de FUN."
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          ) : (
            !fonasaReadOnly &&
            employeePrevisionAttributes.healthPrevisionId &&
            withHealthPrevision && (
              <Col md={6}>
                <Field name="employee[contract][employeePrevisionAttributes][fun]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      label="FUN"
                      tooltipText="El Formulario Único de Notificación (FUN) es un documento que forma parte del contrato 
                                 de salud, donde se detalla toda la información requerida del afiliado y sus
                                 beneficiarios, el cual está normado por la Superintendencia de Salud. En este campo
                                 debe incorporar el número de FUN."
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            )
          )}
        </Row>
        {withHealthPrevision && (
          <Row>
            <Col md={6}>
              <Field name="employee[contract][employeePrevisionAttributes][unitOfAccount]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Unidad de pago"
                    placeholder="Seleccione unidad de pago"
                    options={fonasaReadOnly ? [{ label: '%', value: 'percentage' }] : unitOfAccountTypes}
                    defaultValue={employeePrevisionAttributes.unitOfAccount}
                    onChange={data => this.unitOfAccountChange(field.name, data)}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            {this.agreedAmounts()}
          </Row>
        )}
        <Row>
          <Col md={12}>
            <Field name="employee[contract][employeePrevisionAttributes][ineCode]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  label="Código INE"
                  placeholder="Seleccionar Código INE"
                  options={ineCodeTypes}
                  defaultValue={ineCode}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field name="employee[contract][employeePrevisionAttributes][unemploymentInsurance]">
              {({ field }) => (
                <FormikCheckBox {...field} margin="mt-4" field={field} label="¿Adscribe al seguro de cesantía?" />
              )}
            </Field>
          </Col>
          {!employeePrevisionAttributes.unemploymentInsurance && (
            <Col md={6}>
              <Field name="employee[contract][employeePrevisionAttributes][unemploymentInsuranceReason]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr={remunerations}
                    label="Razón"
                    placeholder="Seleccionar una razón"
                    options={unemploymentInsuranceReasons}
                    defaultValue={unemploymentInsuranceReason}
                    onChange={data => setFieldValue(field.name, data ? data.value : '')}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          )}
          <Col md={12}>
            <Field name="employee[contract][employeePrevisionAttributes][youthAllowance]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  field={field}
                  label="Se encuentra bajo subsidio al empleo joven"
                  tooltipText="Para jóvenes entre 18 y 24 años. Que pertenezcan al 40% más vulnerable de la población
                  según el Registro Social de Hogares. (Si no está registrado se puede realizar en
                  registrosocial.gob.cl o en tu municipio). Calendario El pago del #SEJ es anual y se
                  calcula de acuerdo a las rentas brutas recibidas el año anterior. Se paga una vez al
                  año en el mes de agosto, pero puedes acceder a anticipos mensuales.
                  (Fuente: http://www.sence.cl/portal/Oportunidad-al-Empleo-Joven/)"
                  tooltipVariant="modal"
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="employee[contract][employeePrevisionAttributes][heavyLaborAdditional]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  label="Trabajo Pesado - Porcentaje de Cotización Adicional"
                  placeholder="Seleccionar porcentaje de cotización adicional"
                  options={heavyLaborAdditionals}
                  defaultValue={heavyLaborAdditional}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          {hasHeavyLabor && (
            <Col md={6}>
              <Field name="employee[contract][employeePrevisionAttributes][heavyLaborStation]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    label="Puesto trabajo pesado"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    setFieldValue={setFieldValue}
                  />
                )}
              </Field>
            </Col>
          )}
        </Row>
        <h4 className="text-uppercase">Cotización Voluntaria</h4>
        {this.voluntaryPrevisionsInputs()}
      </>
    );
  }
}

export const yupPrevision = Yup.object().shape({
  agreedQuantity: Yup.number()
    .max(10000000000, 'Deben ser menos de 10 números')
    .nullable(),
  evictionContributionRate: Yup.number().when('pensionScheme', {
    is: val => val === 'ips',
    then: Yup.number()
      .required('Debes seleccionar una tasa Cotización Desahucio Ex-Cajas de Previsión')
      .min(0, 'Debe ser mayor o igual a 0')
      .max(99.99, 'Debe ser menor o igual a 99,99')
      .test('two-decimals', 'La tasa de Cotización debe tener exactamente 2 decimales', value => {
        if (!value) return false;
        return /^\d+(.\d{1,2})?$/.test(value);
      }),
    otherwise: Yup.number().nullable()
  }),
  healthPrevisionId: Yup.string().required('Debes introducir un Tipo de Salud'),
  heavyLaborAdditional: Yup.string().nullable(),
  heavyLaborStation: Yup.string().when('heavyLaborAdditional', {
    is: val => val === 'less_heavy_labor' || val === 'with_heavy_labor',
    then: Yup.string().max(20, 'Máximo 20 caracteres'),
    otherwise: Yup.string().nullable()
  }),
  ineCode: Yup.string().nullable(),
  ipsType: Yup.string().when('pensionScheme', {
    is: val => val === 'ips',
    then: Yup.string()
      .required('Debes seleccionar un fondo de cotización')
      .typeError('Debes seleccionar un fondo de cotización'),
    otherwise: Yup.string().nullable()
  }),
  isOldAge: Yup.string().when('pensioner', {
    is: val => val === 'pensioned_and_contributing' || val === 'pensioned_and_not_contributing',
    then: Yup.string().required('Debes seleccionar si eres pensionado por vejez'),
    otherwise: Yup.string().nullable()
  }),
  fun: Yup.string()
    .alphanumeric('Deben ser caracteres alfanuméricos')
    .max(60, 'Deben ser máximo 60 caracteres')
    .when('healthPrevisionId', {
      is: val => val !== '5',
      then: Yup.string().required('Debes ingresar el formulario único de notificación'),
      otherwise: Yup.string().nullable()
    }),

  pensionFundId: Yup.string().when('pensionScheme', {
    is: val => val === 'afp',
    then: Yup.string()
      .required('Debes seleccionar AFP')
      .typeError('Debes seleccionar AFP'),
    otherwise: Yup.string().nullable()
  }),
  pensionScheme: Yup.string()
    .nullable()
    .required('Debes ingresar un regimen previsional'),
  pensionSchemeRate: Yup.number().when('pensionScheme', {
    is: val => val === 'ips',
    then: Yup.number()
      .required('Debes seleccionar un régimen previsional')
      .typeError('Debes seleccionar un régimen previsional')
      .min(0, 'Debe ser mayor o igual a 0')
      .max(100, 'No puede ser mayor al 100'),
    otherwise: Yup.number().nullable()
  }),
  pensioner: Yup.string()
    .nullable()
    .required('Debes seleccionar una opción de pensión'),
  firstAgreedAmount: Yup.number().when('unitOfAccount', {
    is: val => val === 'uf',
    then: Yup.number()
      .positive('Debe ser mayor a 0')
      .required('Debes ingresar una cantidad acordada en UF')
      .max(10000000000, 'Deben ser menos de 10 números'),
    otherwise: Yup.number().nullable()
  }),
  secondAgreedAmount: Yup.number().when('unitOfAccount', {
    is: val => val === 'pesos',
    then: Yup.number()
      .positive('Debe ser mayor a 0')
      .required('Debes ingresar una cantidad acordada en $')
      .max(10000000000, 'Deben ser menos de 10 números'),
    otherwise: Yup.number().nullable()
  }),
  unemploymentInsurance: Yup.boolean(),
  unemploymentInsuranceReason: Yup.string().when('unemploymentInsurance', {
    is: val => val === false,
    then: Yup.string().required('Debes seleccionar una razón'),
    otherwise: Yup.string().nullable()
  }),
  unitOfAccount: Yup.string().when('healthPrevisionId', {
    is: val => val !== '12',
    then: Yup.string().required('Debes introducir una Unidad de pago'),
    otherwise: Yup.string().nullable()
  }),
  voluntaryPrevisionsAttributes: Yup.array().of(
    Yup.object().shape({
      agreedQuantity: Yup.number()
        .when('voluntaryPrevisionType', {
          is: val => val !== undefined && val !== 'voluntary_affiliate' && val !== 'indemnity_event',
          then: Yup.number()
            .required('Debes ingresar una cantidad')
            .min(0, 'Debe ser mayor o igual a 0')
            .max(100000000000000000000, 'Deben ser menos de 20 números'),
          otherwise: Yup.number().nullable()
        })
        .when('unitOfAccount', {
          is: unitOfAccount => unitOfAccount === 'percentage',
          then: Yup.number()
            .min(0, 'Debe ser mayor o igual a 0')
            .max(100, 'Debe ser menor o igual a 100')
        }),
      contractNumber: Yup.string().when('voluntaryPrevisionType', {
        is: val => val === 'apv' || val === 'collective_apv',
        then: Yup.string()
          .required('Debes seleccionar un numero de contrato')
          .alphanumeric('Deben ser caracteres alfanuméricos')
          .max(20, 'El número de contrato debe tener como máximo 20 caracteres'),
        otherwise: Yup.string().nullable()
      }),
      employerContribution: Yup.number().when('voluntaryPrevisionType', {
        is: val => val === 'collective_apv',
        then: Yup.number()
          .required('Debes ingresar un aporte del empleador')
          .min(0, 'Debe ser mayor o igual a 0')
          .max(100000000000000000000, 'Deben ser menos de 20 números'),
        otherwise: Yup.number().nullable()
      }),
      personalMovmentCode: Yup.string().when('voluntaryPrevisionType', {
        is: val => val === 'voluntary_affiliate',
        then: Yup.string().required('Debes seleccionar un código de movimiento personal'),
        otherwise: Yup.string().nullable()
      }),
      paymentMethod: Yup.string().when('voluntaryPrevisionType', {
        is: val => val === 'apv' || val === 'collective_apv',
        then: Yup.string().required('Debes seleccionar un método de pago'),
        otherwise: Yup.string().nullable()
      }),
      periodNumber: Yup.number().when('voluntaryPrevisionType', {
        is: val => val === 'indemnity_event',
        then: Yup.number()
          .required('Debes seleccionar un número de periodo')
          .integer('Debe ser un número entero')
          .min(0, 'Debe ser mayor o igual a 0')
          .max(99, 'No puede ser mayor al 99'),
        otherwise: Yup.number().nullable()
      }),
      periodFrom: Yup.date().when('voluntaryPrevisionType', {
        is: val => val === 'indemnity_event' || val === 'voluntary_affiliate',
        then: Yup.date()
          .formatdate()
          .required('Debes seleccionar desde que periodo')
          .when('periodTo', (periodTo, schema) => {
            const isValidDate = moment(periodTo).isValid();
            if (periodTo && isValidDate) {
              schema.max(periodTo, 'Debe ser menor o igual a la fecha de término');
            }
            return schema;
          })
      }),
      periodTo: Yup.date()
        .nullable()
        .when(
          ['voluntaryPrevisionType', 'personalMovmentCode'],
          (voluntaryPrevisionType, personalMovmentCode, schema) => {
            return voluntaryPrevisionType === 'indemnity_event' ||
              (voluntaryPrevisionType === 'voluntary_affiliate' && personalMovmentCode === 'contribution_cessation')
              ? schema
                  .required('Debes seleccionar hasta qué periodo')
                  .formatdate()
                  .when('periodFrom', (periodFrom, innerSchema) => {
                    return periodFrom && innerSchema.min(periodFrom, 'Debe ser mayor o igual a la fecha de inicio');
                  })
              : schema;
          }
        ),
      indemnificationRate: Yup.number().when('voluntaryPrevisionType', {
        is: val => val === 'indemnity_event',
        then: Yup.number()
          .required('Debes seleccionar una tasa de indemnización')
          .max(8.33, 'No puede ser mayor a 8.33')
          .min(4.11, 'Debe ser mayor o igual a 4.11')
          .test('two-decimals', 'La tasa de indemnización debe tener hasta 2 decimales', value => {
            if (!value) return false;
            return /^\d+(.\d{1,2})?$/.test(value);
          })
      }),
      taxReduction: Yup.boolean(),
      unitOfAccount: Yup.string().when('voluntaryPrevisionType', {
        is: val => val !== undefined && val !== 'indemnity_event',
        then: Yup.string().required('Debes seleccionar una unidad de cuenta'),
        otherwise: Yup.string().nullable()
      }),
      voluntaryPensionFundId: Yup.string().when('voluntaryPrevisionType', {
        is: val => val !== undefined,
        then: Yup.string().required('Debes seleccionar una institución'),
        otherwise: Yup.string().nullable()
      }),
      voluntaryPensionFundType: Yup.string().when('voluntaryPrevisionType', {
        is: val => val === 'apv',
        then: Yup.string().required('Debes seleccionar un tipo de Régimen Tributario'),
        otherwise: Yup.string().nullable()
      }),
      voluntaryIdentificationNumber: Yup.string().when('voluntaryPrevisionType', {
        is: val => val === 'voluntary_affiliate',
        then: Yup.string()
          .rut('Debes ingresar un rut valido')
          .required('Debes ingresar el rut afiliado voluntario')
      }),
      voluntaryName: Yup.string().when('voluntaryPrevisionType', {
        is: val => val === 'voluntary_affiliate',
        then: Yup.string()
          .required('Debes ingresar el nombre de afiliado voluntario')
          .matches(/^(?!\s+$)[A-Za-z\s]+$/, 'Solo se permiten letras'),
        otherwise: Yup.string().nullable()
      }),
      voluntaryLastName: Yup.string().when('voluntaryPrevisionType', {
        is: val => val === 'voluntary_affiliate',
        then: Yup.string()
          .required('Debes ingresar el apellido paterno del afiliado voluntario')
          .matches(/^(?!\s+$)[A-Za-z\s]+$/, 'Solo se permiten letras'),
        otherwise: Yup.string().nullable()
      }),
      voluntarySecondLastName: Yup.string().when('voluntaryPrevisionType', {
        is: val => val === 'voluntary_affiliate',
        then: Yup.string()
          .required('Debes ingresar el apellido materno del afiliado voluntario')
          .matches(/^(?!\s+$)[A-Za-z\s]+$/, 'Solo se permiten letras'),
        otherwise: Yup.string().nullable()
      }),
      voluntarySavingsAmount: Yup.number().when('voluntaryPrevisionType', {
        is: val => val === 'voluntary_affiliate',
        then: Yup.number()
          .required('Debes ingresar un monto de ahorro voluntario')
          .min(0, 'Debe ser mayor que 0'),
        otherwise: Yup.number().nullable()
      }),
      voluntaryPrevisionType: Yup.string().required('Debes seleccionar un tipo de previsión voluntaria')
    })
  ),
  youthAllowance: Yup.bool()
});

export default connect()(EmployeePrevisionTab);
