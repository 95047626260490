import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import OvertimeCard from './OvertimeCard';

const Headercards = ({
  cards,
  setMoreData,
  setCustomParams,
  setNameRangePicker,
  setDefaultStartDate,
  setDefaultEndDate
}) => {
  const [activeCard, setActiveCard] = useState('all');

  const handleClick = card => {
    switch (card) {
      case 'pending':
        setCustomParams({ status: 'pending' });
        setNameRangePicker('date_range');
        setActiveCard('pending');
        setDefaultStartDate(null);
        setDefaultEndDate(null);
        setMoreData(v => !v);
        break;
      case 'rejected':
        setCustomParams({ status: 'rejected' });
        setNameRangePicker('date_range');
        setActiveCard('rejected');
        setDefaultStartDate(null);
        setDefaultEndDate(null);
        setMoreData(v => !v);
        break;
      default:
        setCustomParams({});
        setNameRangePicker('date_range');
        setActiveCard('all');
        setDefaultStartDate(null);
        setDefaultEndDate(null);
        setMoreData(v => !v);
        break;
    }
  };

  return (
    <>
      <Row className="mt-3 mb-3">
        <Col md={4} xs={12}>
          <OvertimeCard
            variant="info"
            title={cards.all}
            label="Horas Extras Solicitadas"
            active={activeCard === 'all'}
            onClick={() => handleClick('all')}
          />
        </Col>
        <Col md={4} xs={12}>
          <OvertimeCard
            variant="warning"
            title={cards.pending}
            label="Horas Extras por Autorizar"
            active={activeCard === 'pending'}
            onClick={() => handleClick('pending')}
          />
        </Col>
        <Col md={4} xs={12}>
          <OvertimeCard
            variant="danger"
            title={cards.rejected}
            label="Horas Extras Rechazadas"
            active={activeCard === 'rejected'}
            onClick={() => handleClick('rejected')}
          />
        </Col>
      </Row>
    </>
  );
};

export default Headercards;

Headercards.propTypes = {
  cards: PropTypes.shape({
    pending: PropTypes.number.isRequired,
    all: PropTypes.number.isRequired,
    rejected: PropTypes.number.isRequired
  }),
  setMoreData: PropTypes.func,
  setCustomParams: PropTypes.func,
  setNameRangePicker: PropTypes.func,
  setDefaultStartDate: PropTypes.func,
  setDefaultEndDate: PropTypes.func
};

Headercards.defaultProps = {
  cards: {
    pending: 0,
    all: 0,
    rejected: 0
  },
  setMoreData: () => {},
  setCustomParams: () => {},
  setNameRangePicker: () => {},
  setDefaultStartDate: () => {},
  setDefaultEndDate: () => {}
};
