import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col, Card } from 'react-bootstrap';
import * as Yup from 'yup';
import { FormikInput, FormikSelect, FormikTimePicker } from '../../../components';

const Filters = props => {
  const { errors, touched, setFieldValue, onHide, onRequest } = props;
  const optionsFormat = [
    { label: 'Original', value: 'O' },
    { label: 'Rectificatoria', value: 'R' }
  ];

  return (
    <Form>
      <Card style={{ zIndex: 'unset' }}>
        <Row>
          <Col className="mt-2">
            <Card className="card-dt">
              <Col className="top-header-green-dt card-header-title-dt pt-1 pb-1">Declaración DJ1887</Col>
              <Card.Body className="div-content card-body-padding">
                <Row>
                  <Col sm={12} md={2} xs={12}>
                    <Field name="djjReport[year]">
                      {({ field }) => (
                        <FormikTimePicker
                          abbr
                          label="Año a declarar"
                          placeholder="Seleccione año"
                          dateFormat="yyyy"
                          showYearPicker
                          onChange={date => {
                            setFieldValue(field.name, date);
                          }}
                          selected={field.value}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={4} xs={12}>
                    <Field name="djjReport[declarationType]">
                      {({ field }) => (
                        <FormikSelect
                          abbr
                          {...field}
                          label="Tipo de Declaración"
                          placeholder="Seleccionar tipo de declaración"
                          options={optionsFormat}
                          isClearable
                          onChange={data => setFieldValue(field.name, data ? data.value : '')}
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col sm={12} md={2} xs={12}>
                    <Field name="djjReport[previousFolio]">
                      {({ field }) => (
                        <FormikInput
                          {...field}
                          abbr
                          label="Folio Aterior"
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card>
      <Row className="d-flex justify-content-end mt-4 mb-4">
        <Col md={2}>
          <Button className="btn-block" type="submit" onClick={onHide} disabled={onRequest}>
            Exportar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  djjReport: Yup.object().shape({
    year: Yup.mixed().test('is-year', 'Debes seleccionar un año', value => {
      return value !== undefined && value !== '' && value !== null;
    }),
    declarationType: Yup.string().required('Debes seleccionar un tipo de declaración'),
    previousFolio: Yup.string()
      .matches(/^[0-9]+$/, 'El campo debe contener solo números')
      .test('is-positive', 'El número debe ser mayor a 0', value => parseFloat(value) > 0)
      .max(10, 'El campo no debe exceder los 10 caracteres')
      .required('Este campo es obligatorio')
  })
});

const setInitialValues = props => {
  const { currentCompany } = props;
  return {
    djjReport: {
      year: '',
      declarationType: '',
      previousFolio: '',
      company: {
        id: currentCompany.id,
        nationalIdentification: currentCompany.nationalIdentification,
        businessName: currentCompany.businessName,
        address: currentCompany.address,
        commune: currentCompany.commune,
        email: currentCompany.email,
        phone: currentCompany.phone,
        representantNi: currentCompany.representantNi,
        yearOfAgreement: currentCompany.yearOfAgreement
      }
    }
  };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(Filters);
