import React from 'react'
import { MainLogo } from '../../components'

const About = ({ version }) => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <div style={{ paddingBottom: 25 }}>
        <MainLogo />
        <p className='text-center title'>Versión {version}</p>
      </div>
      <p>Peoplework © {currentYear}. Todos los derechos reservados</p>
    </>
  )
}

export default About
