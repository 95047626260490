import { Field, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { InputSelect } from '../Select';
import { debounceIndexJobManagementsRequest } from '../../../requests/jobManagements';
import { pairValue, resultFetchData } from './utils';

const JobManagementsFilter = ({ formikValue, params }) => {
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext();
  const [jobManagements, setJobManagements] = useState([]);
  const fetchJobManagments = (inputValue, callback) => {
    debounceIndexJobManagementsRequest({
      dispatch,
      params: {
        actives: true,
        name: inputValue,
        sort_column: 'name',
        paginate: false,
        ...params
      },
      successCallback: data => {
        setJobManagements(resultFetchData(data));
        callback(resultFetchData(data));
      }
    });
  };
  return (
    <Field name={formikValue}>
      {({ field }) => (
        <InputSelect
          {...field}
          label="Area"
          placeholder="Seleccionar area"
          request={fetchJobManagments}
          value={values.filterJobManagement}
          defaultValue={pairValue(jobManagements, values[formikValue])}
          onChange={data => setFieldValue(field.name, data ? data.value : '')}
          isClearable
        />
      )}
    </Field>
  );
};

export default JobManagementsFilter;
