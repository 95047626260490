import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexOvertimeRequest = ({
  dispatch,
  format = '',
  responseType = 'json',
  params = {},
  failureCallback,
  successCallback
}) => {
  ApiService.request('get', `/overtimes${format}`, {
    dispatch,
    params,
    failureCallback,
    responseType,
    successCallback
  });
};

export const createMassiveOvertimeRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/overtimes/massive_create', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const createOvertimeRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/overtimes', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateOvertimeRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/overtimes/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const approveOvertimeRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/overtimes/${id}/approve`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const rejectOvertimeRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/overtimes/${id}/reject`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteOvertimeRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/overtimes/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexOvertimeRequest = AwesomeDebouncePromise(indexOvertimeRequest, 300);
