import { Field, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { InputSelect } from '../Select';
import { debounceIndexCostCentersRequest } from '../../../requests/costCenters';
import { pairValue, resultFetchData } from './utils';

const CostCentersFilter = ({ formikValue, params = {} }) => {
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext();
  const [costCenters, setCostCenters] = useState([]);
  const fetchCostCenters = (inputValue, callback) => {
    debounceIndexCostCentersRequest({
      dispatch,
      params: {
        actives: true,
        query: inputValue,
        sort_column: 'name',
        display_length: 100,
        ...params
      },
      successCallback: data => {
        setCostCenters(resultFetchData(data));
        callback(resultFetchData(data));
      }
    });
  };

  return (
    <Field name={formikValue}>
      {({ field }) => (
        <InputSelect
          {...field}
          label="Centro de costo"
          placeholder="Seleccionar centro de costo"
          request={fetchCostCenters}
          value={values[formikValue]}
          defaultValue={pairValue(costCenters, values[formikValue])}
          onChange={data => setFieldValue(field.name, data ? data.value : '')}
          isClearable
        />
      )}
    </Field>
  );
};

export default CostCentersFilter;
