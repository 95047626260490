import React from 'react';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../config/abilityContext';
import { BasicDropdown, Icon } from '../../components';

const ShiftAssignmentsMassiveActions = ({ handleClick, disabled, model }) => {
  const ability = useAbility(AbilityContext);
  const actions = () => {
    const authorizedActions = [];
    if (ability.can('destroy', model)) {
      authorizedActions.push({
        icon: <Icon icon="trash" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'destroy',
        props: {
          onClick: () => handleClick('massive_destroy'),
          disabled
        },
        title: 'Eliminar'
      });
    }

    if (ability.can('read', model)) {
      authorizedActions.push({
        icon: <Icon icon="download-2" height="16px" width="16px" style={{ marginRight: 5 }} />,
        key: 'export',
        props: { onClick: () => handleClick('export') },
        title: 'Descargar Excel'
      });
    }

    return authorizedActions;
  };

  return (
    <div className="mb-4">
      <BasicDropdown
        block
        items={actions()}
        noArrow
        right
        titleDrop={
          <span className="align-middle">
            Acciones Masivas <Icon icon="ellipsis-vertical" height="20px" width="20px" />
          </span>
        }
        variant="outline-primary"
      />
    </div>
  );
};

export default ShiftAssignmentsMassiveActions;
