import React from 'react';
import BasicDropdown from '../Utils/Dropdown';
import Icon from '../Icons';

const ShiftActions = ({ handleFilters, filters }) => {
  const items = [
    {
      key: 'active',
      title: 'Activos',
      props: {
        onClick: () => handleFilters('active'),
        active: filters.active
      }
    },
    {
      key: 'inactive',
      title: 'Inactivos',
      props: {
        onClick: () => handleFilters('inactive'),
        active: !filters.active
      }
    }
  ]
  return (
    <BasicDropdown
      variant="outline-primary"
      responsive
      noArrow
      block
      right
      titleDrop={
        <span className="align-middle">
          Mostrar <Icon icon="ellipsis-vertical" height="20px" width="20px" />
        </span>
      }
      items={items}
    />
  );
}
export default ShiftActions;