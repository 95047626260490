import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexDataProcessRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback }) => {
  ApiService.request('get', `/data_processes`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });
};

export const deleteDataProcessRequest = (id, { dispatch, successCallback, failureCallback, callback }) =>
  ApiService.request('delete', `/data_processes/${id}`, {
    dispatch,
    successCallback,
    callback,
    failureCallback
  });

export const generatePaymentDataProcessRequest = (id, { dispatch, params, successCallback, callback }) =>
  ApiService.request('put', `/data_processes/${id}/generate_payment`, {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const createDataProcessRequest = ({ dispatch, params, formData, callback, successCallback }) =>
  ApiService.request('post', '/data_processes', {
    dispatch,
    params,
    formData,
    callback,
    successCallback
  });

export const updateDataProcessRequest = (
  id,
  { dispatch, params, formData, callback, failureCallback, successCallback }
) =>
  ApiService.request('put', `/data_processes/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback,
    failureCallback
  });

export const massiveDestroyDataProcesses = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('delete', '/data_processes/massive_destroy', {
    dispatch,
    params,
    formData: false,
    failureCallback,
    successCallback,
    callback
  });

export const massiveDeactivateDataProcesses = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('put', '/data_processes/massive_deactivate', {
    dispatch,
    params,
    formData: false,
    failureCallback,
    successCallback,
    callback
  });

export const exportDataProcessRequest = ({ dispatch, params, failureCallback, successCallback, callback }) => {
  ApiService.request('get', `/data_processes/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    callback
  });
};

export const generateMassPaymentDataProcessRequest = ({ dispatch, params, successCallback, callback }) =>
  ApiService.request('put', '/data_processes/mass_generate_payment', {
    dispatch,
    params,
    formData: true,
    successCallback,
    callback
  });

export const indexEmployeesDataProcessRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback
}) => {
  ApiService.request('get', `/data_processes/employees`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback
  });
};

export const debounceIndexDataProcessRequest = AwesomeDebouncePromise(indexDataProcessRequest, 300);
export const debounceIndexEmployeesDataProcessRequest = AwesomeDebouncePromise(indexEmployeesDataProcessRequest, 300);
