import React from 'react';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { Icon } from '../../components';
import { useSetTab } from '../../services/hooks';
import SalarySettlement from './SalarySettlement';
import PreviredFile from './PreviredFile';
import DTElectronic from './DTElectronic';
import { AbilityContext } from '../../config/abilityContext';
import WireTransfer from './WireTransfer';
import Dj1887 from './DJ1887';

function FileBroadcast({ location }) {
  const ability = useAbility(AbilityContext);
  const defaultKey = () => {
    if (ability.can('read_salary_settlement', 'FileBroadcast')) return 'salary-settlement';
    if (ability.can('previred_file', 'FileBroadcast')) return 'previred-file';
    if (ability.can('export_lre', 'FileBroadcast')) return 'dt-electronic';
    if (ability.can('bank_transfer', 'FileBroadcast')) return 'wire-transfer';
    return '';
  };
  const [key, setKey] = useSetTab(defaultKey(), location);
  const hasPrevired = ability.can('previred_file', 'FileBroadcast');

  return (
    <>
      <Row className="mt-4 mb-2">
        <Col>
          <h2 className="mb-3 mt-3">Emisión de Archivos</h2>
        </Col>
      </Row>
      <Tab.Container id="company-settings-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills">
          {ability.can('read_salary_settlement', 'FileBroadcast') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="salary-settlement">
                <Icon icon="document" className="mb-1" />
                Liquidaciones de Sueldo
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('bank_transfer', 'FileBroadcast') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="wire-transfer">
                <Icon icon="document" className="mb-1" />
                Transferencia Bancaria
              </Nav.Link>
            </Nav.Item>
          )}
          {hasPrevired && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="previred-file">
                <Icon icon="document" className="mb-1" />
                Archivo Previred
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('export_lre', 'FileBroadcast') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="dt-electronic">
                <Icon icon="document" className="mb-1" />
                L. R. Electrónico - DT
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('export_dj', 'FileBroadcast') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="dj1887">
                <Icon icon="document" className="mb-1" />
                Declaración Jurada 1887
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="salary-settlement">
            <SalarySettlement />
          </Tab.Pane>
          <Tab.Pane eventKey="wire-transfer">
            <WireTransfer />
          </Tab.Pane>
          <Tab.Pane eventKey="previred-file">
            <PreviredFile />
          </Tab.Pane>
          <Tab.Pane eventKey="dt-electronic">
            <DTElectronic />
          </Tab.Pane>
          <Tab.Pane eventKey="dj1887">
            <Dj1887 />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
}

export default FileBroadcast;
