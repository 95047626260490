import React from 'react';
import memoize from 'memoize-one';
import { ButtonTooltip, Icon } from '../../../components';

const columns = memoize((clickHandler, ability) => [
  {
    name: 'NOMBRE DEL TRABAJADOR',
    selector: 'employee_full_name',
    sortable: true,
    grow: '2',
    cell: item => item.full_name
  },
  {
    name: 'TURNO ASIGNADO',
    selector: 'assigned_shift_name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'INICIO DE TURNO',
    selector: 'shift_start',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FINALIZACION DEL TURNO',
    selector: 'shift_end',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        {ability.can('read', 'ShiftAssignment') && (
          <ButtonTooltip
            variant="circle-info"
            className="btn-circle"
            onClick={() => clickHandler(item, 'show')}
            text="Ver"
          >
            <Icon icon="eye" />
          </ButtonTooltip>
        )}
      </>
    ),
    grow: '1'
  }
]);

export default columns;
