import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import snakeCaseKeys from 'snakecase-keys';
import { sendAlert } from '../../../actions/utils';
import { createOvertimeRequest, approveOvertimeRequest } from '../../../requests/overtimes';

const useCreateOvertimeHook = () => {
  const dispatch = useDispatch();

  const handleCreateOvertime = useCallback(
    (overtime, setSubmitting, setModalShow, setMoreData) => {
      createOvertimeRequest({
        dispatch,
        params: snakeCaseKeys(overtime),
        formData: true,
        successCallback: () => {
          setSubmitting(false);
          setModalShow(false);
          setMoreData(v => !v);
          dispatch(sendAlert({ kind: 'success', message: 'Solicitud creada correctamente' }));
        },
        failureCallback: error => {
          const { response } = error;
          dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
          setSubmitting(false);
        }
      });
    },
    [dispatch]
  );

  const handleSignApproveOvertime = useCallback(
    (formKey, overtime, setSubmitting, handleSuccessRequest, handleFailureRequest) => {
      const { id, workflowRequestId } = overtime;
      const myParams = {
        id,
        workflowRequestId,
        signatureKey: formKey?.signatureKey
      };

      approveOvertimeRequest(id, {
        dispatch,
        params: {
          workflow_request: snakeCaseKeys(myParams)
        },
        successCallback: () => handleSuccessRequest('Solicitud de Horas Extras Firmada y Aprobada Correctamente'),
        failureCallback: error => {
          setSubmitting(false);
          handleFailureRequest(error);
        }
      });
    },
    [dispatch]
  );

  const handleApproveOvertime = useCallback(
    (overtime, setSubmitting, handleSuccessRequest, handleFailureRequest) => {
      const { id: overtimeId } = overtime;

      approveOvertimeRequest(overtimeId, {
        dispatch,
        formData: true,
        successCallback: () => handleSuccessRequest('Solicitud de Horas Extras aceptada correctamente'),
        failureCallback: error => handleFailureRequest(error),
        callback: () => setSubmitting(false)
      });
    },
    [dispatch]
  );

  return { handleCreateOvertime, handleApproveOvertime, handleSignApproveOvertime };
};

export default useCreateOvertimeHook;
