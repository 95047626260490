import { Field, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { InputSelect } from '../Select';
import { debounceIndexBalancesRequest } from '../../../requests/balances';
import { resultFetchData, pairValue } from './utils';

const BalancesFilter = ({ formikValue, params = {} }) => {
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext();
  const [balances, setBalances] = useState([]);
  const fetchBalanceAssets = (inputValue, callback) => {
    const request = async () => {
      debounceIndexBalancesRequest({
        dispatch,
        params: {
          selector: true,
          filter_type: 1,
          filter_by_advance: true,
          name: inputValue,
          ...params
        },
        successCallback: response => {
          setBalances(resultFetchData(response));
          callback(resultFetchData(response));
        }
      });
    };
    request();
  };

  return (
    <Field name={formikValue}>
      {({ field }) => (
        <InputSelect
          {...field}
          label="Concepto"
          placeholder="Seleccionar Concepto"
          request={fetchBalanceAssets}
          value={values[formikValue]}
          defaultValue={pairValue(balances, values[formikValue])}
          onChange={data => setFieldValue(field.name, data ? data.value : '')}
          isClearable
        />
      )}
    </Field>
  );
};

export default BalancesFilter;
