import React from 'react';
import memoize from 'memoize-one';
import { ButtonTooltip, DatatableEmployeeName, Icon } from '../../components';
import ShiftAssignmentStatus from './ShiftAssignmentStatus';

const columns = memoize((clickHandler, ability) => [
  {
    name: 'RUT Y NOMBRE',
    selector: 'employee_full_name',
    cell: item => <DatatableEmployeeName item={item.employee} fileName="file_info" name="full_name" fileUrl="file_url" />,
    grow: '3',
    sortable: true
  },
  {
    name: 'FECHA',
    selector: 'date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TURNO ACTUAL',
    selector: 'current_shift_name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TURNO ASIGNADO',
    selector: 'assigned_shift_name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'INICIO DE TURNO',
    selector: 'shift_start',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FINALIZACION DEL TURNO',
    selector: 'shift_end',
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ShiftAssignmentStatus item={item} type="text" />,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ACCIONES',
    cell: item => {
      const currentDate = new Date();
      const [day, month, year] = item.shift_start.split('/');
      const itemDate = new Date(year, month - 1, day);
      return (
        <>
          {ability.can('destroy', 'ShiftAssignment') && (
            <ButtonTooltip
              onClick={() => clickHandler(item, 'destroy')}
              variant="circle-danger"
              className="btn-circle"
              disabled={currentDate > itemDate}
              size="sm"
              text="Eliminar"
            >
              <Icon icon="trash" />
            </ButtonTooltip>
          )}
        </>
      );
    },
    grow: '1'
  }
]);

export default columns;
