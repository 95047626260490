/* eslint-disable func-names */
import React, { useEffect, useState } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col, Card, Accordion } from 'react-bootstrap';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormikInput, FormikSelect, FormikTimePicker } from '../../../components';
import { yearlyPayrollEmployeesRequest } from '../../../requests/payrolls';
import { debounceIndexCostCentersRequest } from '../../../requests/costCenters';
import { debounceIndexJobManagementsRequest } from '../../../requests/jobManagements';
import { debounceIndexBranchOfficesRequest } from '../../../requests/branchOffices';

const Filters = props => {
  const { errors, touched, setFieldValue, values, setFieldTouched, onHide, onRequest, isOpen, setIsOpen } = props;
  const [payrollsEmployees, setPayrollsEmployees] = useState([]);
  const dispatch = useDispatch();
  const [costCenters, setCostCenters] = useState([]);
  const ALL_SELECTED_LABEL = 'Todos';
  const [jobManagement, setJobManagement] = useState([]);
  const [branchOffices, setBranchOffices] = useState([]);

  const handleNewMulti = (data, field, allowEmpty = false) => {
    const newData = data.map(element => element.value);
    if (allowEmpty && !newData.length) newData.push('');
    setFieldValue(field, newData.includes('all') ? [] : newData);
  };
  const resultFetchData = (response, type) => {
    const result = response.data.data;
    return result.map(element => ({
      ...element,
      label: `${type ? `${element.code} - ` : ''} ${element.name}`,
      value: element.id
    }));
  };

  const fetchEmployeesPayrolls = params => {
    yearlyPayrollEmployeesRequest({
      dispatch,
      params: {
        ...params,
        paginate: false
      },
      successCallback: response => {
        const allEmployees = response.data;
        const dataToGroupSearch = [{ label: 'Todos', value: 'all' }, ...allEmployees];
        setPayrollsEmployees(response.data.length > 0 ? dataToGroupSearch : []);
      }
    });
  };
  const hasAllSelection = (groupSelection = []) => {
    return groupSelection.some(option => option.label === ALL_SELECTED_LABEL);
  };
  const handleDisabledSelection = (options, selectedOptions) => {
    if (hasAllSelection(selectedOptions)) {
      return true;
    }
    if (selectedOptions.length === 0) return false;
    return options.label === ALL_SELECTED_LABEL;
  };

  const handleEmployeeFilters = () => {
    if (values.filterByYear) {
      setFieldValue('employees', []);
      setFieldValue('filterEmployees', []);
      const params = { year: values.filterByYear };

      fetchEmployeesPayrolls(params);
    }
  };
  const fetchCostCenters = () => {
    debounceIndexCostCentersRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        display_length: 50
      },
      successCallback: data => {
        setCostCenters(resultFetchData(data));
      }
    });
  };

  const fetchJobManagments = () => {
    debounceIndexJobManagementsRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        display_length: 500
      },
      successCallback: data => {
        setJobManagement(resultFetchData(data));
      }
    });
  };

  const fetchBranchOffices = () => {
    debounceIndexBranchOfficesRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'name',
        display_length: 40
      },
      successCallback: data => {
        setBranchOffices(resultFetchData(data));
      }
    });
  };
  useEffect(handleEmployeeFilters, [values.filterByYear]);
  useEffect(fetchCostCenters, []);
  useEffect(fetchJobManagments, []);
  useEffect(fetchBranchOffices, []);
  return (
    <Form>
      <Row>
        <Col className="mt-2">
          <Accordion defaultActiveKey="0" activeKey={isOpen ? '0' : null}>
            <Card className="card-dt" style={{ overflow: 'visible' }}>
              <Col className="top-header-green card-header-title-dt">
                <Accordion.Toggle
                  as={Col}
                  eventKey="0"
                  onClick={() => setIsOpen(!isOpen)}
                  className="card-header-title-dt"
                >
                  Certificados de Sueldo y Sobre Sueldos
                </Accordion.Toggle>
              </Col>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="div-content card-body-padding">
                  <Row>
                    <Col sm={12} md={2} xs={12}>
                      <Field name="filterByYear">
                        {({ field }) => (
                          <FormikTimePicker
                            abbr
                            label="Año"
                            placeholder="Seleccione año"
                            dateFormat="yyyy"
                            showYearPicker
                            onChange={date => {
                              setFieldValue(field.name, date);
                            }}
                            selected={field.value}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col sm={12} md={3} xs={12}>
                      <Field name="employees">
                        {({ field }) => (
                          <FormikSelect
                            {...field}
                            label="Trabajadores"
                            placeholder="Seleccionar Trabajador"
                            isMulti
                            options={payrollsEmployees}
                            values={values.employee_ids}
                            onChange={data => {
                              handleNewMulti(data || [], 'filterEmployees', true);
                              setFieldValue(field.name, data || []);
                            }}
                            setFieldTouched={() => setFieldTouched(field.name)}
                            isOptionDisabled={handleDisabledSelection}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col sm={12} md={3} xs={12}>
                      <Field name="filterByRut">
                        {({ field }) => (
                          <FormikInput
                            {...field}
                            label="RUT:"
                            placeholder="Sin puntos (ej: 11111111-1)"
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={4} xs={12}>
                      <Field name="filterCostCenter">
                        {({ field }) => (
                          <FormikSelect
                            {...field}
                            label="Centro de Costos"
                            menuPlacement="top"
                            placeholder="Seleccionar Centro de Costos"
                            options={costCenters}
                            isClearable
                            onChange={data => setFieldValue(field.name, data ? data.value : '')}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col sm={12} md={4} xs={12}>
                      <Field name="filterJobManagment">
                        {({ field }) => (
                          <FormikSelect
                            {...field}
                            label="Área"
                            menuPlacement="top"
                            placeholder="Seleccionar Área"
                            options={jobManagement}
                            isClearable
                            onChange={data => setFieldValue(field.name, data ? data.value : '')}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col sm={12} md={4} xs={12}>
                      <Field name="filterBranchOffices">
                        {({ field }) => (
                          <FormikSelect
                            {...field}
                            label="Lugar de Prestación de Servicios"
                            placeholder="Seleccionar Lugar de Prestación de Servicios"
                            menuPlacement="top"
                            options={branchOffices}
                            isClearable
                            onChange={data => setFieldValue(field.name, data ? data.value : '')}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mt-4 mb-4">
        <Col md={2}>
          <Button className="btn-block" type="submit" onClick={onHide} disabled={onRequest}>
            Filtrar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  filterByYear: Yup.mixed().test('is-year', 'Debes seleccionar un año', value => {
    return value !== undefined && value !== '' && value !== null;
  }),
  employees: Yup.array().test('employees', 'Este campo es requerido', function(value) {
    const { filterByRut } = this.parent;
    if (filterByRut === undefined && value.length === 0) return false;
    return true;
  }),
  filterByRut: Yup.string().test('filterByRut', 'Este campo es requerido', function(value) {
    const { employees } = this.parent;
    if (employees.length === 0 && value === undefined) return false;
    return true;
  })
});

const setInitialValues = () => {
  return {
    filterByYear: '',
    filterByRut: '',
    employees: [],
    filterEmployees: [],
    filterBranchOffices: [],
    filterCostCenter: '',
    filterJobManagment: []
  };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: () => setInitialValues(),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(Filters);
