/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import { Button } from 'react-bootstrap';
import {
  Icon,
  ButtonTooltip,
  ApprovementStatus,
  ApproveRejectActions,
  DatatableEmployeeName
} from '../../../components';

const listActions = (item, clickHandler, ability, rrhh) => {
  const hasAction = ability.can('workflow_action', 'Overtime');
  const hasResend = ability.can('resend_notification', 'WorkflowRequest');
  const hasReminder = hasAction && hasResend && rrhh && item.status === 'pending';
  const hasDestroyAdmin = ability.can('destroy', 'Overtime') && rrhh && item.status !== 'pending';
  const hasDestroy = hasDestroyAdmin || (item.status === 'pending' && !rrhh);

  return (
    <>
      {!rrhh && (
        <ButtonTooltip
          onClick={() => clickHandler(item, 'show')}
          variant="circle-info"
          className="btn-circle"
          size="sm"
          text="Ver"
        >
          <Icon icon="eye" />
        </ButtonTooltip>
      )}

      {hasReminder && (
        <ButtonTooltip
          variant="circle-warning"
          className="btn-circle"
          text="Enviar Recordatorio"
          onClick={() => clickHandler(item, 'resend')}
        >
          <Icon icon="notifications" />
        </ButtonTooltip>
      )}

      {hasDestroy && (
        <ButtonTooltip
          onClick={() => clickHandler(item, 'destroy')}
          variant="circle-danger"
          className="btn-circle"
          size="sm"
          text="Eliminar"
        >
          <Icon icon="trash" />
        </ButtonTooltip>
      )}
    </>
  );
};

const getColumnEmployee = module => {
  if (module === 'list') {
    return {
      name: 'SOLICITADOR POR',
      selector: 'employee.full_name',
      sortable: true,
      grow: '2'
    };
  }

  return {
    name: 'NOMBRE DEL TRABAJADOR',
    selector: 'employee',
    cell: item => <DatatableEmployeeName item={item.employee} />,
    sortable: true,
    grow: '2',
    width: '250px',
    align: 'left'
  };
};

const columns = memoize((clickHandler, ability, module = 'list', rrhh = false) => [
  getColumnEmployee(module),
  {
    name: 'FECHA DE SOLICITUD',
    selector: 'request_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA HORAS EXTRAS',
    selector: 'date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'HORAS',
    selector: 'hours',
    sortable: true,
    grow: '1'
  },
  {
    name: 'MINUTOS',
    selector: 'minutes',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TIPO DE HORAS EXTRAS',
    selector: 'overtime_type',
    cell: item => item.translated_overtime_type,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} onClick={() => clickHandler(item, 'show')} />,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'APROBAR',
    cell: item => (
      <ApproveRejectActions
        resource="Overtime"
        item={item}
        clickHandler={clickHandler}
        approveText={item.request_reviewer_signature ? 'Firmar' : 'Aprobar'}
      />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    minWidth: '110px',
    grow: '0.5'
  },
  {
    name: 'ACCIONES',
    cell: item => {
      if (module === 'list') {
        return listActions(item, clickHandler, ability, rrhh);
      }

      return (
        <Button variant="pw" block onClick={() => clickHandler(item, 'actions')} size="sm">
          Gestionar
        </Button>
      );
    },
    grow: '1',
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    minWidth: '123px'
  }
]);

export default columns;
