import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { PieChart } from 'react-minimal-pie-chart';

const Graphic = ({ data, title }) => {
  const currencyCLPFormatter = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
    minimumFractionDigits: 0
  });
  return (
    <Card style={{ zIndex: 'unset' }}>
      <Col className="top-header-green card-header-title-dt">{title}</Col>
      <Row>
        <Col
          className="d-flex align-items-center justify-content-center"
          style={{ padding: 10 }}
          sm={12}
          md={12}
          xs={12}
          xl={3}
        >
          <PieChart
            animation
            animationEasing="ease-out"
            data={data}
            lineWidth={60}
            style={{ height: '150px', width: '150px' }} // Define el tamaño aquí
          />
        </Col>
        <Col className="align-items-center" style={{ margin: 'auto' }} sm={12} md={12} xs={12} xl={9}>
          {data &&
            data?.map(el => (
              <Row key={el.title}>
                <Col sm={12} md={4} xs={12} xl={3}>
                  <p
                    className="alert-p"
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    <span
                      className="dot"
                      style={{ backgroundColor: el.color, width: '10px', height: '10px', borderRadius: '50%' }}
                    />{' '}
                    <b>{currencyCLPFormatter.format(el.value)}</b>
                  </p>
                </Col>
                <Col sm={12} md={6} xs={12} xl={9}>
                  <p>
                    <b>{el.title}</b>
                  </p>
                </Col>
              </Row>
            ))}
        </Col>
      </Row>
    </Card>
  );
};

export default Graphic;
