import { Field } from 'formik';
import React from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { FormikCheckBox, FormikNumber } from '../../../components';

const ToleranceTab = ({ currentTab, disabled, errors, flexibleShift, onChangeTab, setFieldValue, touched, values }) => {
  return (
    <Card className="overflow-visible">
      <Col className={`${currentTab === 'tolerance' ? 'top-header-green' : 'top-header-light-gray'}`}>
        <Accordion.Toggle
          as={Col}
          eventKey="1"
          className="card-header-title-dt"
          onClick={() => onChangeTab('tolerance')}
        >
          Tolerancia
        </Accordion.Toggle>
      </Col>
      <Accordion.Collapse eventKey="1">
        <Card.Body className="div-content">
          <Row>
            <Col xs={12} md={3} className="d-flex align-items-center">
              <Field name="shift[uniqueTolerance]">
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    custom
                    checked={values.shift.uniqueTolerance}
                    field={field}
                    label="Única para todas las marcaciones"
                    tooltipSize="23"
                    disabled
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={2}>
              <Field name="shift[parsedTolerance]">
                {({ field }) => (
                  <FormikNumber
                    {...field}
                    abbr={!flexibleShift}
                    label="Tolerancia"
                    rightAddon="min"
                    placeholder="0"
                    fieldName="shift[tolerance]"
                    value={values.shift.tolerance}
                    tooltipSize="21"
                    tooltipText="Minutos permitidos para marcar ingreso y salida fuera del horario"
                    setFieldValue={setFieldValue}
                    disabled={flexibleShift || disabled}
                    errors={errors}
                    touched={touched}
                  />
                )}
              </Field>
            </Col>
          </Row>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};
export default ToleranceTab;
