import { Field, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { InputSelect } from '../Select';
import { debounceIndexSelectEmployeesRequest } from '../../../requests/employees';
import { pairValue } from './utils';

const EmployeesFilter = ({ formikValue, params = {} }) => {
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext();
  const [employees, setEmployees] = useState([]);
  const fetchEmployee = (inputValue, callback) => {
    debounceIndexSelectEmployeesRequest({
      dispatch,
      params: {
        active: true,
        filter_name: inputValue,
        sort_column: 'name',
        is_dt: false,
        display_length: 100,
        ...params
      },
      successCallback: response => {
        setEmployees([{ label: 'Todos', value: 'all' }, ...response.data.data]);
        callback([{ label: 'Todos', value: 'all' }, ...response.data.data]);
      }
    });
  };

  return (
    <Field name={formikValue}>
      {({ field }) => (
        <InputSelect
          {...field}
          label="Trabajador"
          placeholder="Seleccionar Trabajador"
          value={values[formikValue]}
          defaultValue={pairValue(employees, values[formikValue])}
          request={fetchEmployee}
          onChange={data => setFieldValue(field.name, data ? data.value : '')}
          isClearable
        />
      )}
    </Field>
  );
};

export default EmployeesFilter;
