import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Modal, Row } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakecaseKeys from 'snakecase-keys';
import { DatatableEmployeeName } from '../../../DatatableColumns';
import { camelCaseEmptyStringRecursive } from '../../../../services/utils';
import ApprovementStatusStyled from '../../../DatatableColumns/ApprovementStatusStyled';
import ApproveRejectStyledActions from '../../../DatatableActions/ApproveRejectStyledActions';
import { DefaultModal } from '../../../Utils/Modal';
import SignatureKeyForm from '../../../../screens/DocumentPreview/SignatureKeyForm';
import { signWorkflowRequestRequest } from '../../../../requests/workflowRequests';
import { sendAlert } from '../../../../actions/utils';
import { approveOvertimeRequest, rejectOvertimeRequest } from '../../../../requests/overtimes';
import OvertimeSupervisorRejectForm from './OvertimeSupervisorRejectForm';

const OvertimeInfoTable = props => {
  const dispatch = useDispatch();
  const { item, moreData, setModalShow, setMoreData } = props;
  const {
    activities,
    date,
    employee,
    hours,
    minutes,
    requestReviewerSignature,
    translatedOvertimeType,
    status
  } = camelCaseEmptyStringRecursive(item);
  const [onRequest, setOnRequest] = useState(true);
  const [isDisabled, setIsDisable] = useState(false);
  const [modalItem, setDefaultModalItem] = useState({});
  const [defaultModalTitle, setDefaultModalTitle] = useState('');
  const [defaultModalBody, setDefaultModalBody] = useState('');
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [defaultModalButtons, setDefaultModalButtons] = useState(true);
  const [defaultModalAction, setDefaultModalAction] = useState(() => null);

  const handleModalClose = () => {
    setModalShow(false);
    setDefaultModalShow(false);
    setDefaultModalTitle('');
    setDefaultModalBody('');
    setOnRequest(false);
  };

  const handleSuccessRequest = action => {
    let message = '';
    switch (action) {
      case 'approved':
        message = 'Solicitud aprobada con éxito';
        break;
      case 'rejected':
        message = 'Solicitud rechazada con éxito';
        break;
      default:
        message = 'No action';
        break;
    }

    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const signDocument = (formValues, { workflowRequestId }, setSubmitting = () => null) => {
    signWorkflowRequestRequest(workflowRequestId, {
      dispatch,
      params: snakecaseKeys(formValues),
      successCallback: () => handleSuccessRequest('approved'),
      callback: () => setSubmitting(false)
    });
  };

  const handleApproveRequest = () => {
    const overtimeId = item.id;
    const sendParams = snakecaseKeys({ overtime: item });
    setIsDisable(false);
    setOnRequest(true);

    approveOvertimeRequest(overtimeId, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: () => handleSuccessRequest('approved'),
      callback: () => setOnRequest(false)
    });
  };

  const handleApproveModal = () => {
    setIsDisable(false);
    setOnRequest(false);
    setDefaultModalTitle(`${requestReviewerSignature ? 'Firmar y' : ''} Aprobar Solicitud de Horas Extras`);
    if (item.requestReviewerSignature) {
      setDefaultModalBody(
        <>
          <SignatureKeyForm item={item} onHide={handleModalClose} formRequest={signDocument} signatureKey="" />
        </>
      );
      setDefaultModalButtons(false);
      setDefaultModalAction(() => null);
    } else {
      setDefaultModalItem(item);
      setDefaultModalBody('¿Estás seguro que deseas aprobar esta solicitud?');
      setDefaultModalAction(() => handleApproveRequest);
      setDefaultModalButtons(true);
    }
    setDefaultModalShow(true);
  };

  const handleRejectRequest = (overtime, setSubmitting) => {
    const { id: overtimeId } = overtime.workflowRequest;
    const sendParams = snakecaseKeys(overtime);
    setOnRequest(true);
    rejectOvertimeRequest(overtimeId, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: () => handleSuccessRequest('rejected'),
      callback: () => {
        setOnRequest(false);
        setSubmitting(false);
      }
    });
  };

  const handleOpenModal = (title, body) => {
    setDefaultModalTitle(title);
    setDefaultModalBody(body);
    setDefaultModalButtons(false);
    setDefaultModalAction(() => null);
    setDefaultModalShow(true);
  };

  const handleRejectModal = () => {
    const title = 'Rechazar solicitud';
    const body = (
      <OvertimeSupervisorRejectForm
        workflowRequest={item}
        formRequest={handleRejectRequest}
        handleModalClose={handleModalClose}
      />
    );
    handleOpenModal(title, body);
  };

  const handleActions = action => {
    switch (action) {
      case 'approved':
        handleApproveModal(camelCaseEmptyStringRecursive(item));
        break;
      case 'rejected':
        handleRejectModal(camelCaseRecursive(item));
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  return (
    <>
      <Modal.Body>
        <div className="info-box">
          <Row>
            <Col md={2} lg={2}>
              <ApprovementStatusStyled status={status} margin="mb-1" />
            </Col>
            <Col md={8} lg={8} className="title-pw">
              <b>Gestionar Solicitud de Horas Extras</b>
            </Col>
            <Col md={2} lg={2} className="title-pw" />
          </Row>
          <table className="simple-table">
            <tbody>
              <tr>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Trabajador</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Fecha Horas Extra</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Horas</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Minutos</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Tipo Horas Extra</th>
                <th style={{ textAlign: 'center', paddingTop: '25px' }}>Actividad</th>
              </tr>
              <tr>
                <td>
                  <div bis_skin_checked="1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div aria-hidden="true" bis_skin_checked="1" className="employee-info" />
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                      <DatatableEmployeeName item={employee} fileName="fileInfo" name="shortName" fileUrl="fileUrl" />
                    </span>
                  </div>
                </td>
                <td align="center">{date}</td>
                <td align="center">{hours}</td>
                <td align="center">{minutes}</td>
                <td align="center">{translatedOvertimeType}</td>
                <td align="center">{activities}</td>
              </tr>
            </tbody>
          </table>
          <br />

          <Row>
            <Col className="text-center">
              <ApproveRejectStyledActions
                resource="Overtime"
                item={item}
                clickHandler={handleActions}
                approveText={requestReviewerSignature ? 'Firmar / Aprobar' : 'Aprobar'}
              />
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <DefaultModal
        body={defaultModalBody}
        disabled={onRequest || isDisabled}
        handleClose={handleModalClose}
        handleConfirm={() => defaultModalAction(modalItem)}
        show={defaultModalShow}
        title={defaultModalTitle}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        withClose={defaultModalButtons}
        withConfirm={defaultModalButtons}
      />
    </>
  );
};
export default OvertimeInfoTable;
