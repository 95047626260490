import React, { useEffect, useState } from 'react';
import { withFormik, Form, Field, getIn } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  ButtonTooltip,
  EmployeeSearchModalPayroll,
  FormikRangePicker,
  FormikSelect,
  Icon,
  Loader,
  SimpleCenteredModal
} from '../../../components';
import { absenceTypes } from './FormOptions';
import { payrollEmployeesRequest } from '../../../requests/payrolls';

const ImportAttendanceMovements = props => {
  const {
    errors,
    touched,
    values,
    setFieldValue,
    onHide,
    setFieldTouched,
    payroll,
    isSubmitting,
    withAttendance
  } = props;
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState();
  const [modalShow, setModalShow] = useState(false);
  const ALL_SELECTED_LABEL = 'Todos';
  const dispatch = useDispatch();

  const handleNewMulti = (data, field, allowEmpty = false) => {
    let newData = data.map(element => element.value);
    const newArray = employees.filter(item => item.id !== 'Todos');
    const employeeIds = newArray.map(emp => emp.id);
    if (newData.length && newData.includes(ALL_SELECTED_LABEL)) {
      newData = newData.filter(item => item !== ALL_SELECTED_LABEL);
      newData.push(...employeeIds);
    }

    if (allowEmpty && !newData.length) newData.push(...employeeIds);
    setFieldValue(field, newData);
  };

  const handleSearch = selectedEmployees => {
    const { employeeSelector } = values;
    const filterByEmployee = values.filter_by_employee;
    if (employeeSelector[0]?.value === 'Todos') return;
    const employeesFilter = employees.filter(selected => selectedEmployees.includes(selected.value));
    const employeesToAdd = employeesFilter.filter(selected => !filterByEmployee.includes(selected.value));
    handleNewMulti([...employeeSelector, ...employeesToAdd] || [], 'filter_by_employee', true);
    setFieldValue('employeeSelector', [...employeeSelector, ...employeesToAdd] || []);
    setModalShow(false);
  };

  const hasAllSelection = (groupSelection = []) => {
    return groupSelection.some(option => option.label === ALL_SELECTED_LABEL);
  };

  const handleDisabledSelection = (options, selectedOptions) => {
    if (hasAllSelection(selectedOptions)) {
      return true;
    }
    if (selectedOptions.length === 0) return false;
    return options.label === ALL_SELECTED_LABEL;
  };

  const handleSelectChange = (newValue, { action }) => {
    if (action === 'clear') {
      setFieldValue('filter_by_employee', []);
      setFieldValue('employeeSelector', []);
    } else if (action === 'remove-value' && (newValue === null || newValue === undefined)) {
      setFieldValue('filter_by_employee', []);
      setFieldValue('employeeSelector', []);
    } else {
      handleNewMulti(newValue || [], 'filter_by_employee', true);
      setFieldValue('employeeSelector', newValue || []);
    }
  };

  const fetchEmployees = () => {
    if (payroll.id) {
      setLoading(true);
      payrollEmployeesRequest({
        dispatch,
        params: {
          filter_payroll_process: payroll.id
        },
        successCallback: response => {
          const uniqueEmployees = response.data.data.filter(
            (item, index, self) => index === self.findIndex(t => t.id === item.id)
          );
          const dataEmployees = [{ id: 'Todos', label: 'Todos', value: 'Todos' }, ...uniqueEmployees];
          setEmployees(uniqueEmployees.length > 0 ? dataEmployees : []);
          setFieldValue('arrEmployees', uniqueEmployees);
          setLoading(false);
        }
      });
    }
  };

  useEffect(fetchEmployees, [payroll]);

  return (
    <>
      {isSubmitting ? (
        <Col>
          <Loader message="Enviando información, por favor espere..." />
        </Col>
      ) : (
        <Form>
          <Row className="ml-1 mr-1">
            <Col md={12}>
              <Field name="date_range">
                {({ field }) => (
                  <FormikRangePicker
                    {...field}
                    abbr
                    startDateName="date_from"
                    endDateName="date_to"
                    startDate={values.date_from}
                    endDate={values.date_to}
                    showClearDates
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row className="ml-1 mr-1">
            <Col md={10}>
              <Field name="employeeSelector">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    label="Nombres y Apellidos"
                    isMulti
                    options={employees}
                    placeholder="Seleccione colaboradores"
                    defaultMultiValues={field.value}
                    onChange={handleSelectChange}
                    setFieldTouched={() => setFieldTouched(field.name)}
                    isOptionDisabled={handleDisabledSelection}
                    isClearable
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            {loading ? (
              <Col md={2} xl={2}>
                <Loader
                  containerStyle={{ height: '35px' }}
                  loaderSize={30}
                  message="Cargando colaboradores..."
                  messageStyle={{ fontSize: '14px', top: '135%' }}
                />
              </Col>
            ) : (
              <Col md={2}>
                <ButtonTooltip
                  variant="circle-primary"
                  className="advance-search"
                  text="Búsqueda Avanzada"
                  onClick={() => setModalShow(true)}
                >
                  <Icon className="w-100 h-100" icon="people-circle" />
                </ButtonTooltip>
              </Col>
            )}
          </Row>
          {withAttendance && (
            <Row className="ml-1 mr-1">
              <Col md={12}>
                <Field name="absences_type">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      label="Seleccione tipo de movimiento"
                      placeholder="Todos"
                      options={absenceTypes}
                      isMulti
                      onChange={data => {
                        handleNewMulti(data || [], 'filter_type', true);
                        setFieldValue(field.name, data || []);
                      }}
                      isOptionDisabled={handleDisabledSelection}
                      isClearable
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      margin="mb-0"
                    />
                  )}
                </Field>
              </Col>
            </Row>
          )}
          <Row className="d-flex justify-content-end mt-3 mb-2 mr-1">
            <Col xs={6} sm={4}>
              <Button block type="submit" variant="primary" disabled={isSubmitting} onClick={onHide}>
                Importar
              </Button>
            </Col>
          </Row>

          <SimpleCenteredModal
            title="Buscar Empleados"
            body={
              <EmployeeSearchModalPayroll
                customParams={{
                  filter_payroll_process: payroll.id
                }}
                handleClose={() => setModalShow(false)}
                formRequest={handleSearch}
              />
            }
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </Form>
      )}
    </>
  );
};

const validationSchema = Yup.object().shape({
  date_from: Yup.date()
    .formatdate()
    .required('Debes ingresar una fecha de inicio'),
  date_to: Yup.date()
    .formatdate()
    .required('Debes ingresar una fecha de termino'),
  employeeSelector: Yup.array().required('Este campo no puede estar vacío'),
  date_range: Yup.array().rangedate(true)
});

const setInitialValues = props => {
  const { withAttendance, payroll } = props;
  const startDate = moment(payroll?.start_date).format('DD/MM/YYYY');
  const endDate = moment(payroll?.end_date)
    .set({ hour: 23, minute: 59 })
    .format('DD/MM/YYYY');
  return {
    date_from: startDate || '',
    date_to: endDate || '',
    date_range: [startDate, endDate] || [],
    filter_type: !withAttendance ? ['without_pay'] : [],
    filter_id: '',
    filter_by_employee: [],
    employeeSelector: [],
    arrEmployees: []
  };
};

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true
})(ImportAttendanceMovements);
