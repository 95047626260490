import { Field, getIn, useFormikContext } from 'formik';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormikDatePicker, FormikInput } from '../../../components';
import { rutFormat, validRutInput } from '../../../services/utils';
import BankAccountWithHoldings from '../../../components/SharedFields/BankAccountWithHoldings';

export default function AlimonyForm({ index, errors, touched, setFieldValue }) {
  const handleOutSourceNationalIdentification = e => {
    if (validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  const { values } = useFormikContext();
  const contractRetentionValues = getIn(values, 'employee[contract][contractBalancesRetentionsAttributes]');
  const currentIndex = contractRetentionValues.findIndex((_, _index) => index === _index);
  const retentionWorking = getIn(
    values,
    `employee[contract][contractBalancesRetentionsAttributes][${currentIndex}][contractRetention]`
  );

  return (
    <React.Fragment key={`retention-form-${index.toString()}`}>
      <Row className="ml-0 mr-0 d-flex">
        <Col md={4}>
          <Field
            name={`employee[contract][contractBalancesRetentionsAttributes][${index}][contractRetention][fileNumber]`}
          >
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Numero de Expediente"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field
            name={`employee[contract][contractBalancesRetentionsAttributes][${index}][contractRetention][retentionDate]`}
          >
            {({ field }) => (
              <FormikDatePicker
                name={field.name}
                value={field.value}
                abbr
                isOutsideRange={() => false}
                label="Fecha Retencion"
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field
            name={`employee[contract][contractBalancesRetentionsAttributes][${index}][contractRetention][competentCourt]`}
          >
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Tribunal Competente"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="ml-0 mr-0 d-flex">
        <Col md={4}>
          <Field
            name={`employee[contract][contractBalancesRetentionsAttributes][${index}][contractRetention][beneficiary]`}
          >
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre de Beneficiario"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field
            name={`employee[contract][contractBalancesRetentionsAttributes][${index}][contractRetention][nationalIdentificationBeneficiary]`}
          >
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Rut"
                onChange={e => handleOutSourceNationalIdentification(e)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="ml-0 mr-0">
        <BankAccountWithHoldings
          modelKey={`employee[contract][contractBalancesRetentionsAttributes][${index}][contractRetention]`}
          currentModel={{
            paymentType: retentionWorking.paymentType,
            bankId: retentionWorking.bankId,
            bankAccount: retentionWorking.bankAccount,
            bankAccountType: retentionWorking.bankAccountType,
            bank: retentionWorking.bank
          }}
          forceSetBank
          paymentTypeAbbr
          holderData={false}
        />
      </Row>
    </React.Fragment>
  );
}
