import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import camelCaseRecursive from 'camelcase-keys-recursive';
import * as Yup from 'yup';
import { Button, Row, Col, Table } from 'react-bootstrap';
import {
  FormikInput,
  ButtonTooltip,
  DatatableEmployeeName,
  FormikDatePicker,
  FormikNumber,
  FormikSelect,
  Icon,
  SimpleCenteredModal,
  EmployeeSearchModal,
  InputSelect
} from '../../components';

import { indexSelectEmployeesRequest, indexEmployeesRequest } from '../../requests/employees';
import { selectorTypes, hourValues, extraHoursTypes } from './FormOptions';

const basicOvertime = () => ({
  date: '',
  employeeId: '',
  hourValue: 'fifty',
  hours: 0,
  minutes: 0,
  overtimeType: '',
  bossSupervisorId: '',
  status: 'pending',
  directApprove: true,
  activities: ''
});

const OvertimesForm = props => {
  const { setFieldValue, setFieldTouched, values, isSubmitting } = props;
  const [selector, setSelector] = useState(selectorTypes);
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [placeHolder, setPlaceHolder] = useState('Seleccionar Empleado');
  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    setIsLoading(false);
    setPlaceHolder('Seleccionar Empleado');
    const vEmployees = camelCaseRecursive(response.data.data);
    const vSelector = [...selectorTypes, ...vEmployees];
    setSelector(vSelector);
  };

  const fetchEmployees = () => {
    setIsLoading(true);
    setPlaceHolder('Cargando Trabajadores...');
    indexSelectEmployeesRequest({
      dispatch,
      params: { active: true, is_dt: false, paginate: false },
      successCallback: handleSuccessIndex
    });
  };

  const fetchBossSupervisors = (inputValue, callback) => {
    indexSelectEmployeesRequest({
      dispatch,
      params: {
        active: true,
        filter_name: inputValue,
        bosses_and_supervisors: true,
        paginate: false
      },
      successCallback: response => {
        callback(response.data.data);
      }
    });
  };

  const fetchFullEmployees = employeeIds => {
    setIsLoading(true);
    return new Promise((resolve, reject) => {
      indexEmployeesRequest({
        dispatch,
        params: { active: true, is_dt: false, paginate: false, filter_id: employeeIds },
        successCallback: response => {
          const data = camelCaseRecursive(response.data.data);
          setIsLoading(false);
          resolve(data);
        },
        failureCallback: error => {
          reject(error);
        }
      });
    });
  };

  useEffect(fetchEmployees, []);

  const addAllEmployees = async () => {
    const allIds = selector.filter(selected => selected.id !== undefined).map(employee => employee.value);
    const employeesData = await fetchFullEmployees(allIds);
    setIsLoading(true);
    const { overtimes } = values;
    const employeesToAdd = employeesData.map(employee => ({
      ...basicOvertime(),
      employeeId: employee.value,
      employee
    }));
    const vOvertimes = [...overtimes, ...employeesToAdd];
    setSelector([]);
    setFieldValue('overtimes', vOvertimes);
    setIsLoading(false);
  };

  const handleSelector = async () => {
    const { employeeSelector } = props.values;
    const vSelector = selector.filter(selected => selected.value !== employeeSelector);

    if (vSelector.length === selector.length) {
      return;
    }
    if (employeeSelector === 'all_employees') {
      addAllEmployees();
    } else {
      const employeesData = await fetchFullEmployees(employeeSelector);
      const employeeToAdd = employeesData.find(selected => selected.value === employeeSelector);
      const { overtimes } = values;
      const overtimesLength = overtimes.length;
      setFieldValue(`overtimes[${overtimesLength}]`, {
        ...basicOvertime(),
        employeeId: employeeToAdd.value,
        employee: employeeToAdd
      });
      setSelector(vSelector);
      setFieldValue('employeeSelector', '');
    }
  };

  const removeFromTable = (employee, action) => {
    if (action === 'destroy') {
      const { overtimes } = values;
      const notRemovedEmployees = overtimes.filter(selected => selected.employee.id !== employee.id);
      const addToSelector = [...selector, employee];
      setSelector(addToSelector);
      setFieldValue('overtimes', notRemovedEmployees);
    }
  };

  const handleSearch = async selectedEmployees => {
    const vSelector = selector.filter(selected => !selectedEmployees.includes(selected.value));

    const employeesData = await fetchFullEmployees(selectedEmployees);

    setModalShow(false);
    if (vSelector.length === selector.length) return;
    const employeesToAdd = employeesData.map(employee => ({
      ...basicOvertime(),
      employeeId: employee.value,
      employee
    }));
    setFieldValue('overtimes', [...values.overtimes, ...employeesToAdd]);
    setSelector(vSelector);
  };

  const { onHide, submitVariant, errors, touched } = props;
  const { overtimes, employeeSelector } = values;
  return (
    <Form>
      <div className="info-box ml-0">
        <h4 className="text-uppercase">Selecciona Trabajadores</h4>
        <p className="info">
          <span className="full-width">Seleccione trabajadores para incluir en el proceso</span>
        </p>
      </div>
      <Row className="align-items-center mb-3 mb-md-0">
        <Col md={5}>
          <Field name="employeeSelector">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Generar Para"
                isDisabled={isLoading}
                isLoading={isLoading}
                placeholder={placeHolder}
                options={selector}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col xs={6} md={2} xl={2}>
          <Button
            disabled={isLoading || employeeSelector.length === 0}
            variant="primary"
            onClick={handleSelector}
            style={{ marginTop: '5px' }}
          >
            Agregar
          </Button>
        </Col>
        <Col>
          <ButtonTooltip
            variant="circle-primary"
            className="advance-search"
            text="Búsqueda Avanzada"
            onClick={() => setModalShow(true)}
          >
            <Icon className="w-100 h-100" icon="people-circle" />
          </ButtonTooltip>
        </Col>
      </Row>
      <Row>{isLoading && <div className="loader">Procesando...</div>}</Row>
      <Row className="mt-2">
        <Col md={5}>
          <Field name="activities">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Actividad"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Table responsive>
          <thead>
            <tr>
              <th style={{ width: '250px' }}>NOMBRE DEL TRABAJADOR</th>
              <th className="text-center" style={{ width: '200px' }}>
                FECHA
              </th>
              <th className="text-center" style={{ width: '120px' }}>
                HORAS
              </th>
              <th className="text-center" style={{ width: '120px' }}>
                MINUTOS
              </th>
              <th className="text-center" style={{ width: '150px' }}>
                TIPO DE HORA
              </th>
              <th className="text-center" style={{ width: '200px' }}>
                JEFE/SUPERVISOR
              </th>
              <th className="text-center" style={{ width: '120px' }}>
                VALOR
              </th>
              <th className="text-center" style={{ width: '120px' }}>
                ACCIONES
              </th>
            </tr>
          </thead>
          <tbody>
            {overtimes.map((overtime, index) => (
              <tr key={`overtime-${index.toString()}`}>
                <td className="employee-name">
                  <DatatableEmployeeName
                    item={overtime.employee}
                    fileName="fileInfo"
                    name="fullName"
                    fileUrl="fileUrl"
                  />
                </td>
                <td>
                  <Field name={`overtimes[${index}][date]`}>
                    {({ field }) => (
                      <FormikDatePicker
                        {...field}
                        isOutsideRange={() => false}
                        placeholder="dd/mm/aaaa"
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        margin="mb-0"
                      />
                    )}
                  </Field>
                </td>
                <td>
                  <Field name={`overtimes[${index}][parsedHours]`}>
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        rightAddon="hrs"
                        placeholder="1"
                        fieldName={`overtimes[${index}][hours]`}
                        value={overtime.hours}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        margin="mb-0"
                      />
                    )}
                  </Field>
                </td>
                <td>
                  <Field name={`overtimes[${index}][parsedMinutes]`}>
                    {({ field }) => (
                      <FormikNumber
                        {...field}
                        rightAddon="min"
                        placeholder="0"
                        fieldName={`overtimes[${index}][minutes]`}
                        value={overtime.minutes}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        margin="mb-0"
                      />
                    )}
                  </Field>
                </td>
                <td>
                  <Field name={`overtimes[${index}][overtimeType]`}>
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr
                        placeholder="Seleccionar"
                        options={extraHoursTypes}
                        defaultValue={overtime.overtimeType}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </td>
                <td>
                  <Field name={`overtimes[${index}][bossSupervisorId]`}>
                    {({ field }) => (
                      <InputSelect
                        {...field}
                        abbr
                        isClearable
                        placeholder="Seleccione"
                        value={overtime.bossSupervisorId}
                        model={[overtime, 'bossSupervisor']}
                        request={fetchBossSupervisors}
                        onChange={data => setFieldValue(field.name, data ? data.id : '')}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </td>
                <td>
                  <Field name={`overtimes[${index}][hourValue]`}>
                    {({ field }) => (
                      <FormikSelect
                        {...field}
                        abbr
                        placeholder="50%"
                        options={hourValues}
                        onChange={data => setFieldValue(field.name, data ? data.value : '')}
                        setFieldTouched={() => setFieldTouched(field.name)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </td>
                <td>
                  <ButtonTooltip
                    onClick={() => removeFromTable(overtime.employee, 'destroy')}
                    variant="circle-danger"
                    className="btn-circle"
                    size="sm"
                    text="Eliminar"
                  >
                    <Icon icon="trash" />
                  </ButtonTooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
      <Row className="d-flex justify-content-end m-t-10 m-b-30">
        <Col md={2}>
          <Button type="submit" disabled={isSubmitting} variant={submitVariant} block onClick={onHide}>
            Finalizar
          </Button>
        </Col>
      </Row>
      <SimpleCenteredModal
        title="Buscar Empleados"
        body={
          <EmployeeSearchModal
            customParams={{ active: true }}
            handleClose={() => setModalShow(false)}
            formRequest={handleSearch}
          />
        }
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Form>
  );
};

const setInitialValues = () => {
  return {
    overtimes: [],
    employeeSelector: '',
    activities: ''
  };
};

const validationSchema = Yup.object().shape({
  overtimes: Yup.array().of(
    Yup.object().shape(
      {
        date: Yup.string().required('Debes seleccionar una fecha'),
        hours: Yup.number().when('minutes', {
          is: val => val === 0,
          then: Yup.number()
            .required('Debes ingresar la cantidad de horas')
            .integer('Debe ser un número entero')
            .min(1, 'Debe ser mayor o igual a 1')
            .max(12, 'Debe ser menor o igual a 12 horas'),
          otherwise: Yup.number().nullable()
        }),
        minutes: Yup.number().when('hours', {
          is: val => val === 0,
          then: Yup.number()
            .required('Debes ingresar la cantidad de minutos')
            .integer('Debe ser un número entero')
            .min(1, 'Debe ser mayor o igual a 1')
            .max(59, 'Debe ser menor o igual a 60 minutos'),
          otherwise: Yup.number().nullable()
        }),
        overtimeType: Yup.string().required('Debes seleccionar un tipo de hora extra'),
        bossSupervisorId: Yup.number().required('Debes seleccionar un Supervisor o Jefe')
      },
      [['hours', 'minutes']]
    )
  ),
  activities: Yup.string()
    .required('Debes ingresar una actividad')
    .min(5, 'Debe ser mayor a 5 caracteres')
    .max(50, 'Debe ser menor a 50 caracteres')
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  const { overtimes, activities } = values;

  const cleanOvertimesData = {
    overtimes: overtimes.map(({ employee, parsedHours, parsedMinutes, ...rest }) => ({
      ...rest,
      activities
    }))
  };

  formRequest(cleanOvertimesData, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(OvertimesForm);
