import React from 'react';
import { Col } from 'react-bootstrap';
import { Field, getIn } from 'formik';
import { InputSelect, FormikNumber, FormikRangePicker, FormikSelect } from '../../../components';

const BalanceInput = ({
  addon,
  defaultOptions,
  defaultValue,
  disabledAmount,
  errors,
  fetch,
  index,
  setFieldValue,
  title,
  touched,
  startDate,
  endDate,
  unitOfAccounts,
  unitOfAccountValue,
  contractValuePath
}) => {
  return (
    <>
      <Col md={3}>
        <Field name={`employee[contract][${contractValuePath}][${index}][balanceId]`}>
          {({ field }) => (
            <InputSelect
              {...field}
              abbr
              label={title}
              placeholder={`Seleccionar ${title}`}
              value={defaultValue}
              defaultOptions={defaultOptions}
              request={fetch}
              onChange={data => {
                const name = `employee[contract][${contractValuePath}][${index}]`;
                setFieldValue(field.name, data ? data.value : '');
                setFieldValue(`${name}[code]`, data ? data.code : '');
                if (data.code?.toLowerCase() === 'pw051') {
                  setFieldValue(`${name}[amount]`, '3');
                  setFieldValue(`${name}[parsedAmount]`, '3');
                  setFieldValue(`${name}[unitOfAccount]`, 'percentage');
                  return;
                }
                setFieldValue(`${name}[amount]`, '');
                setFieldValue(`${name}[parsedAmount]`, '');
                setFieldValue(`${name}[unitOfAccount]`, 'pesos');
                setFieldValue(`${name}[contractRetention][balanceId]`, data ? data.value : '');
              }}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={2}>
        <Field name={`employee[contract][${contractValuePath}][${index}][unitOfAccount]`}>
          {({ field }) => (
            <FormikSelect
              {...field}
              abbr
              label="Tipo Moneda"
              placeholder="Seleccionar Moneda"
              options={unitOfAccounts}
              defaultValue={unitOfAccountValue}
              onChange={data => {
                const name = `employee[contract][${contractValuePath}][${index}]`;
                setFieldValue(field.name, data ? data.value : '');
                setFieldValue(`${name}[amount]`, '');
                setFieldValue(`${name}[parsedAmount]`, '');
              }}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={2}>
        <Field name={`employee[contract][${contractValuePath}][${index}][parsedAmount]`}>
          {({ field }) => (
            <FormikNumber
              {...field}
              abbr
              decimalScale={addon === '$' ? 0 : 2}
              fieldName={`employee[contract][${contractValuePath}][${index}][amount]`}
              disabled={disabledAmount}
              setFieldValue={setFieldValue}
              leftAddon={addon}
              label="Monto"
              errors={errors}
              touched={touched}
            />
          )}
        </Field>
      </Col>
      <Col md={4}>
        <Field name={`employee[contract][${contractValuePath}][${index}][rangeDate]`}>
          {({ field }) => (
            <FormikRangePicker
              {...field}
              startDateName={`employee[contract][${contractValuePath}][${index}][startDate]`}
              endDateName={`employee[contract][${contractValuePath}][${index}][endDate]`}
              startDate={startDate}
              endDate={endDate}
              labelLeft="Desde"
              labelRight="Hasta"
              showClearDates
              minimumNights={1}
              error={getIn(errors, `employee[contract][${contractValuePath}][${index}][startDate]`)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
    </>
  );
};

export default BalanceInput;
