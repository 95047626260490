import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';
import Filters from './Filters';
import CerDjDatatable from './CerDjDatatable';
import { DefaultModal, SimpleCenteredModal } from '../../../components';
import {
  debounceIndexEmployeeDeclarationRequest,
  downloadCertificateRequest,
  graphic,
  sendCertificate,
  sendWorkflowRequest
} from '../../../requests/employeeDeclaration';
import { camelCaseEmptyStringRecursive, downloadFile } from '../../../services/utils';
import { sendAlert } from '../../../actions/utils';
import Graphic from './Graphic';

function CertDj({ onRequest, setOnRequest, setMoreData, moreData }) {
  const { currentCompany } = useSelector(state => state.auth);
  const [modalTitleBtn, setModalTitleBtn] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [simpleModalTitle, setSimpleModalTitle] = useState('');
  const [simpleModalBody, setSimpleModalBody] = useState('');
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [simpleModalAction, setSimpleModalAction] = useState(() => null);
  const [simpleModalItem, setSimpleModalItem] = useState({});
  const [customParams, setCustomParams] = useState({});
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchFirstStart, setSearchFirstStart] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [amount, setAmount] = useState(0);
  const [selectedCount, setSelectedCount] = useState(0);
  const [dataGraphic, setDataGraphic] = useState([]);
  const [dataUpGraphic, setDataUpGraphic] = useState([]);
  const [totalGrapOne, setTotalGrapOne] = useState(0);
  const [totalGrapTwo, setTotalGrapTwo] = useState(0);
  const [isOpen, setIsOpen] = useState(true); // Estado inicial: colapsado (false)

  const dispatch = useDispatch();

  const handleGraphic = async params => {
    graphic({
      dispatch,
      params: {
        filter_by_company: currentCompany.id,
        ...params
      },
      successCallback: response => {
        const { data } = response;
        setTotalGrapOne(data?.total || 0);
        setTotalGrapTwo(data?.update_total || 0);
        const certificate = [
          {
            title: 'Renta Imponible Afecta al Impto.Único de 2° Cat.',
            value: data?.sum_taxable_income_second_category,
            color: '#FB7474',
            percentage: data?.total > 0 ? Math.ceil((data?.sum_taxable_income_second_category / data?.total) * 100) : 0
          },
          {
            title: 'Impuesto Único Retenido',
            value: data?.sum_withheld_single_tax,
            color: '#674ea7',
            percentage: data?.total > 0 ? Math.ceil((data?.sum_withheld_single_tax / data?.total) * 100) : 0
          },
          {
            title: 'Mayor Retención de Impto. Solicitada Art.88 LIR',
            value: data.sum_requested_extra_withholding,
            color: '#17A2B8',
            percentage: data?.total > 0 ? Math.ceil((data?.sum_requested_extra_withholding / data?.total) * 100) : 0
          },
          {
            title: 'Renta Total Exenta',
            value: data?.sum_total_exempt_income,
            color: '#29B48F',
            percentage: data?.total > 0 ? Math.ceil((data?.sum_total_exempt_income / data?.total) * 100) : 0
          },
          {
            title: 'Renta Total no Gravada',
            value: data?.sum_total_non_taxable_income,
            color: '#FFC107',
            percentage: data?.total > 0 ? Math.ceil((data?.sum_total_non_taxable_income / data?.total) * 100) : 0
          },
          {
            title: 'Rebaja por Zonas Extremas (Franquicia D.L. 889)',
            value: data?.sum_extreme_zone_discount,
            color: '#FFC107',
            percentage: data?.total > 0 ? Math.ceil((data?.sum_extreme_zone_discount / data?.total) * 100) : 0
          }
        ];
        const certificateUp = [
          {
            title: 'Renta Imponible Afecta al Impto.Único de 2° Cat.',
            value: data?.sum_up_taxable_income_second_category,
            color: '#FB7474',
            percentage:
              data?.update_total > 0
                ? Math.ceil((data?.sum_up_taxable_income_second_category / data?.update_total) * 100)
                : 0
          },
          {
            title: 'Impuesto Único Retenido',
            value: data?.sum_up_withheld_single_tax,
            color: '#674ea7',
            percentage:
              data?.update_total > 0 ? Math.ceil((data?.sum_up_withheld_single_tax / data?.update_total) * 100) : 0
          },
          {
            title: 'Mayor Retención de Impto. Solicitada Art.88 LIR',
            value: data.sum_up_requested_extra_withholding,
            color: '#17A2B8',
            percentage:
              data?.update_total > 0
                ? Math.ceil((data?.sum_up_requested_extra_withholding / data?.update_total) * 100)
                : 0
          },
          {
            title: 'Renta Total Exenta',
            value: data?.sum_up_total_exempt_income,
            color: '#29B48F',
            percentage:
              data?.update_total > 0 ? Math.ceil((data?.sum_up_total_exempt_income / data?.update_total) * 100) : 0
          },
          {
            title: 'Renta Total no Gravada',
            value: data?.sum_up_total_non_taxable_income,
            color: '#FFC107',
            percentage:
              data?.update_total > 0 ? Math.ceil((data?.sum_up_total_non_taxable_income / data?.update_total) * 100) : 0
          },
          {
            title: 'Rebaja por Zonas Extremas (Franquicia D.L. 889)',
            value: data?.sum_up_extreme_zone_discount,
            color: '#FFC107',
            percentage:
              data?.update_total > 0 ? Math.ceil((data?.sum_up_extreme_zone_discount / data?.update_total) * 100) : 0
          }
        ];

        setDataGraphic(certificate);
        setDataUpGraphic(certificateUp);
        setIsOpen(false);
      }
    });
  };

  const handleFilters = async params => {
    setOnRequest(true);
    setClearSelectedRows(!clearSelectedRows);
    setSearchFirstStart(true);
    setCustomParams(params);
    setMoreData(!moreData);
    handleGraphic(params);
  };

  const handleRequest = params => {
    const myParams = snakecaseKeys({ ...params, ...customParams });
    setOnRequest(true);
    debounceIndexEmployeeDeclarationRequest({
      dispatch,
      params: {
        filter_by_company: currentCompany.id,
        ...myParams
      },
      successCallback: response => {
        const { data, metadata } = response.data;
        setEmployee(camelCaseEmptyStringRecursive(data));
        setAmount(metadata.amount);
        setOnRequest(false);
      },
      callback: () => {
        setOnRequest(false);
      }
    });
  };

  const handleSuccessSendEmail = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
  };

  const handleSendCertificate = ids => {
    setOnRequest(true);
    sendCertificate({
      dispatch,
      params: { ids },
      successCallback: () => handleSuccessSendEmail('Email enviado correctamente'),
      callback: () => {
        setOnRequest(false);
        setSimpleModalShow(false);
      }
    });
  };

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleSuccessRequest = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
  };

  const handleMassiveCreateWorkflow = ids => {
    setOnRequest(true);
    sendWorkflowRequest({
      dispatch,
      params: { ids },
      successCallback: () => handleSuccessRequest('Flujos creado correactemente'),
      failureCallback: error => handleFailureRequest(error),
      callback: () => {
        setOnRequest(false);
        setSimpleModalShow(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const handleButtonClick = (item, action) => {
    const file = item.fileInfo;
    const downloadBtn = (
      <Button variant="primary" href={file?.fileUrl} target="_blank" rel="noopener noreferrer" className="ml-4">
        Descargar PDF
      </Button>
    );
    const body = (
      <iframe src={file?.fileUrl} title={`${file?.filename}`} style={{ height: '500px' }} className="w-100">
        <p>Este navegador no soporta PDFs. Por favor descarga el PDF para verlo: {downloadBtn}</p>
      </iframe>
    );
    switch (action) {
      case 'show':
        setModalTitleBtn(downloadBtn);
        setModalBody(body);
        setModalShow(true);
        break;
      case 'email':
        setSimpleModalItem([item.id]);
        setSimpleModalShow(true);
        setSimpleModalBody(
          <>
            <p>
              ¿Está seguro de que desea enviar al flujo el Certificados de Sueldo y Sobre Sueldos, del trabajador
              seleccionado?
            </p>
            <br />
            <p>Esta acción no podrá ser revertida.</p>
          </>
        );
        setSimpleModalTitle('Enviar Certificados de Sueldo y Sobre Sueldos');
        setSimpleModalAction(() => handleSendCertificate);
        break;
      case 'request-sign':
        setSimpleModalItem([item.id]);
        setSimpleModalShow(true);
        setSimpleModalBody(
          `¿Está seguro de que desea enviar al flujo el Certificado de Sueldo y Sobre Sueldos, del trabajador seleccionado?`
        );
        setSimpleModalTitle('Enviar a flujo Certificado de Sueldo y Sobre Sueldos');
        setSimpleModalAction(() => handleMassiveCreateWorkflow);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Acción no encontrada');
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'full_name':
        return { sort_name: name };
      case 'cost_center':
        return { sort_cost_center: name };
      case 'job_management':
        return { sort_job_management: name };
      case 'year':
        return { sort_year: name };
      default:
        return { sort_column: name };
    }
  };

  const handleDownload = () => {
    setOnRequest(true);
    const ids = selectedRows.map(item => item.id);
    downloadCertificateRequest({
      dispatch,
      params: { ids },
      format: '.zip',
      responseType: 'blob',
      successCallback: downloadFile,
      callback: () => {
        setOnRequest(false);
        setClearSelectedRows(!clearSelectedRows);
      }
    });
  };

  const handleMassAction = action => {
    const payrollIds = selectedRows.map(item => item.id);

    switch (action) {
      case 'download_zip':
        handleDownload();
        break;
      case 'email':
        setSimpleModalItem(payrollIds);
        setSimpleModalShow(true);
        setSimpleModalBody(
          <>
            <p>
              ¿Está seguro de que desea enviar el Certificado de Sueldo y Sobre Sueldos a los trabajadores seleccionados
              por correo?
            </p>
            <br />
            <p>Esta acción no podrá ser revertida.</p>
          </>
        );
        setSimpleModalTitle('Enviar Certificados de Sueldo y Sobre Sueldos');
        setSimpleModalAction(() => handleSendCertificate);
        break;
      case 'request-sign':
        setSimpleModalItem(payrollIds);
        setSimpleModalShow(true);
        setSimpleModalBody(
          `¿Está seguro que desea enviar a flujo el Certificado de Sueldo y Sobre Sueldos de los trabajadores seleccionado?`
        );
        setSimpleModalTitle('Enviar a flujo los Certificados de Sueldo y Sobre Sueldos');
        setSimpleModalAction(() => handleMassiveCreateWorkflow);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };
  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  return (
    <>
      <Filters
        formRequest={handleFilters}
        onRequest={onRequest}
        currentCompany={currentCompany}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      {(totalGrapOne > 0 || totalGrapTwo > 0) && (
        <Row>
          <Col md={6}>
            <Graphic data={dataGraphic} title="Montos Sin Actualizar" />
          </Col>
          <Col md={6}>
            <Graphic data={dataUpGraphic} title="Montos Actualizados" />
          </Col>
        </Row>
      )}
      <div className="position-relative">
        <CerDjDatatable
          onRequest={onRequest}
          handleRequest={handleRequest}
          searchFirstStart={searchFirstStart}
          amount={amount}
          employee={employee}
          handleButtonClick={handleButtonClick}
          sortColumnCase={sortColumnCase}
          moreData={moreData}
          selectedCount={selectedCount}
          handleSelectedRows={handleSelectedRows}
          handleMassAction={handleMassAction}
        />
        <SimpleCenteredModal
          title="Detalles del Certificado de Sueldo y Sobre Sueldos"
          titleBtn={modalTitleBtn}
          body={modalBody}
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="xl"
          bodyClass="mx-3 mb-3"
        />
        <DefaultModal
          title={simpleModalTitle}
          body={simpleModalBody}
          show={simpleModalShow}
          disabled={onRequest}
          handleClose={() => setSimpleModalShow(false)}
          handleConfirm={() => simpleModalAction(simpleModalItem)}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
        />
      </div>
    </>
  );
}

export default CertDj;
