import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import moment from 'moment';
import { FormikRangePicker, FormikTimePicker } from '../../components';

const PayrollProcessForm = ({ onHide, submitVariant, errors, touched, setFieldValue, isSubmitting, values }) => {
  const { startDate, endDate } = values.payrollProcess;
  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <Field name="payrollProcess[selectedDate]">
              {({ field }) => (
                <FormikTimePicker
                  abbr
                  label="Mes y Año de proceso"
                  placeholder="Seleccionar mes y año de proceso"
                  dateFormat="MMMM - yyyy"
                  showMonthYearPicker
                  setLocale
                  onChange={date => {
                    setFieldValue(field.name, date);
                    if (!date) return;
                    moment.locale('en');
                    const month = moment(date)
                      .format('MMMM')
                      .toLowerCase();
                    const _year = moment(date).format('YYYY');
                    setFieldValue('payrollProcess[month]', month);
                    setFieldValue('payrollProcess[year]', _year);
                    const firstOfMonth = moment(date).startOf('month');
                    const lastOfMonth = moment(date).endOf('month');
                    setFieldValue('payrollProcess[startDate]', firstOfMonth.format('DD/MM/YYYY'));
                    setFieldValue('payrollProcess[endDate]', lastOfMonth.format('DD/MM/YYYY'));
                    setFieldValue('rangeDate', [firstOfMonth.format('DD/MM/YYYY'), lastOfMonth.format('DD/MM/YYYY')]);
                  }}
                  selected={field.value}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="rangeDate">
              {({ field }) => (
                <FormikRangePicker
                  {...field}
                  name={field.name}
                  abbr
                  startDateName="payrollProcess[startDate]"
                  endDateName="payrollProcess[endDate]"
                  startDate={startDate}
                  endDate={endDate}
                  isOutsideRange={day => {
                    if (startDate) {
                      const payrollStartDate = moment(startDate, 'DD/MM/YYYY');
                      const payrollEndDate = moment(startDate, 'DD/MM/YYYY').add(30, 'days');
                      return day.isAfter(payrollEndDate) || day.isBefore(payrollStartDate);
                    }
                    return null;
                  }}
                  showClearDates
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" className="btn" variant={submitVariant} onClick={onHide} disabled={isSubmitting}>
          Guardar
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const setInitialValues = props => {
  const { endDate, startDate } = props.payrollProcess;

  return {
    payrollProcess: {
      selectedDate: '',
      year: '',
      month: '',
      startDate,
      endDate
    },
    rangeDate: [startDate, endDate]
  };
};

const validationSchema = Yup.object().shape({
  payrollProcess: Yup.object().shape({
    year: Yup.number().required('Debes ingresar un año'),
    month: Yup.string().required('Debes ingresar un mes'),
    startDate: Yup.date()
      .formatdate()
      .required('Debes ingresar una fecha de inicio'),
    endDate: Yup.date()
      .formatdate()
      .required('Debes ingresar una fecha de termino'),
    selectedDate: Yup.string().required('Debes ingresar un mes y año de proceso')
  }),
  rangeDate: Yup.array().rangedate(true)
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(PayrollProcessForm);
