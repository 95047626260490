import React from 'react';

const DataProcessAbsences = ({ info }) => {
  return (
    <div className="info-box">
      <table className="table ">
        <tbody>
          {info.map(item => (
            <tr key={item.name}>
              <td>
                <span>{item.name}</span>
              </td>
              <td>{item.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataProcessAbsences;
