import React, { useEffect } from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { BasicTextArea, FormikDatePicker, FormikSelect, InputSelect, FormikNumber } from '../../../components';
import '../../../services/yupCustomMethods';
import { indexSelectEmployeesRequest } from '../../../requests/employees';

const converHourValue = {
  0: 'fifty',
  1: 'hundred',
  2: 'hundred_and_fifty'
};

const OvertimeNew = props => {
  const dispatch = useDispatch();
  const { errors, isSubmitting, setFieldTouched, setFieldValue, touched, values, employeeId } = props;

  const { overtime } = values;

  const { advanceSettings } = useSelector(state => state.utils);

  const hourValue = advanceSettings?.filter(asItem => asItem.code === 'hour_value_overtime_requests')[0]?.integerValue;

  useEffect(() => {
    if (hourValue !== undefined) {
      setFieldValue('overtime[hour_value]', converHourValue[hourValue]);
    }
  }, [hourValue, setFieldValue]);

  const extraHoursTypes = [
    { value: 'begin_journey', label: 'Inicio de Jornada' },
    { value: 'end_journey', label: 'Fin de Jornada' }
  ];

  const fetchBossSupervisors = (inputValue, callback) => {
    indexSelectEmployeesRequest({
      dispatch,
      params: {
        active: true,
        filter_name: inputValue,
        bosses_and_supervisors: true,
        paginate: false
      },
      successCallback: response => {
        callback(response.data.data);
      }
    });
  };

  const fetchEmployees = (inputValue, callback) => {
    indexSelectEmployeesRequest({
      dispatch,
      params: { active: true, filter_name: inputValue, paginate: false },
      successCallback: response => callback(response.data.data)
    });
  };

  return (
    <Form>
      {isSubmitting && <Spinner animation="border" variant="primary" className="spinner-modal" />}
      <Row>
        {!employeeId && (
          <Col md={12}>
            <Field name="overtime[employeeId]">
              {({ field }) => (
                <InputSelect
                  {...field}
                  abbr
                  label="Trabajador"
                  placeholder="Seleccionar Trabajador"
                  values={values}
                  model={[overtime, 'employee']}
                  request={fetchEmployees}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={12}>
          <Field name="overtime[date]">
            {({ field }) => (
              <FormikDatePicker
                {...field}
                abbr
                isOutsideRange={() => false}
                label="Fecha Hora Extra"
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                margin="mb-0"
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={6}>
          <Field name="overtime[hours]">
            {({ field }) => (
              <FormikNumber
                {...field}
                abbr
                rightAddon="hrs"
                placeholder="05"
                label="Hora"
                fieldName="overtime[hours]"
                value={overtime.hours}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                maxLength={2}
                margin="mb-0"
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="overtime[minutes]">
            {({ field }) => (
              <FormikNumber
                {...field}
                abbr
                rightAddon="min"
                placeholder="35"
                label="Minutos"
                fieldName="overtime[minutes]"
                value={overtime.minutes}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                maxLength={2}
                margin="mb-0"
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={12}>
          <Field name="overtime[overtimeType]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Tipo de Hora"
                placeholder="Seleccionar"
                options={extraHoursTypes}
                defaultValue={overtime.overtimeType}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={12}>
          <Field name="overtime[bossSupervisorId]">
            {({ field }) => (
              <InputSelect
                {...field}
                abbr
                isClearable
                label="Jefe/Supervisor Hora Extra"
                placeholder="Seleccione"
                value={overtime.bossSupervisorId}
                model={[overtime, 'bossSupervisor']}
                request={fetchBossSupervisors}
                onChange={data => setFieldValue(field.name, data ? data.id : '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col md={12}>
          <Field name="overtime[activities]">
            {({ field }) => (
              <BasicTextArea
                {...field}
                label="Actividad"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>

      <Row className="d-flex justify-content-end mt-3 mb-1">
        <Col md={4}>
          <Button block type="submit" variant="primary" disabled={isSubmitting}>
            Guardar Solicitud
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { employeeId, rrhh } = props;
  return {
    overtime: {
      employeeId,
      date: '',
      hours: 0,
      minutes: 0,
      overtimeType: '',
      bossSupervisorId: '',
      activities: '',
      hour_value: '',
      status: 'pending',
      ...(rrhh === true && { directApprove: true })
    }
  };
};

const validationSchema = Yup.object().shape({
  overtime: Yup.object().shape(
    {
      date: Yup.string().required('Debes seleccionar una fecha'),
      employeeId: Yup.string().required('Debes seleccionar un trabajador'),
      hours: Yup.number().when('minutes', {
        is: val => val === 0,
        then: Yup.number()
          .required('Debes ingresar la cantidad de horas')
          .integer('Debe ser un número entero')
          .min(1, 'Debe ser mayor o igual a 1')
          .max(12, 'Debe ser menor o igual a 12 horas'),
        otherwise: Yup.number().nullable()
      }),

      minutes: Yup.number().when('hours', {
        is: val => val === 0,
        then: Yup.number()
          .required('Debes ingresar la cantidad de minutos')
          .integer('Debe ser un número entero')
          .min(1, 'Debe ser mayor o igual a 1')
          .max(59, 'Debe ser menor o igual a 60 minutos'),
        otherwise: Yup.number().nullable()
      }),
      overtimeType: Yup.string().required('Debes seleccionar un tipo de hora extra'),
      bossSupervisorId: Yup.number().required('Debes seleccionar un Supervisor o Jefe'),
      activities: Yup.string()
        .required('Debes ingresar una actividad')
        .min(5, 'Debe ser mayor a 5 caracteres')
        .max(50, 'Debe ser menor a 50 caracteres')
    },
    [['hours', 'minutes']]
  )
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  setSubmitting(true);
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(OvertimeNew);
