import React from 'react';
import memoize from 'memoize-one';
import { ButtonTooltip, ActiveDot, Icon } from '../../components';

const columns = memoize((clickHandler, permissions) => [
  {
    name: 'CÓDIGO',
    selector: 'code',
    sortable: true,
    center: true,
    grow: '1'
  },
  {
    name: 'DESCRIPCIÓN',
    selector: 'name',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TIPO DE CONCEPTO',
    selector: 'translated_balance_type',
    sortable: false,
    center: true,
    grow: '2'
  },
  {
    name: 'CTAS. CONTABLES',
    selector: item => (item.bookkeepers.length ? item.bookkeepers.map(b => b.label).join(', ') : ''),
    sortable: false,
    grow: '2'
  },
  {
    name: 'CONCEPTO LRE',
    selector: item => (item.concept_lre ? item.concept_lre.label : ''),
    sortable: false,
    grow: '2'
  },

  {
    name: 'ACTIVO',
    selector: 'active',
    cell: item => <ActiveDot item={item} />,
    sortable: true,
    grow: '1',
    center: true
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        {permissions.read && (
          <ButtonTooltip
            onClick={() => clickHandler(item, 'show')}
            variant="circle-info"
            className="btn-circle"
            size="sm"
            text="Ver"
          >
            <Icon icon="eye" />
          </ButtonTooltip>
        )}
        {permissions.update && (
          <ButtonTooltip
            onClick={() => clickHandler(item, 'edit')}
            variant="circle-warning"
            className="btn-circle"
            size="sm"
            text="Editar"
          >
            <Icon icon="pencil" />
          </ButtonTooltip>
        )}
        {permissions.destroy && (
          <ButtonTooltip
            onClick={() => clickHandler(item, 'destroy')}
            variant="circle-danger"
            className="btn-circle"
            size="sm"
            text="Eliminar"
          >
            <Icon icon="trash" />
          </ButtonTooltip>
        )}
      </>
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '123px'
  }
]);

export default columns;
