import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { Spinner } from 'react-bootstrap';
import Filters from './Filters';
import { generateReport } from '../../../requests/jd1887Report';
import { downloadFile } from '../../../services/utils';

function Dj1887() {
  const [onRequest, setOnRequest] = useState(false);
  const { currentCompany } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const handleRequest = async params => {
    const myparams = snakeCaseKeys(params);
    const request = async () =>
      generateReport({
        params: myparams,
        format: '.zip',
        responseType: 'blob',
        dispatch,
        successCallback: response => {
          downloadFile(response);
          setOnRequest(false);
        },
        callback: () => setOnRequest(false)
      });
    request();
  };

  const handleFilters = async params => {
    setOnRequest(true);
    handleRequest(params);
  };

  return (
    <>
      <Filters formRequest={handleFilters} onRequest={onRequest} currentCompany={currentCompany} />
      <div className="position-relative">
        {onRequest && (
          <div className="containerSpinnerLoad position-absolute h-100 w-100">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </div>
    </>
  );
}

export default Dj1887;
