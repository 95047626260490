import React, { useState } from 'react';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useAbility } from '@casl/react';
import { DatatableEmployeeName, DefaultModal, Reminder } from '../../../components';
import ApproveRejectStyledActions from '../../../components/DatatableActions/ApproveRejectStyledActions';
import { AbilityContext } from '../../../config/abilityContext';
import columns from './Columns';
import SignatureKeyForm from '../../DocumentPreview/SignatureKeyForm';
import WorkflowRequestReject from '../../WorkflowRequest/WorkflowRequestReject';

import { rejectOvertimeRequest } from '../../../requests/overtimes';

import { sendAlert } from '../../../actions/utils';
import ApprovementStatusStyled from '../../../components/DatatableColumns/ApprovementStatusStyled';
import useCreateOvertimeHook from './useCreateOvertimeHook';

const OvertimeInfoTable = props => {
  const { item, moreData, setModalShow, setMoreData } = props;

  const { status } = camelCaseRecursive(item);
  const dispatch = useDispatch();
  const ability = useAbility(AbilityContext);
  const [defaultModalBody, setDefaultModalBody] = useState('');
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [defaultModalButtons, setDefaultModalButtons] = useState(true);
  const [defaultModalTitle, setDefaultModalTitle] = useState('');
  const [defaultModalAction, setDefaultModalAction] = useState(() => null);
  const [modalItem, setDefaultModalItem] = useState({});
  const [onRequest, setOnRequest] = useState(true);
  const [isDisabled, setIsDisable] = useState(false);
  const [reminderShow, setReminderShow] = useState(false);
  const [reminderItem, setReminderItem] = useState({});

  const handleModalClose = () => {
    setModalShow(false);
    setDefaultModalShow(false);
    setDefaultModalTitle('');
    setDefaultModalBody('');
    setOnRequest(false);
  };

  const handleSuccessRequest = message => {
    setMoreData(!moreData);
    dispatch(sendAlert({ kind: 'success', message }));
    handleModalClose();
  };

  const handleFailureRequest = error => {
    const { response } = error;
    setIsDisable(false);
    setOnRequest(false);
    setMoreData(!moreData);
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const { handleApproveOvertime, handleSignApproveOvertime } = useCreateOvertimeHook();

  const aproveWorfklow = overtime => {
    setDefaultModalButtons(false);
    setDefaultModalTitle('Firmar y Aprobar Solicitud de Horas Extras');
    setDefaultModalBody(
      <SignatureKeyForm
        formRequest={(formKey, _item, setSubmitting) => {
          handleSignApproveOvertime(formKey, overtime, setSubmitting, handleSuccessRequest, handleFailureRequest);
        }}
        onHide={handleModalClose}
        signatureKey=""
      />
    );
  };

  const handleApproveModal = overtime => {
    setIsDisable(false);
    setOnRequest(false);
    setDefaultModalItem(overtime);
    setDefaultModalButtons(true);
    setDefaultModalTitle('Aprobar Solicitud de Horas Extras');
    setDefaultModalBody('¿Estás seguro que deseas Aprobar Solicitud de Horas Extras?');
    setDefaultModalAction(() => (_item, setSubmitting) => {
      handleApproveOvertime(overtime, setSubmitting, handleSuccessRequest, handleFailureRequest);
    });
  };

  const rejectDocument = (values, setSubmitting) => {
    setOnRequest(true);
    const {
      workflowRequest: { record }
    } = values;

    rejectOvertimeRequest(record.id, {
      params: snakeCaseKeys(values),
      dispatch,
      successCallback: () => handleSuccessRequest('Solicitud de Horas Extras rechazada correctamente..!!'),
      callback: () => setSubmitting(false)
    });
  };

  const handleRejectModal = () => {
    setDefaultModalTitle('Rechazar Solicitud de Horas Extras');
    setDefaultModalBody(
      <WorkflowRequestReject
        record={item}
        workflowRequest={item}
        cancelAction={handleModalClose}
        formRequest={rejectDocument}
      />
    );
    setDefaultModalShow(true);
    setDefaultModalButtons(false);
  };

  const handleActions = action => {
    switch (action) {
      case 'approved':
        if (item.requestReviewerSignature) {
          aproveWorfklow(item);
        } else {
          handleApproveModal(item);
        }
        setDefaultModalShow(true);
        break;
      case 'rejected':
        handleRejectModal();
        break;
      case 'resend':
        setReminderShow(true);
        setReminderItem(snakeCaseKeys(item));
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const headColumns = columns(item, ability).filter(
    column => !['ACCIONES', 'ESTADO', 'APROBAR'].some(name => column.name === name)
  );

  return (
    <>
      <Modal.Body>
        <div className="info-box">
          <Row>
            <Col md={2} lg={2}>
              <ApprovementStatusStyled margin="mt-1" status={status} />
            </Col>
            <Col md={8} lg={8} className="title-pw">
              <b>Gestionar Solicitud de Horas Extras</b>
            </Col>
            <Col md={2} lg={2} className="title-pw" />
          </Row>
          <table className="simple-table">
            <tbody>
              <tr>
                {headColumns.map(column => (
                  <th key={column.name} style={{ textAlign: 'center', paddingTop: '25px' }}>
                    {column.name}
                  </th>
                ))}
              </tr>

              <tr>
                <td style={{ width: '150px' }}>
                  <div bis_skin_checked="1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div aria-hidden="true" bis_skin_checked="1" className="employee-info" />
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                      <DatatableEmployeeName
                        item={item.employee}
                        fileName="fileInfo"
                        name="shortName"
                        fileUrl="fileUrl"
                      />
                    </span>
                  </div>
                </td>
                <td align="center">{item.requestDate}</td>
                <td align="center">{item.date}</td>
                <td align="center">{item.hours}</td>
                <td align="center">{item.minutes}</td>
                <td align="center">{item.translatedOvertimeType}</td>
              </tr>
            </tbody>
          </table>
          <br />

          <Row>
            <Col className="text-center">
              <ApproveRejectStyledActions
                resource="Overtime"
                item={item}
                clickHandler={handleActions}
                approveText={item.requestReviewerSignature ? 'Firmar / Aprobar' : 'Aprobar'}
                withDestroy
              />
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <DefaultModal
        body={defaultModalBody}
        disabled={onRequest || isDisabled}
        handleClose={handleModalClose}
        handleConfirm={() => defaultModalAction(modalItem)}
        show={defaultModalShow}
        title={defaultModalTitle}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        withClose={defaultModalButtons}
        withConfirm={defaultModalButtons}
      />
      <Reminder
        item={reminderItem}
        show={reminderShow}
        modalShow={setReminderShow}
        moreData={moreData}
        setOnRequest={setOnRequest}
        setMoreData={setMoreData}
      />
    </>
  );
};

export default OvertimeInfoTable;
