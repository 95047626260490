/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import { Button } from 'react-bootstrap';
import { ApprovementStatus, DatatableEmployeeName } from '../../..';
import { DatatableEmployeeNameAttendance } from '../../../DatatableColumns';

const listActions = (item, clickHandler) => {
  return (
    <>
      <Button variant="pw" block onClick={() => clickHandler(item, 'actions')} size="sm">
        Gestionar
      </Button>
    </>
  );
};

const getColumnEmployee = module => {
  if (module === 'list') {
    return {
      name: 'NOMBRE DEL TRABAJADOR',
      selector: 'employee',
      cell: item => <DatatableEmployeeName item={item.employee} />,
      sortable: true,
      grow: '1',
      width: '200px',
      align: 'left'
    };
  }

  return {
    name: 'NOMBRE DEL TRABAJADOR',
    selector: 'employee',
    cell: item => <DatatableEmployeeNameAttendance item={item.employee} />,
    sortable: true,
    grow: '1',
    width: '200px',
    align: 'left'
  };
};

const OvertimeColumns = memoize((clickHandler, ability, module = 'list') => [
  getColumnEmployee(module),
  {
    name: 'FECHA DE SOLICITUD',
    selector: 'request_date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA HORAS EXTRAS',
    selector: 'date',
    sortable: true,
    grow: '1'
  },
  {
    name: 'HORAS',
    selector: 'hours',
    sortable: true,
    grow: '1'
  },
  {
    name: 'MINUTOS',
    selector: 'minutes',
    sortable: true,
    grow: '1'
  },
  {
    name: 'TIPO DE HORAS EXTRAS',
    selector: 'overtime_type',
    cell: item => item.translated_overtime_type,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => <ApprovementStatus status={item.status} onClick={() => clickHandler(item, 'show')} />,
    grow: '1',
    center: true
  },
  {
    name: 'ACCIONES',
    cell: item => {
      if (module === 'list') {
        return listActions(item, clickHandler, ability);
      }

      return (
        <Button variant="pw" block onClick={() => clickHandler(item, 'actions')} size="sm">
          Gestionar
        </Button>
      );
    },
    grow: '1',
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    minWidth: '123px'
  }
]);

export default OvertimeColumns;
