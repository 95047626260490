import ApiService from '../services/apiService';

export const generateReport = ({ dispatch, params = {}, successCallback, callback, format, responseType }) =>
  ApiService.request('post', `/reports/generate_jd1887_report${format}`, {
    dispatch,
    params,
    format,
    responseType,
    successCallback,
    callback,
    service: 'payroll'
  });

export default generateReport;
