import React, { Component } from 'react';
import { withFormik, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Accordion } from 'react-bootstrap';
import { daysOfWeek } from './FormOptions';
import './style.scss';
import GeneralData from './Accordion/GeneralData';
import ToleranceTab from './Accordion/Tolerance';
import Colation from './Accordion/Colation';
import Flexibility from './Accordion/Flexibility';
import Assignment from './Accordion/Assignment';
import WeekDistribution from './Accordion/WeekDistribution';

class ShiftForm extends Component {
  state = { accordion: 'general' }

  constructor(props) {
    super(props);
    this.timeout = null;
  }

  handleChangeTab = tab => {
    this.setState({ accordion: tab });
  }

  dayCheckBoxValue = (day, index, element) => {
    const { setFieldValue, values } = this.props;
    if (element._dispatchListeners[0] === undefined) {
      values.shift.shiftDaysAttributes[index].send = !values.shift.shiftDaysAttributes[index].send;
    }
    setFieldValue(`shift[shiftDaysAttributes][${index}][weekDay]`, day);
  };

  render() {
    const { accordion } = this.state;
    const { values, errors, onHide, touched, setFieldValue, shift, disabled } = this.props;
    const {
      exceptionalWorkday,
      flexibleShift,
      shiftType,
    } = values.shift;

    return (
      <Form>
        <Accordion defaultActiveKey="0">
          <GeneralData
            currentTab={accordion}
            disabled={disabled}
            errors={errors}
            exceptionalWorkday={exceptionalWorkday}
            extensionDefaultValue='weekly_extension'
            onChangeTab={this.handleChangeTab}
            setFieldValue={setFieldValue}
            shiftType={shiftType}
            touched={touched}
          />
          <Flexibility
            currentTab={accordion}
            disabled={disabled}
            errors={errors}
            getIn={getIn}
            onChangeTab={this.handleChangeTab}
            setFieldValue={setFieldValue}
            touched={touched}
            values={values}
          />
          <ToleranceTab
            currentTab={accordion}
            disabled={disabled}
            errors={errors}
            flexibleShift={flexibleShift}
            onChangeTab={this.handleChangeTab}
            setFieldValue={setFieldValue}
            touched={touched}
            values={values}
          />
          <Colation
            currentTab={accordion}
            disabled={disabled}
            errors={errors}
            onChangeTab={this.handleChangeTab}
            setFieldValue={setFieldValue}
            touched={touched}
            values={values}
          />
          <WeekDistribution
            currentTab={accordion}
            dayCheckBoxValue={this.dayCheckBoxValue}
            disabled={disabled}
            errors={errors}
            getIn={getIn}
            onChangeTab={this.handleChangeTab}
            setFieldValue={setFieldValue}
            touched={touched}
            values={values}
          />
          <Assignment
            currentTab={accordion}
            disabled={disabled}
            errors={errors}
            getIn={getIn}
            onChangeTab={this.handleChangeTab}
            setFieldValue={setFieldValue}
            shift={shift}
            touched={touched}
          />
        </Accordion>
        {!disabled && (<Row className="d-flex justify-content-end my-3">
          <Col md={3}>
            <Button type="submit" variant="primary" block onClick={onHide}>
              Guardar
            </Button>
          </Col>
        </Row>)}
      </Form>
    );
  }
}

const setInitialValues = props => {
  const { action } = props;
  const { shiftDaysAttributes } = props.shift;
  let shiftDays = daysOfWeek;
  if (shiftDaysAttributes.length) {
    shiftDays = daysOfWeek.map(day => {
      const shiftDay = shiftDaysAttributes.filter(item => item.weekDay === day.value);
      if (shiftDay.length) {
        return { ...shiftDay[0], send: true };
      }
      return { ...day, send: false };
    });
  }
  const shiftEmpty = shiftDays.map(day => {
    return { ...day, send: false };
  });
  return {
    shift: {
      ...props.shift,
      shiftDaysAttributes: action === 'new' ? shiftEmpty : shiftDays,
      shiftType: 'weekly'
    }
  };
};

const validationSchema = Yup.object().shape({
  shift: Yup.object().shape(
    {
      workHolidays: Yup.boolean(),
      imputableColation: Yup.boolean(),
      uniqueTolerance: Yup.boolean(),
      uniqueAllDays: Yup.boolean(),
      tolerance: Yup.number().when('flexibleShift', {
        is: val => val !== 'true',
        then: Yup.number()
          .required('Debes ingresar una tolerancia')
          .min(0, 'Debe ser mayor o igual a 0')
      }),
      lunchCheck: Yup.bool(),
      collationTime: Yup.bool().when('agreedSchedule', {
        is: aSchedule => aSchedule === false,
        then: Yup.bool().oneOf([true], 'Debes seleccionar una opción')
      }),
      agreedSchedule: Yup.bool().when('collationTime', {
        is: colTime => colTime === false,
        then: Yup.bool().oneOf([true], 'Debes seleccionar una opción')
      }),
      lunchTime: Yup.number().when('collationTime', {
        is: val => val,
        then: Yup.number()
          .required('Debes ingresar un número')
          .min(0, 'Debe ser mayor o igual a 0 '),
        otherwise: Yup.number().nullable()
      }),
      initialTime: Yup.string().when('flexibleShift', {
        is: val => val,
        then: Yup.string().required('Debes ingresar un horario'),
        otherwise: Yup.string().nullable()
      }),
      maximumStartTime: Yup.string().when('flexibleShift', {
        is: val => val,
        then: Yup.string().required('Debes ingresar tiempo máximo de inicio')
      }),
      workHours: Yup.string().when('flexibleShift', {
        is: val => val,
        then: Yup.string().required('Debes ingresar cantidad de horas a trabajar')
      }),
      name: Yup.string()
        .required('Debes ingresar un nombre')
        .max(80, 'Máximo 80 caracteres'),
      acronym: Yup.string()
        .required('Debes ingresar las siglas del turno')
        .max(5, 'Máximo 5 caracteres'),
      description: Yup.string()
        .required('Debes ingresar la descripción del turno')
        .max(80, 'Máximo 80 caracteres'),
      workdayNumber: Yup.string().when('exceptionalWorkday', {
        is: val => val === true,
        then: Yup.string()
          .required('Debes ingresar el número de jornada')
          .max(15, 'Máximo 15 caracteres')
      }),
      beginExceptionalWorkday: Yup.string().when('exceptionalWorkday', {
        is: val => val === true,
        then: Yup.string().required('Debes ingresar la fecha de inicio')
      }),
      expirationExceptionalWorkday: Yup.string().when('exceptionalWorkday', {
        is: val => val === true,
        then: Yup.string().required('Debes ingresar la fecha de vencimiento')
      }),
      shiftDaysAttributes: Yup.array()
        .when('agreedSchedule', {
          is: val => val,
          then: Yup.array().of(
            Yup.object({
              endLunch: Yup.string().when('send', {
                is: val => val,
                then: Yup.string()
                  .required('Debes ingresar hora de término')
                  .test('is-greater', 'La hora de término debe ser mayor o igual que la hora de inicio', function isGreaterTest(
                    value
                  ) {
                    const { startLunch } = this.parent;
                    return startLunch && value >= startLunch;
                  }),
                otherwise: Yup.string().nullable()
              }),
              startLunch: Yup.string().when('send', {
                is: val => val,
                then: Yup.string().required('Debes ingresar hora de inicio'),
                otherwise: Yup.string().nullable()
              })
            })
          )
        })
        .of(
          Yup.object().shape({
            endTime: Yup.string().when('send', {
              is: val => val,
              then: Yup.string()
                .required('Debes ingresar hora de término')
                .notOneOf([Yup.ref('startTime'), null], 'Las horas de inicio y termino no pueden ser iguales'),
              otherwise: Yup.string().nullable()
            }),
            startTime: Yup.string().when('send', {
              is: val => val,
              then: Yup.string().required('Debes ingresar hora de inicio'),
              otherwise: Yup.string().nullable()
            }),
            send: Yup.boolean()
          })
        )
    },
    [['agreedSchedule', 'collationTime']]
  )
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ShiftForm);
