const daysOfWeek = [
  {
    label: 'Lunes',
    value: 'monday',
    send: false,
    startTime: '09:00',
    endTime: '18:00',
    startLunch: '14:00',
    endLunch: '15:00',
    weekDay: ''
  },
  {
    label: 'Martes',
    value: 'tuesday',
    send: false,
    startTime: '09:00',
    endTime: '18:00',
    startLunch: '14:00',
    endLunch: '15:00',
    weekDay: ''
  },
  {
    label: 'Miércoles',
    value: 'wednesday',
    send: false,
    startTime: '09:00',
    endTime: '18:00',
    startLunch: '14:00',
    endLunch: '15:00',
    weekDay: ''
  },
  {
    label: 'Jueves',
    value: 'thursday',
    send: false,
    startTime: '09:00',
    endTime: '18:00',
    startLunch: '14:00',
    endLunch: '15:00',
    weekDay: ''
  },
  {
    label: 'Viernes',
    value: 'friday',
    send: false,
    startTime: '09:00',
    endTime: '18:00',
    startLunch: '14:00',
    endLunch: '15:00',
    weekDay: ''
  },
  {
    label: 'Sábado',
    value: 'saturday',
    send: false,
    startTime: '09:00',
    endTime: '18:00',
    startLunch: '14:00',
    endLunch: '15:00',
    weekDay: ''
  },
  {
    label: 'Domingo',
    value: 'sunday',
    send: false,
    startTime: '09:00',
    endTime: '18:00',
    startLunch: '14:00',
    endLunch: '15:00',
    weekDay: ''
  }
];

const shiftExtensions = [
  { label: 'Diario', value: 'daily_extension' },
  { label: 'Semanal', value: 'weekly_extension' },
  { label: 'Quincenal', value: 'biweekly_extension' },
  { label: 'Mensual', value: 'monthly_extension' }
]

export { daysOfWeek, shiftExtensions };
