import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const generateCertificatesRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  service = 'payroll'
}) => {
  ApiService.request('post', '/employee_declarations', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    service
  });
};

export const indexEmployeeDeclarationRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  service = 'payroll'
}) =>
  ApiService.request('get', '/employee_declarations', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    service
  });

export const sendCertificate = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  service = 'payroll'
}) =>
  ApiService.request('post', '/employee_declarations/send_email', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    service
  });

export const downloadCertificateRequest = ({
  dispatch,
  params = {},
  format,
  responseType,
  successCallback,
  callback,
  service = 'payroll'
}) =>
  ApiService.request('get', '/employee_declarations/download.zip', {
    dispatch,
    params,
    format,
    responseType,
    successCallback,
    callback,
    service
  });

export const sendWorkflowRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  service = 'payroll'
}) =>
  ApiService.request('get', '/employee_declarations/send_workflow', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    service
  });

export const graphic = ({ dispatch, params = {}, successCallback, failureCallback, callback, service = 'payroll' }) =>
  ApiService.request('get', '/employee_declarations/graphic', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    service
  });

export const debounceIndexEmployeeDeclarationRequest = AwesomeDebouncePromise(indexEmployeeDeclarationRequest, 300);
