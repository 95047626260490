import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { WorkflowTimeline, ComponentDataTable, DefaultModal, Reminder, SimpleCenteredModal } from '../../../components';
import { debounceIndexDocumentsRequest } from '../../../requests/documents';
import { deleteDirectoryFileRequest } from '../../../requests/directoryNodes';
import { deleteWorkflowRequestRequest } from '../../../requests/workflowRequests';
import { delayMethod } from '../../../services/utils';
import { sendAlert } from '../../../actions/utils';
import WorkflowRequestNew from '../../WorkflowRequest/WorkflowRequestNew';
import columns from './Columns_Token';
import DocumentPreviewToken from '../../DocumentPreview/DocumentPreviewToken';
import './Style.scss';

const DocumentsDatatable = ({ moreData, setMoreData, customParams, employeeSearch, allowedActions, rrhh = false }) => {
  const [documents, setDocuments] = useState([]);
  const [amount, setAmount] = useState(0);
  const [onRequest, setOnRequest] = useState(false);
  const [reminderItem, setReminderItem] = useState({});
  const [reminderShow, setReminderShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalActions, setModalActions] = useState(false);
  const [modalItem, setModalItem] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalSize, setModalSize] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [defaultModalConfirmAction, setDefaultModalConfirmAction] = useState('');
  const [documentModalShow, setDocumentModalShow] = useState(false);
  const [documentModalBody, setDocumentModalBody] = useState('');
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [isRequestSign, setIsRequestSign] = useState(false);

  const dispatch = useDispatch();

  const handleRequest = async params => {
    if (customParams.filter_by_token) {
      setOnRequest(true);
      const localParams = { ...params };
      if (rrhh === false) {
        localParams.documents_filter_guidelines = JSON.stringify({
          work_contract: ['approved'],
          contract_annex: ['approved'],
          salary_settlement: ['approved'],
          work_settlement: ['approved'],
          reprimand_document: ['approved'],
          congratulation_letter: ['approved'],
          salary_certificate: ['approved']
        });
      }

      debounceIndexDocumentsRequest({
        dispatch,
        format: '',
        params: { ...params, ...customParams },
        successCallback: response => {
          const { data, metadata } = response.data;
          setDocuments(data);
          setAmount(metadata.amount);
        },
        callback: () => setOnRequest(false)
      });
    }
  };

  const handleSuccessRequest = (message, clearRow = false) => {
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
    setModalShow(false);
    setOnRequest(false);
    if (clearRow) {
      setClearSelectedRows(!clearSelectedRows);
    }
  };

  const removeDirectoryFile = documentFile => {
    const folderId = documentFile.directory_node_id;
    const documentId = documentFile.id;
    delayMethod(() => setModalShow(false), 160);
    setOnRequest(true);
    deleteDirectoryFileRequest({
      params: {
        documents: [
          {
            directory_node_id: folderId,
            document_id: documentId,
            employee_id: documentFile.employee.id
          }
        ]
      },
      dispatch,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Archivo eliminado con éxito' }));
        setMoreData(!moreData);
      },
      failureCallback: error => {
        const { response } = error;

        let errorMessage = response.statusText;
        if (response.data && response.data.message) {
          errorMessage = response.data.message;
        }

        setModalTitle(
          <span role="img" aria-label="">
            {' '}
            ¡UPS! &#128559;{' '}
          </span>
        );
        setModalBody(errorMessage);
        setModalActions(false);
        setModalShow(true);
      },
      callback: () => setOnRequest(false)
    });
  };

  const removeDocumentWorkflow = document => {
    const workflowId = document.workflow_request_id;

    delayMethod(() => setModalShow(false), 160);
    setOnRequest(true);
    deleteWorkflowRequestRequest([workflowId], {
      dispatch,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Flujo eliminado con éxito' }));
        setMoreData(!moreData);
      },
      callback: () => setOnRequest(false)
    });
  };

  const handleDefaultModalConfirmAction = (item, action) => {
    if (item.has_pending_annexes && isRequestSign) {
      setModalTitle(`Enviar a firmar ${item.file_info.clean_filename}`);
      setModalBody(
        <WorkflowRequestNew
          document={camelCaseRecursive(item)}
          workflowType={item.documentType}
          handleModalClose={() => setModalShow(false)}
          handleSuccessWorkflowRequest={handleSuccessRequest}
        />
      );
      setModalActions(false);
      setModalSize('xl');
      setModalShow(true);
      return;
    }

    switch (action) {
      case 'remove':
        removeDirectoryFile(item);
        break;
      case 'remove-workflow':
        removeDocumentWorkflow(item);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  const handleButtonClick = (item, action) => {
    setIsRequestSign(false);
    switch (action) {
      case 'show':
        setDocumentModalBody(<DocumentPreviewToken documentId={item.id} employeeId={item.employee?.id} />);
        setDocumentModalShow(true);
        break;
      case 'download':
        window.open(item.file_info.file_url, '_blank');
        break;
      case 'remove':
        setModalTitle('Eliminar Archivo');
        setModalBody(`¿Estás seguro que deseas eliminar ${item.file_info.filename}?`);
        setModalItem(item);
        setDefaultModalConfirmAction('remove');
        setModalSize('');
        setModalActions(true);
        setModalShow(true);
        break;
      case 'remove-workflow':
        setModalTitle('Cancelar Flujo');
        setModalBody(`¿Estás seguro que deseas cancelar el flujo del documento ${item.file_info.filename}?`);
        setModalItem(item);
        setDefaultModalConfirmAction('remove-workflow');
        setModalSize('');
        setModalActions(true);
        setModalShow(true);
        break;
      case 'show-workflow':
        setModalTitle('Detalles del flujo');
        setModalBody(
          <WorkflowTimeline
            workflowId={item.workflow_request_id}
            document={camelCaseRecursive(item)}
            handleClose={() => setModalShow(false)}
          />
        );
        setModalActions(false);
        setModalSize('lg');
        setModalShow(true);
        break;
      case 'resend':
        setReminderShow(true);
        setReminderItem(item);
        break;
      case 'request-sign':
        setIsRequestSign(true);
        setModalItem(item);
        if (item.has_pending_annexes) {
          setModalTitle(`Anexos de contratos`);
          setModalBody(
            <>
              <p>
                El trabajador {item.employee.full_name} tiene los siguientes anexos de contrato pendientes de
                firma/aprobación:
              </p>
              <br />
              <ul>
                {item.pending_annexes.map(document => (
                  <li>{document.file_name}</li>
                ))}
              </ul>
              <br />
              <p> ¿Estás seguro que deseas continuar? </p>
            </>
          );
          setModalActions(true);
          setModalSize('md');
          setModalShow(true);
          break;
        } else {
          setModalTitle(`Enviar a firmar ${item.file_info.clean_filename}`);
          setModalBody(
            <WorkflowRequestNew
              document={camelCaseRecursive(item)}
              workflowType={item.documentType}
              handleModalClose={() => setModalShow(false)}
              handleSuccessWorkflowRequest={handleSuccessRequest}
            />
          );
          setModalActions(false);
          setModalSize('xl');
          setModalShow(true);
        }
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  const handleDocumentModalClose = () => {
    setMoreData(!moreData);
    setDocumentModalShow(false);
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick, allowedActions)}
        data={documents}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={handleRequest}
        onRowClicked={item => handleButtonClick(item, 'show')}
        pointerOnHover
        withMassActions={rrhh}
        withSearch={!employeeSearch}
        preName="documents"
      />
      <Reminder
        item={reminderItem}
        show={reminderShow}
        modalShow={setReminderShow}
        moreData={moreData}
        setOnRequest={setOnRequest}
        setMoreData={setMoreData}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={() => handleDefaultModalConfirmAction(modalItem, defaultModalConfirmAction)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        modalSize={modalSize}
        withClose={modalActions}
        withConfirm={modalActions}
        disabled={onRequest}
      />
      <SimpleCenteredModal
        body={documentModalBody}
        onHide={handleDocumentModalClose}
        show={documentModalShow}
        size="xl"
      />
    </>
  );
};

export default DocumentsDatatable;
