import React, { useState } from 'react';
import { Row, Col, Tab, Nav, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import snakecaseKeys from 'snakecase-keys';
import { Icon, SimpleCenteredModal } from '../../components';
import { useSetTab } from '../../services/hooks';
import ProcessReportsIndex from './ProcessSummary/ProcessReportsIndex';
import ability from '../../config/ability';
import SummaryProcessIndex from './SummaryProcessIndex';
import CertDj from '../FileBroadcast/CertDJ';
import { generateCertificatesRequest } from '../../requests/employeeDeclaration';
import { sendAlert } from '../../actions/utils';
import CerDjFrom from '../FileBroadcast/CertDJ/CerDjFrom';

function ProcessSummary({ location }) {
  const defaultKey = () => {
    if (ability.can('process_summary', 'ProcessSummary')) return 'process-summary';
    if (ability.can('process_reports', 'ProcessSummary')) return 'process-reports';
    return 'process-summary';
  };

  const [key, setKey] = useSetTab(defaultKey(), location);
  const [onRequest, setOnRequest] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalSize, setModalSize] = useState();
  const [modalBody, setModalBody] = useState('');
  const { user, currentCompany } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const company = {
    id: currentCompany?.id,
    businessName: currentCompany?.businessName,
    nationalIdentification: currentCompany?.parsedNationalId,
    businessLogoBase64: currentCompany?.businessLogo64,
    addressAndNumber: currentCompany?.address,
    economicActivity: currentCompany?.economicActivity,
    representant: {
      id: currentCompany?.representantId,
      nationalIdentification: currentCompany?.representantNi,
      fullName: currentCompany?.representantName,
      imageSignature: currentCompany?.representantImgSig64
    }
  };
  const createdBy = {
    id: user?.employeeCard?.id,
    nationalIdentification: user?.nationalIdentification,
    fullName: user?.fullName,
    imageSignature: user?.employeeCard?.digitalSign64
  };
  const handleGenerateCertificates = params => {
    setOnRequest(true);
    generateCertificatesRequest({
      dispatch,
      params: snakecaseKeys(params),
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Proceso creado con éxito' }));
        setOnRequest(false);
        setMoreData(!moreData);
        setModalShow(false);
      }
    });
  };
  const generateCertificates = () => {
    setModalTitle('Generar Certificado Sueldo Sobre Sueldos');
    setModalSize('lg');
    setModalShow(true);
    setModalBody(<CerDjFrom formRequest={handleGenerateCertificates} company={company} createdBy={createdBy} />);
  };
  return (
    <>
      <Row className="mt-4 mb-2">
        <Col>
          <h2 className="mb-3 mt-3">Reportes</h2>
        </Col>
        {key === 'cert1887' && (
          <Col md={2}>
            <Button
              variant="primary btn-block mb-3 mt-3"
              disabled={!company.id && !user.id}
              onClick={generateCertificates}
            >
              Generar Certificados
            </Button>
          </Col>
        )}
      </Row>
      <Tab.Container id="company-settings-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills">
          {ability.can('process_summary', 'ProcessSummary') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="process-summary">
                <Icon icon="document" className="mb-1" />
                Resumen del Proceso
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('process_reports', 'ProcessSummary') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="process-reports">
                <Icon icon="document" className="mb-1" />
                Reportes del Proceso
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('certificate_dj', 'ProcessSummary') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="cert1887">
                <Icon icon="document" className="mb-1" />
                Certificados de Sueldo y Sobre Sueldos
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="process-summary">
            <SummaryProcessIndex />
          </Tab.Pane>
          <Tab.Pane eventKey="process-reports">
            <ProcessReportsIndex />
          </Tab.Pane>
          <Tab.Pane eventKey="cert1887">
            <CertDj onRequest={onRequest} setOnRequest={setOnRequest} setMoreData={setMoreData} moreData={moreData} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        onHide={() => setModalShow(false)}
        size={modalSize}
      />
    </>
  );
}

export default ProcessSummary;
