import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { Button, Row, Col, Form, Spinner, Card } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { useParams } from 'react-router-dom';
import { AbilityContext } from '../../config/abilityContext';
import { useAuthorization } from '../../services/hooks';
import { sendAlert, setAdvanceSettings } from '../../actions/utils';
import {
  BasicCard,
  BasicTooltip,
  FormikNumber,
  FormikSelect,
  Icon,
  UploadImage,
  DefaultModal,
  ButtonTooltip
} from '../../components';
import {
  indexAdvanceSettingsRequest,
  advanceSettingAdvancePaymentsRequest,
  updateAdvanceSettingRequest
} from '../../requests/advanceSettings';
import {
  showCompanyRequest,
  updateCompanyRequest,
  exportCompanyRequest,
  generateSecretKeyCompanyRequest
} from '../../requests/companies';
import { validateToken } from '../../actions/auth';
import './style.scss';
import { listedSettings, selectorOptions } from './CardInfo';
import { hideString } from '../../services/utils';
import { generateSecretKeyAdminCompanyRequest } from '../../requests/adminCompanies';
import FormAdvancePayment from './FormAdvancePayment';
import LoanFormSettings from '../LoanSettings/LoanFormSettings';
import ModalLoanSettings from '../LoanSettings/ModalLoanSettings';
import FieldWorkdayReportEarlyOvertimeEffectiveDate from './FieldWorkdayReportEarlyOvertimeEffectiveDate';
import FormOvertimeRequests from './FormOvertimeRequests';

const AdvanceSettings = props => {
  const dispatch = useDispatch();
  const childRef = useRef();
  const ability = useAbility(AbilityContext);
  const [onRequest, setOnRequest] = useState(false);
  const [settingData, setSettingData] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [advancePayment, setAdvancePayment] = useState([]);
  const [advancePaymentLoan, setAdvancePaymentLoan] = useState([]);
  const [overtimeRequests, setOvertimeRequests] = useState([]);
  const [modalShowApiCredentials, setModalShowApiCredentials] = useState(false);
  const [modalBodyApiCredentials, setModalBodyApiCredentials] = useState('');
  const [modalTitleApiCredentials, setModalTitleApiCredentials] = useState('');
  const [initialSettingData, setInitialSettingData] = useState({});
  const [companyLogo, setCompanyLogo] = useState({});
  const [logoUpdateButton, setLogoUpdateButton] = useState(false);
  const params = useParams();
  const { id } = params;
  const companyId = id || localStorage.getItem('currentCompany');
  const canUpdateSettings = useAuthorization('update', 'AdvanceSetting');
  const { currentCompany, ongoingRequest, company } = useSelector(state => state.auth);
  const [showSecretKey, setShowSecretKey] = useState(false);
  const [withModalConfirm, setWithModalConfirm] = useState(true);
  const [currentSecretKey, setCurrentSecretKey] = useState('');
  const [closeBtnMsg, setCloseBtnMsg] = useState('Cancelar');
  const [loanSetting, setLoanSetting] = useState({});
  const { admin, isProfile } = props;
  const { apiKey, secretKey } = company;
  const secretBtnIcon = showSecretKey ? 'eye-off' : 'eye';
  const secretBtnMsg = showSecretKey ? 'Ocultar' : 'Mostrar';
  const hiddenSecretKey = currentSecretKey?.length ? hideString(currentSecretKey, currentSecretKey.length - 7) : '';
  const modalsWithButtons = [
    'Comenzar Exportación de Empresa',
    'Desconectar Usuario DT',
    'HABILITAR SOLICITUD DE PRÉSTAMO'
  ];
  let vListedSettings = listedSettings;

  const handleNewSecretKeyModal = () => {
    const newModalTitle = 'Nueva secret key';
    const newModalBody = '¿Estás seguro que deseas crear una nueva secret key?';
    setWithModalConfirm(true);
    setModalShowApiCredentials(true);
    setCloseBtnMsg('Cancelar');
    setModalTitleApiCredentials(newModalTitle);
    setModalBodyApiCredentials(newModalBody);
  };

  const handleSuccessNewSecretRequest = response => {
    const { secretKey: newSecretKey } = camelCaseRecursive(response?.data);
    const newModalBody = (
      <p className="mb-0">
        Tu nueva secret key es: <b>{newSecretKey}</b>
      </p>
    );

    setWithModalConfirm(false);
    setCloseBtnMsg('Cerrar');
    setModalBodyApiCredentials(newModalBody);
    setCurrentSecretKey(newSecretKey);
    setOnRequest(false);
  };

  const handleFailureRequest = error => {
    const { response } = error;
    setModalShowApiCredentials(false);
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleNewSecretKeyRequest = () => {
    const allParams = {
      dispatch,
      successCallback: handleSuccessNewSecretRequest,
      failureCallback: handleFailureRequest
    };

    if (admin) generateSecretKeyAdminCompanyRequest(companyId, allParams);
    else generateSecretKeyCompanyRequest(companyId, allParams);
    setOnRequest(true);
  };

  if (!ability.can('manage', 'attendance_management')) {
    vListedSettings = listedSettings.filter(item => item.name !== 'mobile_assistance_distance');
  }

  const handleSuccessUpdate = message => {
    dispatch(setAdvanceSettings());
    dispatch(sendAlert({ kind: 'success', message }));
  };

  const settingUpdate = (advanceSetting, isLoanSetting = false) => {
    updateAdvanceSettingRequest(advanceSetting.id, {
      dispatch,
      params: snakeCaseKeys({ advanceSetting }),
      formData: true,
      successCallback: () => {
        if (isLoanSetting) {
          setOnRequest(false);
          setModalShow(false);
        }
        handleSuccessUpdate('Configuración modificada con éxito');
      }
    });
  };

  const companyLogoUpdate = () => {
    const companyData = { company: { logo: companyLogo } };
    updateCompanyRequest(companyId, {
      dispatch,
      params: companyData,
      formData: true,
      successCallback: () => handleSuccessUpdate('Logo empresa modificado con éxito')
    });
    setLogoUpdateButton(false);
  };

  const fetchAdvancePayments = () => {
    advanceSettingAdvancePaymentsRequest({
      dispatch,
      params: {
        /* NOTA:  No incluir aqui otra cosa distinta a anticipo */
        codes: [
          'advance_payment_amount_limit',
          'advance_payment_percentage_limit',
          'limit_day_advance_payment',
          'advance_payment_payday',
          'loan_amount_limit',
          'loan_percentage_limit'
        ]
      },
      successCallback: response => {
        /* NOTA:  No incluir aqui otra cosa distinta a anticipo */
        setAdvancePayment(response.data.filter(setting => !setting.code.includes('loan')));
        setAdvancePaymentLoan(response.data.filter(setting => setting.code.includes('loan')));
      }
    });
  };

  const settingIndex = () => {
    indexAdvanceSettingsRequest({
      dispatch,
      successCallback: response => {
        setSettingData(camelCaseRecursive(response.data));
        setInitialSettingData(camelCaseRecursive(response.data));
        setOvertimeRequests(response.data.filter(setting => setting.code.includes('hour_value_overtime_requests')));
      }
    });
    fetchAdvancePayments();
  };

  const companyShow = () => {
    showCompanyRequest(companyId, {
      dispatch,
      successCallback: response => setCompanyLogo(response.data.business_logo)
    });
  };

  const changeSetting = (settIndex, settValue, inputType) => {
    const newSettData = [...settingData];
    if (inputType) {
      newSettData[settIndex].integerValue = settValue;
    } else {
      newSettData[settIndex].textValue = String(!settValue);
    }
    setSettingData(newSettData);
  };

  const changeInitialSetting = (settIndex, settValue) => {
    const newSettData = [...initialSettingData];
    newSettData[settIndex].integerValue = settValue;
    setInitialSettingData(newSettData);
  };

  const handleModal = (body, title) => {
    setModalBody(body);
    setModalTitle(title);
    setModalShow(true);
  };

  const downloadModalBody = () => (
    <>
      <p>Comenzar el proceso de exportación</p>
      <ul>
        <li>
          La recopilación de la información se procederá en nuestros servidores, esto puede tardar varios minutos o
          incluso horas dependiendo de la cantidad de información considerada.
        </li>
        <li>Recibirás un email con un link de descarga adjunto cuando el proceso este completo.</li>
        <li>Procura realizar la descarga con una conexión segura y estable de internet.</li>
      </ul>
    </>
  );

  const handleExportCompany = () => {
    setOnRequest(true);
    exportCompanyRequest(companyId, {
      dispatch,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Proceso de descarga iniciado con éxito.' }));
        dispatch(validateToken());
      },
      callback: () => {
        setOnRequest(false);
        setModalShow(false);
      }
    });
  };

  const modalHandleConfirm = () => {
    switch (modalTitle) {
      case 'Comenzar Exportación de Empresa':
        handleExportCompany();
        setModalShow(false);
        break;
      case 'Desconectar Usuario DT':
        childRef.current.handleDeactivateUser();
        setModalShow(false);
        break;
      case 'HABILITAR SOLICITUD DE PRÉSTAMO':
        setOnRequest(true);
        settingUpdate(loanSetting, true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const handleCardToggleChange = (settIndex, checked, name) => {
    changeSetting(settIndex, checked);
    if (name !== 'advance_payment_request' || checked === true) {
      settingUpdate(settingData[settIndex]);
    }
  };

  const handleLoanSettingsUpdate = _loanSetting => {
    handleModal(<ModalLoanSettings />, 'HABILITAR SOLICITUD DE PRÉSTAMO');
    setLoanSetting(_loanSetting);
  };

  const cardBody = ({ text, name, tooltipText, inputType }) => {
    const isToggle = inputType === 'toggle';
    let settIndex = '';
    if (settingData.length) {
      settIndex = settingData.findIndex(value => value.code === name);
      if (settIndex < 0) settIndex = '';
    }
    const checked = settingData[settIndex]?.textValue === 'true';

    return (
      <>
        <Row>
          <Col sm={isToggle ? 9 : 8} md={12} lg={isToggle ? 6 : 5} xl={isToggle ? 8 : 7}>
            <p className="text-uppercase mt-1" style={{ letterSpacing: '1.5px' }}>
              {text}
            </p>
          </Col>
          <Col
            xs={6}
            sm={isToggle ? 2 : 3}
            md={6}
            lg={isToggle ? 4 : 5}
            xl={isToggle ? 3 : 4}
            className={`${isToggle ? 'd-flex justify-content-lg-end' : ''}`}
          >
            {inputType === 'input' && (
              <>
                {name === 'workday_report_early_overtime_effective_date' ? (
                  <FieldWorkdayReportEarlyOvertimeEffectiveDate
                    item={settingData[settIndex]}
                    initialItemData={initialSettingData[settIndex]}
                    canUpdateSettings={canUpdateSettings}
                    settingUpdate={settingUpdate}
                  />
                ) : (
                  <FormikNumber
                    fieldName=""
                    label=""
                    disabled={!canUpdateSettings}
                    value={settingData[settIndex]?.integerValue || '0'}
                    onChange={data => {
                      changeSetting(settIndex, data.target.value.replaceAll('.', ''), inputType !== 'toggle');
                    }}
                  />
                )}
                {initialSettingData[settIndex]?.integerValue !== settingData[settIndex]?.integerValue && (
                  <div style={{ marginLeft: '5px' }}>
                    <Icon
                      width={22}
                      disabled={!canUpdateSettings}
                      className="as-button text-warning"
                      icon="close-circle"
                      onClick={() =>
                        changeSetting(settIndex, initialSettingData[settIndex].integerValue, inputType !== 'toggle')
                      }
                    />
                    <Icon
                      width={22}
                      disabled={!canUpdateSettings}
                      className="as-button text-primary"
                      icon="checkmark-circle"
                      onClick={() => {
                        changeInitialSetting(settIndex, settingData[settIndex].integerValue);
                        settingUpdate(settingData[settIndex]);
                      }}
                    />
                  </div>
                )}
              </>
            )}
            {isToggle && (
              <>
                <Form.Group className="mt-1">
                  <Form.Check
                    id={`checkbox-${settIndex}`}
                    disabled={!canUpdateSettings}
                    checked={checked}
                    className={canUpdateSettings ? 'as-checkbox' : 'as-checkbox-disabled'}
                    label=""
                    type="switch"
                    onChange={() => {
                      handleCardToggleChange(settIndex, checked, name);
                    }}
                  />
                </Form.Group>
              </>
            )}
            {inputType === 'selector' && (
              <FormikSelect
                label=""
                options={selectorOptions[settingData[settIndex]?.code]}
                isDisabled={!canUpdateSettings}
                placeholder={settingData[settIndex]?.integerValue || '0'}
                onChange={data => {
                  changeSetting(settIndex, data.value, inputType !== 'toggle');
                  settingUpdate(settingData[settIndex]);
                }}
              />
            )}
          </Col>
          {tooltipText && (
            <Col className="d-flex justify-content-end">
              <Form.Label className="position-sm-absolute mt-2 mb-0">
                <BasicTooltip text={tooltipText}>
                  <Icon icon="help-circle" width={20} />
                </BasicTooltip>
              </Form.Label>
            </Col>
          )}
        </Row>

        {name === 'advance_payment_request' && checked === true && advancePayment.length > 0 && (
          <Row>
            <Col sm={12}>
              <FormAdvancePayment
                advanceSetting={advancePayment}
                extraAction={() => {
                  settingUpdate(settingData[settIndex]);
                }}
              />
            </Col>
          </Row>
        )}

        {name === 'loan_request' && checked === true && advancePaymentLoan.length > 0 && (
          <Row>
            <Col sm={12}>
              <LoanFormSettings
                amountData={advancePaymentLoan[0]}
                percentageData={advancePaymentLoan[1]}
                extraAction={() => {
                  handleLoanSettingsUpdate(settingData[settIndex]);
                }}
              />
            </Col>
          </Row>
        )}

        {name === 'overtime_requests' && checked === true && overtimeRequests.length > 0 && (
          <Row>
            <Col sm={12}>
              <FormOvertimeRequests hourValueData={overtimeRequests[0]} handleSuccessUpdate={handleSuccessUpdate} />
            </Col>
          </Row>
        )}
      </>
    );
  };

  useEffect(() => {
    setCurrentSecretKey(secretKey);
  }, [secretKey]);
  useEffect(settingIndex, []);
  useEffect(companyShow, []);

  return (
    <>
      {onRequest ||
        (ongoingRequest.validateToken && (
          <div className="containerSpinnerLoad fix-middle center-spinner position-fixed">
            <Spinner animation="border" variant="primary" />
          </div>
        ))}
      <Row className="mt-4 mb-2">
        <Col>
          <h2 className="mt-3 mb-3">Ajustes</h2>
        </Col>
      </Row>
      <h4 className="text-uppercase mt-3 mb-3">Logo Empresa</h4>
      <Row>
        <Col md={8}>
          <UploadImage
            disabled={!canUpdateSettings}
            name="Modificar Logo"
            imageUrl={companyLogo.file_url}
            onChange={avatar => {
              setCompanyLogo(avatar);
              setLogoUpdateButton(true);
            }}
            helpText="Formato sugerido 620x400px de máximo 10mb."
            fileAccept=".png, .jpg, .gif, .jpeg"
          />
        </Col>
      </Row>
      <Row>
        <Col md={3} style={{ marginTop: '30px', marginLeft: '20px' }}>
          {logoUpdateButton && (
            <Button type="submit" className="btn" onClick={companyLogoUpdate}>
              Confirmar Logo
            </Button>
          )}
        </Col>
      </Row>

      <h4 className="text-uppercase mt-2 mb-3">Configuraciones Generales</h4>
      <Row className={`${onRequest || (ongoingRequest.validateToken && 'bg-opacity')}`}>
        {vListedSettings.map(body => {
          return (
            <Col key={`asd-${body.name}`} md={6}>
              <BasicCard
                size="100%"
                classNameCard="z-index-auto"
                bodyMarginBottom="-10px"
                colorCard="white"
                divBody
                text={cardBody(body)}
              />
            </Col>
          );
        })}
      </Row>
      {currentCompany.allowDownload && ability.can('export', 'Company') && (
        <Row>
          <Col xs={12} md={6}>
            <h4 className="text-uppercase mb-2">Exportar Empresa</h4>
            {currentCompany.availableDownload ? (
              <p className="text-muted">
                Peoplework ha autorizado la exportación de la información de tu empresa. Este permiso estará vigente
                hasta {currentCompany.zipRequestEndDate}.
              </p>
            ) : (
              <p className="text-muted">
                Ya has iniciado la exportación de información de tu empresa. Recibirás un email con el link de descarga
                cuando el proceso este finalizado.
              </p>
            )}
            <BasicCard
              size="100%"
              bodyMarginBottom="-10px"
              colorCard="white"
              divBody
              text={
                <Row>
                  <Col md={8} xs={6}>
                    <p className="text-uppercase mt-1" style={{ letterSpacing: '1.5px' }}>
                      Exportar Empresa
                    </p>
                  </Col>
                  <Col xs={6} md={4}>
                    {currentCompany.availableDownload ? (
                      <Button onClick={() => handleModal(downloadModalBody(), 'Comenzar Exportación de Empresa')}>
                        Comenzar Exportación
                      </Button>
                    ) : (
                      <p className="mt-1 font-weight-bold text-primary text-uppercase">Comenzado</p>
                    )}
                  </Col>
                </Row>
              }
            />
          </Col>
        </Row>
      )}
      {!isProfile && (
        <Card>
          <Card.Body>
            <Row>
              <Col xs={12} md={9}>
                <Card.Title>Credenciales API</Card.Title>
                <div className="info">
                  <span className="type">API KEY:</span>
                  <span className="line" />
                  <span className="answer"> {apiKey}</span>
                </div>
                <div className="info">
                  <span className="type">SECRET KEY:</span>
                  <span className="line" />
                  <span className="answer"> {showSecretKey ? currentSecretKey : hiddenSecretKey}</span>
                </div>
              </Col>
              <Col sm={5} md={1} className="d-flex align-items-end mb-1">
                <ButtonTooltip
                  onClick={() => setShowSecretKey(!showSecretKey)}
                  variant="circle-primary"
                  text={secretBtnMsg}
                >
                  <Icon icon={secretBtnIcon} />
                </ButtonTooltip>
              </Col>
              <Col sm={7} md={2} className="d-flex align-items-end mb-1">
                <Button
                  variant="warning"
                  className="w-100 mt-1 mt-md-4"
                  onClick={handleNewSecretKeyModal}
                  disabled={onRequest}
                >
                  Nueva secret key
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
      {onRequest && (
        <div className="containerSpinnerLoad">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        modalSize="lg"
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={modalHandleConfirm}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        disabled={onRequest}
        withClose={modalsWithButtons.indexOf(modalTitle) > -1}
        withConfirm={modalsWithButtons.indexOf(modalTitle) > -1}
      />
      <DefaultModal
        title={modalTitleApiCredentials}
        body={modalBodyApiCredentials}
        show={modalShowApiCredentials}
        handleClose={() => setModalShowApiCredentials(false)}
        handleConfirm={handleNewSecretKeyRequest}
        titleBtnClose={closeBtnMsg}
        titleBtnSave="Confirmar"
        withConfirm={withModalConfirm}
        withClose={false}
      />
    </>
  );
};

export default AdvanceSettings;
