import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { SimpleCenteredModal } from '../../../components';
import OvertimeProfileDatatable from './OvertimeProfileDatatable';
import { HROvertimesRequest } from '../../../requests/dashboards';
import OvertimeNew from './OvertimeNew';
import columns from './Columns';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import HeaderCards from './HeaderCards';
import useCreateOvertimeHook from './useCreateOvertimeHook';

const OvertimeIndex = ({ currentEmployee, rrhh = false, moreData, setMoreData }) => {
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [cards, setCards] = useState({});
  const [customParams, setCustomParams] = useState({});
  const [defaultEndDate, setDefaultEndDate] = useState(null);
  const [defaultStartDate, setDefaultStartDate] = useState(null);
  const [nameRangePicker, setNameRangePicker] = useState('');

  const dispatch = useDispatch();

  const handleModalClose = () => {
    setModalShow(false);
  };

  const fetchDashboardCards = () => {
    const request = async () => {
      HROvertimesRequest({
        dispatch,
        successCallback: response => setCards(camelCaseEmptyStringRecursive(response.data))
      });
    };

    if (rrhh) request();
  };

  const { handleCreateOvertime } = useCreateOvertimeHook();

  const handleOpenModal = () => {
    setModalTitle('Solicitar Horas Extra');
    setModalBody(
      <OvertimeNew
        formRequest={(overtime, setSubmitting) => {
          handleCreateOvertime(overtime, setSubmitting, setModalShow, setMoreData);
        }}
        employeeId={currentEmployee}
        rrhh={rrhh}
      />
    );
    setModalShow(true);
  };

  useEffect(fetchDashboardCards, [moreData]);

  return (
    <Row className="mt-2 mb-3">
      <Col>
        {!rrhh && (
          <Row>
            <Col md={{ span: 3, offset: 9 }}>
              <Button variant="primary" className="d-none d-md-block mt-0" block onClick={handleOpenModal}>
                Nuevo
              </Button>
            </Col>
          </Row>
        )}

        {rrhh && (
          <HeaderCards
            cards={cards}
            setMoreData={setMoreData}
            setCustomParams={setCustomParams}
            setNameRangePicker={setNameRangePicker}
            setDefaultStartDate={setDefaultStartDate}
            setDefaultEndDate={setDefaultEndDate}
          />
        )}

        <OvertimeProfileDatatable
          rrhh={rrhh}
          columns={columns}
          moreData={moreData}
          setMoreData={setMoreData}
          currentEmployee={currentEmployee}
          customParams={customParams}
          nameRangePicker={nameRangePicker}
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
        />

        <SimpleCenteredModal title={modalTitle} size="lg" body={modalBody} show={modalShow} onHide={handleModalClose} />
      </Col>
    </Row>
  );
};

export default OvertimeIndex;
