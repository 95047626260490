import React from 'react';
import camelCaseRecursive from 'camelcase-keys-recursive';

const OvertimeShow = props => {
  const { item } = props;
  const {
    date,
    hours,
    minutes,
    translatedOvertimeType,
    bossSupervisor,
    activities,
    rejectionReason
  } = camelCaseRecursive(item);

  return (
    <>
      <div className="info-box">
        <p className="info">
          <span>Fecha Hora Extra:</span> {date}
        </p>

        <p className="info">
          <span>Horas:</span> {hours}
        </p>

        <p className="info">
          <span>Minutos:</span> {minutes}
        </p>

        <p className="info">
          <span>Tipo de Hora Extra:</span> {translatedOvertimeType}
        </p>

        <p className="info">
          <span>Jefe/Supervisor Hora Extra:</span> {bossSupervisor}
        </p>

        <p className="info">
          <span>Actividad:</span> {activities}
        </p>

        <p className="info">
          <span>Motivo Rechazo:</span> {rejectionReason}
        </p>
      </div>
    </>
  );
};

export default OvertimeShow;
