import React from 'react';
import { Modal } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';

const OvertimeInfo = ({ overtime }) => {
  const {
    date,
    hours,
    minutes,
    translatedOvertimeType,
    bossSupervisor,
    activities,
    rejectionReason,
    translatedStatus
  } = camelCaseRecursive(overtime);

  return (
    <Modal.Body>
      <div className="info-box">
        <p className="info">
          <span>Fecha Hora Extra:</span> {date}
        </p>

        <p className="info">
          <span>Horas:</span> {hours}
        </p>

        <p className="info">
          <span>Minutos:</span> {minutes}
        </p>
        <p className="info">
          <span>Tipo de Hora Extra:</span> {translatedOvertimeType}
        </p>

        <p className="info">
          <span>Jefe/Supervisor Hora Extra:</span> {bossSupervisor}
        </p>

        <p className="info">
          <span>Actividad:</span> {activities}
        </p>
        <p className="info">
          <span>Estado:</span> {translatedStatus}
        </p>

        <p className="info">
          <span>Motivo de Rechazo:</span> {rejectionReason}
        </p>
      </div>
    </Modal.Body>
  );
};

export default OvertimeInfo;
