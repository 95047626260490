import { Field } from 'formik';
import React from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { FormikCheckBox, FormikMaterialUiTimePicker } from '../../../components';

const Flexibility = ({ currentTab, disabled, errors, getIn, onChangeTab, setFieldValue, touched, values }) => {
  const { flexibleShift, initialTime, lunchTime, shiftDaysAttributes, workHours } = values.shift;

  return (
    <Card className="overflow-visible">
      <Col className={`${currentTab === 'flexibility' ? 'top-header-green' : 'top-header-light-gray'}`}>
        <Accordion.Toggle
          as={Col}
          eventKey="3"
          className="card-header-title-dt"
          onClick={() => onChangeTab('flexibility')}
        >
          Flexibilidad
        </Accordion.Toggle>
      </Col>
      <Accordion.Collapse eventKey="3">
        <Card.Body className="div-content">
          <Row>
            <Col xs={12} md={4} className="mt-4 d-flex align-items-center">
              <Field name="shift[flexibleShift]">
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    custom
                    field={field}
                    label="Turno flexible"
                    tooltipSize="23"
                    onClick={() => {
                      setFieldValue(field.name, field.value === 'true' || field.value === true);
                      setFieldValue('shift[tolerance]', 0);
                      setFieldValue('shift[parsedTolerance]', 0);
                      setFieldValue('shift[uniqueAllDays]', !field.value);
                      if (field.value !== 'true' || field.value !== true) {
                        setFieldValue('shift[initialTime]', '');
                        setFieldValue('shift[maximumStartTime]', '');
                        setFieldValue('shift[workHours]', '');
                        setFieldValue('shift[agreedSchedule]', false);
                      }
                    }}
                    disabled={disabled}
                  />
                )}
              </Field>
            </Col>
            {flexibleShift && (
              <Col xs={12} md={4} className="mt-4 d-flex align-items-center">
                <Field name="shift[uniqueAllDays]">
                  {({ field }) => (
                    <FormikCheckBox
                      {...field}
                      custom
                      field={field}
                      label="Único para todos los días"
                      tooltipSize="23"
                      onClick={() => {
                        setFieldValue(field.name, field.value === 'true' || field.value === true);
                      }}
                      disabled
                    />
                  )}
                </Field>
              </Col>
            )}
          </Row>
          <Row>
            {flexibleShift && (
              <>
                <Col xs={4} md={2} className="ml-2">
                  <Field name="shift[initialTime]">
                    {({ field }) => (
                      <FormikMaterialUiTimePicker
                        {...field}
                        abbr
                        timeSelector
                        label="Hora de Inicio"
                        onChange={time => {
                          setFieldValue(field.name, time);
                          if (flexibleShift) {
                            shiftDaysAttributes.map((_item, index) =>
                              setFieldValue(`shift[shiftDaysAttributes][${index}][startTime]`, time)
                            );
                            if (workHours) {
                              shiftDaysAttributes.forEach((_item, index) => {
                                const totalWorkDuration = moment.duration(workHours);
                                const collationDuration = moment.duration(lunchTime ?? '0', 'minutes');
                                const totalDurationWithLunch = moment
                                  .duration(totalWorkDuration)
                                  .add(collationDuration);

                                const startTime = moment.utc(time, 'HH:mm');
                                const endTime = moment
                                  .utc(startTime)
                                  .add(totalDurationWithLunch)
                                  .format('HH:mm');
                                setFieldValue(`shift[shiftDaysAttributes][${index}][endTime]`, endTime);
                              });
                            }
                          }
                        }}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={disabled}
                      />
                    )}
                  </Field>
                </Col>
                <Col xs={4} md={2} className="ml-2">
                  <Field name="shift[maximumStartTime]">
                    {({ field }) => (
                      <FormikMaterialUiTimePicker
                        {...field}
                        abbr
                        timeSelector
                        label="Tiempo Max. Inicio"
                        onChange={time => setFieldValue(field.name, time)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={disabled}
                      />
                    )}
                  </Field>
                </Col>
                <Col xs={4} md={2} className="ml-2">
                  <Field name="shift[workHours]">
                    {({ field }) => (
                      <FormikMaterialUiTimePicker
                        {...field}
                        abbr
                        timeSelector
                        label="Horas Trabajo"
                        onChange={time => {
                          setFieldValue(field.name, time);
                          shiftDaysAttributes.forEach((_item, index) => {
                            const totalWorkDuration = moment.duration(time);
                            const collationDuration = moment.duration(lunchTime ?? '0', 'minutes');
                            const totalDurationWithLunch = moment.duration(totalWorkDuration).add(collationDuration);
                            const startTime = moment.utc(initialTime, 'HH:mm');
                            const endTime = moment
                              .utc(startTime)
                              .add(totalDurationWithLunch)
                              .format('HH:mm');
                            setFieldValue(`shift[shiftDaysAttributes][${index}][endTime]`, endTime);
                          });
                        }}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                        disabled={disabled}
                      />
                    )}
                  </Field>
                </Col>
              </>
            )}
          </Row>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};
export default Flexibility;
