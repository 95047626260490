import React from 'react';
import { ComponentDataTable } from '../../../components';
import columns from './Columns';
import MassiveActions from '../SalarySettlement/MassiveActions';

const CerDjDatatable = ({
  handleRequest,
  onRequest,
  searchFirstStart,
  employee,
  amount,
  moreData,
  handleButtonClick,
  sortColumnCase,
  clearSelectedRows,
  handleSelectedRows,
  handleMassAction,
  selectedCount
}) => {
  return (
    <ComponentDataTable
      onRequest={onRequest}
      columns={columns(handleButtonClick)}
      data={employee}
      moreData={moreData}
      handleSortCase={sortColumnCase}
      resourceRequest={handleRequest}
      searchFirstStart={searchFirstStart}
      pointerOnHover
      preName="salarySettlement"
      noDataComponent={<div className="pb-5">No se encontraron datos</div>}
      withMassActions
      massActions={
        <>
          <MassiveActions handleClick={handleMassAction} disabled={selectedCount <= 0} />
        </>
      }
      totalRows={amount}
      selectableRows
      clearSelectedRows={clearSelectedRows}
      onSelectedRowsChange={handleSelectedRows}
      withSearch={false}
    />
  );
};

export default CerDjDatatable;
