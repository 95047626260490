import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

const OvertimeCard = ({
  variant,
  onClick,
  label,
  subtitle,
  title,
  active,
  paddingTitle,
  paddingContent,
  customSize
}) => (
  <Card className={`long-card bl-0 ${active ? 'active-card' : ''} card-${variant}`} onClick={onClick}>
    <div className="d-flex flex-row">
      {active && <span className="active-border" />}
      <Card.Body>
        <div className={`flex-col my-auto ${paddingContent || active ? '' : 'inactive-margin'}`}>
          <Card.Subtitle className="mb-2">{label}</Card.Subtitle>
          <div className="d-flex flex-row">
            <Card.Title className={`mt-md-n2 mt-xl-0 ${customSize}`}>{title}</Card.Title>
            {subtitle && <h4 className={`text-uppercase my-auto ${paddingTitle} font-responsive`}>{subtitle}</h4>}
          </div>
        </div>
      </Card.Body>
    </div>
  </Card>
);

OvertimeCard.propTypes = {
  variant: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subtitle: PropTypes.string,
  paddingTitle: PropTypes.string,
  customSize: PropTypes.string
};

OvertimeCard.defaultProps = {
  title: '',
  label: '',
  subtitle: '',
  variant: 'info',
  paddingTitle: 'pl-2 pb-3',
  customSize: ''
};

export default OvertimeCard;
