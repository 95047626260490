export const resultFetchData = response => {
  const { data } = response.data;
  const tempArray = data.map(element => {
    return {
      label: element.name,
      value: element.id
    };
  });
  return [{ label: 'Todos', value: 'all' }, ...tempArray];
};

export const pairValue = (allOptions, currentValue, isMulti) => {
  if (!currentValue) return null;
  if (isMulti) {
    return currentValue.map(value => {
      const option = allOptions.find(_option => _option.value === value);
      return option || null;
    });
  }
  const option = allOptions.find(_option => _option.value === currentValue);
  return option || null;
};
