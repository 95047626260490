import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Form, InputGroup, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getIn } from 'formik';

import Icon from '../../Icons';
import './style.scss';

const FormikNumber = ({
  abbr,
  delayHide,
  delayShow,
  direction,
  disabled,
  errors,
  fieldName,
  helpText,
  label,
  labelClass,
  leftAddon,
  margin,
  rightAddon,
  setFieldValue,
  tooltipIcon,
  tooltipSize,
  tooltipText,
  touched,
  maxLength,
  ...props
}) => {
  const hasError = getIn(errors, fieldName) && getIn(touched, fieldName);

  const validateValue = inputObj => {
    const { value } = inputObj;

    return maxLength ? value.length <= maxLength : true;
  };
  return (
    <Form.Group className={`${disabled ? 'disabled' : ''} ${margin} ${hasError ? 'is-invalid' : ''}`}>
      {label && (
        <Form.Label className={`d-flex align-items-center ${labelClass}`}>
          {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
          {tooltipText && (
            <ButtonToolbar className="ml-2">
              <OverlayTrigger
                key={direction}
                placement={direction}
                delay={{ show: delayShow, hide: delayHide }}
                overlay={<Tooltip>{tooltipText}</Tooltip>}
              >
                <Icon icon={tooltipIcon} width={tooltipSize} />
              </OverlayTrigger>
            </ButtonToolbar>
          )}
        </Form.Label>
      )}
      <InputGroup>
        {leftAddon && (
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">{leftAddon}</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <NumberFormat
          className={`form-control ${disabled && 'input-disabled'}`}
          disabled={disabled}
          decimalScale={0}
          thousandSeparator="."
          decimalSeparator=","
          allowNegative={false}
          onValueChange={setFieldValue && (values => setFieldValue(fieldName, values?.value))}
          isAllowed={validateValue} // Validación de longitud y rango
          {...props}
        />
        {rightAddon && (
          <InputGroup.Append>
            <InputGroup.Text id="basic-addon2">{rightAddon}</InputGroup.Text>
          </InputGroup.Append>
        )}
      </InputGroup>
      {hasError && <Form.Text className="text-danger">{getIn(errors, fieldName)}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

FormikNumber.propTypes = {
  abbr: PropTypes.bool,
  direction: PropTypes.string,
  disabled: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  leftAddon: PropTypes.string,
  margin: PropTypes.string,
  rightAddon: PropTypes.string,
  tooltipIcon: PropTypes.string,
  tooltipSize: PropTypes.string,
  tooltipText: PropTypes.string,
  maxLength: PropTypes.number
};

FormikNumber.defaultProps = {
  abbr: false,
  direction: 'bottom',
  disabled: false,
  helpText: '',
  label: '',
  labelClass: '',
  leftAddon: '',
  margin: '',
  rightAddon: '',
  tooltipIcon: 'help-circle',
  tooltipSize: '15',
  tooltipText: '',
  maxLength: undefined
};

export default FormikNumber;
