import React from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import ShiftInfo from './ShiftInfo'
import { Icon } from '../../../components';
import { useSetTab } from '../../../services/hooks';
import { AbilityContext } from '../../../config/abilityContext';
import ShiftAssignmentsInfo from '../ShiftAssignments/ShiftAssigmentsInfo';


const ShiftProfileShow = () => {
  const ability = useAbility(AbilityContext);
  const location = useLocation();

  const defaultKey = () => {
    if (ability.can('show', 'Shifts')) return 'shifts';
    if (ability.can('show', 'ShiftAssignments')) return 'shift_assignments';
    return 'shifts';
  };

  const [key, setKey] = useSetTab(defaultKey(), location);

  return (
    <>
      <h2 className="m-top mb-3">&nbsp;</h2>
      <Tab.Container id="profile-index.tabs" activeKey={key} onSelect={k => setKey(k)} mountOnEnter>
        <Nav variant="pills">
          {ability.can('read', 'Shift') && (
            < Nav.Item >
              <Nav.Link className="mb-1" eventKey="shifts">
                <Icon icon="user-check" />
                Mi Turno
              </Nav.Link>
            </Nav.Item>
          )}
          {ability.can('read', 'ShiftAssignment') && (
            <Nav.Item>
              <Nav.Link className="mb-1" eventKey="shift_assignments">
                <Icon icon="calendar" />
                Mi Asignación Turno
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="shifts">
            <ShiftInfo />
          </Tab.Pane>
          <Tab.Pane eventKey="shift_assignments">
            <ShiftAssignmentsInfo />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container >
    </>
  );
};

export default ShiftProfileShow;
