import { Field } from 'formik';
import React from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { FormikMaterialUiTimePicker, Icon } from '../../../components';
import { daysOfWeek } from '../FormOptions';

const WeekDistribution = ({
  currentTab,
  dayCheckBoxValue,
  disabled,
  errors,
  getIn,
  onChangeTab,
  setFieldValue,
  touched,
  values
}) => {
  const { agreedSchedule, flexibleShift, shiftDaysAttributes } = values.shift;

  return (
    <Card className="overflow-visible">
      <Col className={`${currentTab === 'distribution' ? 'top-header-green' : 'top-header-light-gray'}`}>
        <Accordion.Toggle
          as={Col}
          eventKey="4"
          className="card-header-title-dt"
          onClick={() => onChangeTab('distribution')}
        >
          Distribución
        </Accordion.Toggle>
      </Col>
      <Accordion.Collapse eventKey="4">
        <Card.Body className="div-content">
          <p>Haz click en un día para activarlo o desactivarlo en este turno semanal.</p>
          {agreedSchedule ? (
            <Row className="justify-content-center">
              {daysOfWeek.map((item, index) => (
                <Col
                  xs={5}
                  sm={2}
                  lg
                  key={item.value}
                  onClick={e => disabled || dayCheckBoxValue(item.value, index, e)}
                  className={`shift-card-container${shiftDaysAttributes[index].send === true ? '' : '-disabled'}`}
                >
                  <Row>
                    <Col md={12}>
                      {shiftDaysAttributes[index].send && (
                        <Icon
                          className="text-primary"
                          style={{ marginTop: '5px', position: 'absolute', left: '5px' }}
                          width={18}
                          icon="checkmark-circle"
                        />
                      )}
                      <p className="text-uppercase shift-card-title">{item.label}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="align-items-center" md={12}>
                      <p className="text-uppercase shift-card-label">Desde</p>
                      <Field name={`shift[shiftDaysAttributes][${index}][startTime]`}>
                        {({ field }) => (
                          <FormikMaterialUiTimePicker
                            {...field}
                            abbr
                            defaultValue="09:00"
                            onChange={time => setFieldValue(field.name, time)}
                            disabled={!shiftDaysAttributes[index].send || disabled}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col md={12}>
                      <p className="text-uppercase shift-card-label">Hasta</p>
                      <Field name={`shift[shiftDaysAttributes][${index}][endTime]`}>
                        {({ field }) => (
                          <FormikMaterialUiTimePicker
                            {...field}
                            abbr
                            defaultValue="18:00"
                            onChange={time => setFieldValue(field.name, time)}
                            disabled={!shiftDaysAttributes[index].send || disabled}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p className="text-uppercase shift-card-title">Colación</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="align-items-center" md={12}>
                      <p className="text-uppercase shift-card-label">Desde</p>
                      <Field name={`shift[shiftDaysAttributes][${index}][startLunch]`}>
                        {({ field }) => (
                          <FormikMaterialUiTimePicker
                            {...field}
                            abbr
                            defaultValue="14:00"
                            onChange={time => setFieldValue(field.name, time)}
                            disabled={!shiftDaysAttributes[index].send || disabled}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col md={12}>
                      <p className="text-uppercase shift-card-label">Hasta</p>
                      <Field name={`shift[shiftDaysAttributes][${index}][endLunch]`}>
                        {({ field }) => (
                          <FormikMaterialUiTimePicker
                            {...field}
                            abbr
                            defaultValue="15:00"
                            onChange={time => setFieldValue(field.name, time)}
                            disabled={!shiftDaysAttributes[index].send || disabled}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          ) : (
            <Row className="justify-content-center">
              {daysOfWeek.map((item, index) => (
                <Col
                  xs={5}
                  sm={2}
                  lg
                  key={item.value}
                  onClick={e => disabled || dayCheckBoxValue(item.value, index, e)}
                  className={`shift-card-container${shiftDaysAttributes[index].send === true ? '' : '-disabled'}`}
                >
                  <Row>
                    <Col md={12}>
                      {shiftDaysAttributes[index].send && (
                        <Icon
                          className="text-primary"
                          style={{ marginTop: '5px', position: 'absolute', left: '5px' }}
                          width={18}
                          icon="checkmark-circle"
                        />
                      )}
                      <p className="text-uppercase shift-card-title">{item.label}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3 align-items-center" md={12}>
                      <p className="text-uppercase shift-card-label">Desde</p>
                      <Field name={`shift[shiftDaysAttributes][${index}][startTime]`}>
                        {({ field }) => (
                          <FormikMaterialUiTimePicker
                            {...field}
                            abbr
                            defaultValue="09:00"
                            onChange={time => setFieldValue(field.name, time)}
                            disabled={!shiftDaysAttributes[index].send || flexibleShift || disabled}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3" md={12}>
                      <p className="text-uppercase shift-card-label">Hasta</p>
                      <Field name={`shift[shiftDaysAttributes][${index}][endTime]`}>
                        {({ field }) => (
                          <FormikMaterialUiTimePicker
                            {...field}
                            abbr
                            defaultValue="18:00"
                            onChange={time => setFieldValue(field.name, time)}
                            disabled={!shiftDaysAttributes[index].send || flexibleShift || disabled}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};
export default WeekDistribution;
