import React, { useEffect } from 'react';
import { useAbility } from '@casl/react';
import { useSelector } from 'react-redux';
import { Badge, Card, Col, Nav, Tab } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import { useLocation } from 'react-router-dom';
import { AbilityContext } from '../../../config/abilityContext';
import Icon from '../../Icons';
import VacationDataTableStyled from '../../../screens/Shared/Vacation/VacationDataTableStyled';
import PermissionDataTableStyled from '../../../screens/Shared/Permission/PermissionDataTableStyled';
import LoanDataTableStyled from '../../../screens/Shared/Loan/LoanDataTableStyled';
import AdvancePaymentDataTableStyled from '../../../screens/Shared/AdvancePayment/AdvancePaymentDataTableStyled';
import { useSetTab } from '../../../services/hooks';
import ShiftChangeDataTableStyled from '../../../screens/Shared/ShiftChange/ShitfChangeDataTableStyled';
import OvertimeProfileDatatableStyled from '../../../screens/Profile/Overtime/OvertimeProfileDatatableStyled';

const MyTeamRequests = ({ cards, currentEmployee, employeesBelongBoss, moreData, setMoreData }) => {
  const ability = useAbility(AbilityContext);
  const { advanceSettings } = useSelector(state => state.utils);
  const location = useLocation();

  const requestsAbility = [
    ability.can('index', 'AdvancePayment'),
    ability.can('index', 'Permission'),
    ability.can('index', 'Vacation'),
    ability.can('index', 'Loan'),
    ability.can('index', 'ShiftChangeRequest'),
    ability.can('index', 'Overtime')
  ];

  const vacationsTab = advanceSettings
    .filter(asItem => ['legal_holiday', 'progressive_holiday', 'additional_days'].includes(asItem.code))
    .some(x => x.textValue !== undefined);

  const loanTab = advanceSettings.filter(asItem => asItem.code === 'loan_request').some(x => x.textValue !== undefined);

  const permissionsTab = advanceSettings
    .filter(asItem => ['paid_leave', 'without_paid_leave', 'administrative_days'].includes(asItem.code))
    .some(x => x.textValue !== undefined);

  const administrativeTab = advanceSettings
    .filter(asItem => ['administrative_days'].includes(asItem.code))
    .some(x => x.textValue !== undefined);

  const shiftChangeTab = advanceSettings
    .filter(asItem => asItem.code === 'shift_change')
    .some(x => x.textValue !== undefined);

  const advancePaymentsTab = advanceSettings
    .filter(asItem => asItem.code === 'advance_payment_request')
    .some(x => x.textValue !== undefined);

  const overtimeTab = advanceSettings
    .filter(asItem => asItem.code === 'overtime_requests')
    .some(x => x.textValue === 'true');

  const defaultKey = () => {
    if (vacationsTab) return 'vacations';
    if (administrativeTab) return 'administrative_days';
    if (permissionsTab) return 'permissions';
    if (loanTab) return 'loans';
    if (advancePaymentsTab) return 'advance_payments';
    if (shiftChangeTab) return 'shift_change';
    if (overtimeTab) return 'overtime';
    return 'no-tabs';
  };

  const checkTab = () => {
    const tab = new URLSearchParams(location.search).get('tab');
    if (tab === 'vacations' && vacationsTab) return 'vacations';
    if (tab === 'administrative_days' && administrativeTab) return 'administrative_days';
    if (tab === 'permissions' && permissionsTab) return 'permissions';
    if (tab === 'loans' && loanTab) return 'loans';
    if (tab === 'advance_payments' && advancePaymentsTab) return 'advance_payments';
    if (tab === 'shift_change' && shiftChangeTab) return 'shift_change';
    if (tab === 'overtime' && overtimeTab) return 'overtime';
    return defaultKey();
  };

  const setDatatable = tabKey => {
    switch (tabKey) {
      case 'vacations':
        return (
          <Col>
            <VacationDataTableStyled
              currentEmployee={currentEmployee}
              defaultStatus="0"
              employeesBelongBoss={employeesBelongBoss}
              moreData={moreData}
              setMoreData={setMoreData}
              preName="vacations"
            />
          </Col>
        );
      case 'administrative-days':
        return (
          <VacationDataTableStyled
            currentEmployee={currentEmployee}
            defaultStatus="0"
            employeesBelongBoss={employeesBelongBoss}
            moreData={moreData}
            setMoreData={setMoreData}
            preName="administrative_days"
          />
        );
      case 'permissions':
        return (
          <PermissionDataTableStyled
            currentEmployee={currentEmployee}
            employeesBelongBoss={employeesBelongBoss}
            moreData={moreData}
            setMoreData={setMoreData}
            preName="pending-permissions"
          />
        );
      case 'loans':
        return (
          <LoanDataTableStyled
            currentEmployee={currentEmployee}
            employeesBelongBoss={employeesBelongBoss}
            moreData={moreData}
            setMoreData={setMoreData}
            preName="loans"
          />
        );
      case 'advance-payments':
        return (
          <AdvancePaymentDataTableStyled
            currentEmployee={currentEmployee}
            employeesBelongBoss={employeesBelongBoss}
            moreData={moreData}
            setMoreData={setMoreData}
            preName="advance_payments"
          />
        );
      case 'shift-change':
        return (
          <ShiftChangeDataTableStyled
            currentEmployee={currentEmployee}
            employeesBelongBoss={employeesBelongBoss}
            moreData={moreData}
            setMoreData={setMoreData}
            preName="shift_change"
          />
        );
      case 'overtime':
        return (
          <OvertimeProfileDatatableStyled
            currentEmployee={currentEmployee}
            employeesBelongBoss={employeesBelongBoss}
            moreData={moreData}
            setMoreData={setMoreData}
            preName="overtime"
          />
        );
      default:
        return '';
    }
  };

  const [key, setKey] = useSetTab(defaultKey(), location);

  const updateDefaultKey = () => setKey(checkTab());

  useEffect(updateDefaultKey, [advanceSettings]);

  return (
    <>
      <Card className="mb-1">
        <Col className="top-header-green">
          <Col className="card-header-title">GESTION DE SOLICITUDES</Col>
        </Col>
        <Col className="div-content mt-2 mb-2">
          <Tab.Container
            className="ml-3"
            id="profile-index.tabs"
            activeKey={key}
            onSelect={k => setKey(k)}
            mountOnEnter
          >
            <Nav variant="pills">
              {vacationsTab && requestsAbility[2] && (
                <Nav.Item>
                  <Nav.Link className="mb-1" eventKey="vacations">
                    {cards?.cardBoss?.vacationsCount > 0 && (
                      <Badge
                        variant="danger"
                        className="badge-circle"
                        style={{ marginTop: '-10px', marginLeft: '110px' }}
                      >
                        {cards.cardBoss.vacationsCount}
                      </Badge>
                    )}
                    <Icon icon="sunny" />
                    Vacaciones
                  </Nav.Link>
                </Nav.Item>
              )}
              {administrativeTab && requestsAbility[1] && (
                <Nav.Item>
                  <Nav.Link className="mb-1" eventKey="administrative_days">
                    {cards?.cardBoss?.administrativeCount > 0 && (
                      <Badge
                        variant="danger"
                        className="badge-circle"
                        style={{ marginTop: '-10px', marginLeft: '105px' }}
                      >
                        {cards.cardBoss.administrativeCount}
                      </Badge>
                    )}
                    <Icon icon="calendar" />
                    Días Admin.
                  </Nav.Link>
                </Nav.Item>
              )}
              {permissionsTab && requestsAbility[1] && (
                <Nav.Item>
                  <Nav.Link className="mb-1" eventKey="permissions">
                    {cards?.cardBoss?.permissionsCount > 0 && (
                      <Badge
                        variant="danger"
                        className="badge-circle"
                        style={{ marginTop: '-10px', marginLeft: '87px' }}
                      >
                        {cards.cardBoss.permissionsCount}
                      </Badge>
                    )}
                    <Icon icon="clock2" />
                    Permisos
                  </Nav.Link>
                </Nav.Item>
              )}
              {loanTab && requestsAbility[3] && (
                <Nav.Item>
                  <Nav.Link className="mb-1" eventKey="loans">
                    {cards?.cardBoss?.loansCount > 0 && (
                      <Badge
                        variant="danger"
                        className="badge-circle"
                        style={{ marginTop: '-10px', marginLeft: '98px' }}
                      >
                        {cards.cardBoss.loansCount}
                      </Badge>
                    )}
                    <Icon icon="wallet" />
                    Préstamos
                  </Nav.Link>
                </Nav.Item>
              )}
              {advancePaymentsTab && requestsAbility[0] && (
                <Nav.Item>
                  <Nav.Link className="mb-1" eventKey="advance_payments">
                    {cards?.cardBoss?.advancePaymentsCount > 0 && (
                      <Badge
                        variant="danger"
                        className="badge-circle"
                        style={{ marginTop: '-10px', marginLeft: '93px' }}
                      >
                        {cards.cardBoss.advancePaymentsCount}
                      </Badge>
                    )}
                    <Icon icon="user-check" />
                    Anticipos
                  </Nav.Link>
                </Nav.Item>
              )}
              {shiftChangeTab && requestsAbility[4] && (
                <Nav.Item>
                  <Nav.Link className="mb-1" eventKey="shift_change">
                    {cards?.cardBoss?.shiftChangeCount > 0 && (
                      <Badge
                        variant="danger"
                        className="badge-circle"
                        style={{ marginTop: '-10px', marginLeft: '147px' }}
                      >
                        {cards.cardBoss.shiftChangeCount}
                      </Badge>
                    )}
                    <Icon icon="pencil" />
                    Cambio de Turno
                  </Nav.Link>
                </Nav.Item>
              )}
              {overtimeTab && requestsAbility[5] && (
                <Nav.Item>
                  <Nav.Link className="mb-1" eventKey="overtime">
                    {cards?.cardBoss?.overtimeRequestCount > 0 && (
                      <Badge
                        variant="danger"
                        className="badge-circle"
                        style={{ marginTop: '-10px', marginLeft: '210px' }}
                      >
                        {cards.cardBoss.overtimeRequestCount}
                      </Badge>
                    )}
                    <Icon icon="clock2" />
                    Solicitud de Horas Extras
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="vacations">
                {vacationsTab && requestsAbility[2] && key === 'vacations' && setDatatable('vacations')}
              </Tab.Pane>
              <Tab.Pane eventKey="administrative_days">
                {administrativeTab &&
                  requestsAbility[1] &&
                  key === 'administrative_days' &&
                  setDatatable('administrative-days')}
              </Tab.Pane>
              <Tab.Pane eventKey="permissions">
                {permissionsTab && requestsAbility[1] && key === 'permissions' && setDatatable('permissions')}
              </Tab.Pane>
              <Tab.Pane eventKey="loans">
                {loanTab && requestsAbility[3] && key === 'loans' && setDatatable('loans')}
              </Tab.Pane>
              <Tab.Pane eventKey="advance_payments">
                {advancePaymentsTab &&
                  requestsAbility[0] &&
                  key === 'advance_payments' &&
                  setDatatable('advance-payments')}
              </Tab.Pane>
              <Tab.Pane eventKey="shift_change">
                {shiftChangeTab && requestsAbility[4] && key === 'shift_change' && setDatatable('shift-change')}
              </Tab.Pane>
              <Tab.Pane eventKey="overtime">
                {overtimeTab && requestsAbility[5] && key === 'overtime' && setDatatable('overtime')}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Card>
    </>
  );
};

export default MyTeamRequests;
