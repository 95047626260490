import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexShiftsAssignmentRequest = ({ dispatch, params = {}, failureCallback, successCallback }) => {
  ApiService.request('get', '/shift_assignments', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

};

export const createShiftAssignmentsRequest = ({ dispatch, params, formData, callback, successCallback }) =>
  ApiService.request('post', '/shift_assignments', {
    dispatch,
    params,
    formData,
    callback,
    successCallback
  });

export const createMassiveShiftAssignmentsRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/shift_assignments/massive_create', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const deleteShiftAssignmentRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/shift_assignments/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const massiveDestroyShiftsAssignmentsRequest = ({ dispatch, params, failureCallback, successCallback, callback }) =>
  ApiService.request('delete', '/shift_assignments/massive_destroy', {
    dispatch,
    params,
    formData: false,
    failureCallback,
    successCallback,
    callback
  });

export const exportShiftAssignmentsRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback }) => {
  ApiService.request('get', `/shift_assignments/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    failureCallback,
    successCallback,
    callback
  });
};
export const debounceIndexShiftsAssignmentRequest = AwesomeDebouncePromise(indexShiftsAssignmentRequest, 300);