import { Field, Form, getIn, withFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { Button, Col, Row, Table, Image } from 'react-bootstrap';
import moment from 'moment';
import {
  FormikNumber,
  FormikSelect,
  FormikTimePicker,
  Icon,
  LinkBtn,
  NestedAttributesTable
} from '../../../components';

const PayrollBalanceInput = ({ index, balance, errors, touched, setFieldTouched, payrollBalances, setFieldValue }) => {
  const periodicityOptions = [
    { value: 'permanent', label: 'Permanente' },
    { value: 'fixed_term', label: 'Plazo fijo' }
  ];

  return (
    <>
      <td className="text-center align-middle mt-3" style={{ maxWidth: '60px' }}>
        <Field name={`payroll[payrollBalances][${index}][balance][value]`}>
          {({ field }) => (
            <FormikSelect
              {...field}
              placeholder="Seleccionar concepto"
              options={balance}
              defaultValue={payrollBalances?.balance?.value}
              onChange={data => {
                setFieldValue(field.name, data ? data.value : '');
                setFieldValue(`payroll[payrollBalances][${index}][balance]`, data || {});
                setFieldValue(`payroll[payrollBalances][${index}][name]`, data.name || '');
              }}
              setFieldTouched={() => setFieldTouched(field.name)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </td>
      <td className="text-center align-middle mt-3" style={{ maxWidth: '60px' }}>
        <Field name={`payroll[payrollBalances][${index}][parsedAmount]`}>
          {({ field }) => (
            <FormikNumber
              {...field}
              leftAddon="$"
              value={payrollBalances.amount}
              fieldName={`payroll[payrollBalances][${index}][amount]`}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
            />
          )}
        </Field>
      </td>
      <td className="text-center align-middle mt-3" style={{ maxWidth: '60px' }}>
        <Field name={`payroll[payrollBalances][${index}][periodicity]`}>
          {({ field }) => (
            <FormikSelect
              {...field}
              placeholder="Seleccionar periodo"
              options={periodicityOptions}
              defaultValue={payrollBalances.periodicity}
              onChange={data => setFieldValue(field.name, data ? data.value : '')}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </td>
      <td className="text-center align-middle" style={{ maxWidth: '60px' }}>
        <Field name={`payroll[payrollBalances][${index}][parsedStartDate]`}>
          {({ field }) => (
            <FormikTimePicker
              {...field}
              setLocale
              placeholder="mm/aaaa"
              dateFormat="MM/yyyy"
              showMonthYearPicker
              onChange={date => {
                const startDate = moment(date);
                setFieldTouched(field.name);
                setFieldValue(field.name, startDate.format('MM/YYYY'));
                setFieldValue(`payroll[payrollBalances][${index}][startDate]`, startDate.format('YYYY-MM-DD'));
              }}
              error={getIn(errors, `payroll[payrollBalances][${index}][startDate]`)}
              touched={getIn(touched, `payroll[payrollBalances][${index}][startDate]`)}
            />
          )}
        </Field>
      </td>
      <td className="text-center align-middle" style={{ maxWidth: '60px' }}>
        <Field name={`payroll[payrollBalances][${index}][parsedEndDate]`}>
          {({ field }) => (
            <FormikTimePicker
              {...field}
              setLocale
              placeholder="mm/aaaa"
              dateFormat="MM/yyyy"
              showMonthYearPicker
              onChange={date => {
                const endDate = moment(date);
                setFieldTouched(field.name);
                setFieldValue(field.name, endDate.format('MM/YYYY'));
                setFieldValue(`payroll[payrollBalances][${index}][endDate]`, endDate.format('YYYY-MM-DD'));
              }}
              disabled={payrollBalances.periodicity === 'permanent'}
              error={getIn(errors, `payroll[payrollBalances][${index}][endDate]`)}
              touched={getIn(touched, `payroll[payrollBalances][${index}][endDate]`)}
            />
          )}
        </Field>
      </td>
    </>
  );
};

const PayrollBalancesForm = ({
  errors,
  setFieldValue,
  setFieldTouched,
  touched,
  values,
  balance,
  payroll,
  payrollProcess,
  isSubmitting
}) => {
  const { payrollBalances } = values.payroll;
  const { employeeImage } = payroll;

  const addPayrollBalances = () => {
    const mapResults = payrollBalances.map((body, index) => {
      if (body._destroy) {
        return undefined;
      }

      return (
        <PayrollBalanceInput
          key={`payroll-${index.toString()}`}
          errors={errors}
          touched={touched}
          index={index}
          balance={balance}
          payrollBalances={body}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
      );
    });

    mapResults.push(
      <>
        <td className="text-center align-middle" style={{ maxWidth: '30px' }}>
          <FormikSelect isDisabled placeholder="Seleccionar concepto" />
        </td>
        <td className="text-center align-middle" style={{ maxWidth: '30px' }}>
          <FormikNumber leftAddon="$" disabled fieldName="" />
        </td>
        <td className="text-center align-middle" style={{ maxWidth: '20px' }}>
          <FormikSelect isDisabled placeholder="Seleccionar periodo" />
        </td>
        <td className="text-center align-middle" style={{ maxWidth: '20px' }}>
          <FormikTimePicker disabled placeholder="mm/aaaa" />
        </td>
        <td className="text-center align-middle" style={{ maxWidth: '20px' }}>
          <FormikTimePicker disabled placeholder="mm/aaaa" />
        </td>
      </>
    );

    return (
      <>
        <NestedAttributesTable
          removeFirstItem
          mapInputs={mapResults}
          arrayValues={payrollBalances}
          setFieldValue={setFieldValue}
          valuePath="payroll[payrollBalances]"
          tdClass="text-center"
          maxRow={20}
          newAttributes={{
            name: '',
            parsedAmount: '',
            amount: '',
            periodicity: '',
            startDate: '',
            endDate: '',
            balance: { value: '' }
          }}
        />
      </>
    );
  };

  return (
    <>
      <Form>
        <Row className="mt-4 mb-4 align-items-center">
          <Col xs={1}>
            <LinkBtn
              variant="circle-dark"
              className="btn-circle mt-3"
              block
              to={{
                pathname: `/remuneration_processes/${payrollProcess.id}/edit`,
                state: { payrollProcess }
              }}
            >
              <Icon icon="chevron-back" />
            </LinkBtn>
          </Col>
          <Col>
            <h2 className="text-uppercase mb-0 mt-3">Editar Liquidación</h2>
          </Col>
          <Col md={{ span: 2, offset: 8 }}>
            <Button variant="primary" block type="submit" disabled={isSubmitting}>
              Guardar
            </Button>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={5}>
            <Row className="order-md-2">
              <Col md={4} className="text-md-right border-right">
                {employeeImage && employeeImage.fileUrl ? (
                  <Image src={employeeImage?.fileUrl} width="80px" height="80px" fluid />
                ) : (
                  <Icon icon="camera" width="80px" height="80px" />
                )}
              </Col>
              <Col md={8}>
                <h3>{payroll?.fullName}</h3>
                <p>{payroll?.payrollInfo?.jobTitleName}</p>
                <p>{payroll?.rut}</p>
              </Col>
            </Row>
          </Col>
          <Col md={5}>
            <Row className="border-left">
              <Col className="ml-3 ">
                <h3>Colaborador Activo</h3>
                <p>Días trabajados: {payroll?.payrollInfo?.workedDays}</p>
                <p>Días Ausencia: {payroll?.payrollInfo?.licenseDays}</p>
                <p>Contrato {payroll?.payrollInfo?.contractType}</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Table responsive>
          <thead>
            <tr>
              <th className="text-center align-middle" style={{ maxWidth: '30px' }}>
                CONCEPTO
              </th>
              <th className="text-center align-middle" style={{ maxWidth: '30px' }}>
                MONTO
              </th>
              <th className="text-center align-middle" style={{ maxWidth: '20px' }}>
                PERIODO
              </th>
              <th className="text-center align-middle" style={{ maxWidth: '20px' }}>
                INICIO
              </th>
              <th className="text-center align-middle" style={{ maxWidth: '20px' }}>
                TERMINO
              </th>
              <th className="text-center align-middle" style={{ maxWidth: '20px' }}>
                ACCIONES
              </th>
            </tr>
          </thead>
          <tbody>{addPayrollBalances()}</tbody>
        </Table>
      </Form>
    </>
  );
};

const setInitialValues = ({ payroll }) => {
  const { payrollBalances } = payroll;
  return {
    payroll: {
      payrollBalances
    }
  };
};

const validationSchema = Yup.object().shape({
  payroll: Yup.object().shape({
    payrollBalances: Yup.array().of(
      Yup.object().shape({
        balance: Yup.object().shape({
          value: Yup.string().required('Debes ingresar un concepto')
        }),
        amount: Yup.number()
          .positive('Debe ser mayor a 0')
          .required('Debes ingresar un monto'),
        periodicity: Yup.string().required('Debes ingresar un periodo'),
        startDate: Yup.string()
          .required('Debes seleccionar una fecha de inicio.')
          .when('periodicity', {
            is: val => val !== 'permanent',
            then: Yup.string().test(
              'startDate',
              'La fecha de inicio debe ser menor o igual a la fecha de fin',
              function valid(value) {
                const { endDate } = this.parent;
                if (endDate) {
                  return !moment(new Date(endDate)).isBefore(new Date(value));
                }
                return true;
              }
            )
          }),
        endDate: Yup.string()
          .when('periodicity', {
            is: val => val !== 'permanent',
            then: Yup.string('Debes seleccionar una fecha fin').required('Debes seleccionar una fecha fin')
          })
          .nullable(true)
      })
    )
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: false,
  validateOnMount: props => props.action !== 'new'
})(PayrollBalancesForm);
