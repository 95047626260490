import React from 'react';
import { ButtonTooltip, Icon } from '../../components';

export default function DataProcessActions({ ability, item, clickHandler }) {
  return (
    <>
      {item.status === 'active' && (
        <>
          <ButtonTooltip
            variant="circle-primary"
            className="btn-circle"
            onClick={() => clickHandler(item, 'show')}
            text="Ver detalles"
          >
            <Icon icon="eye" />
          </ButtonTooltip>
          {ability.can('update', 'AdvancePaymentProcess') && (
            <ButtonTooltip
              variant="circle-warning"
              className="btn-circle"
              onClick={() => clickHandler(item, 'edit')}
              text="Editar"
              disabled={item.source_type === 'Solicitudes'}
            >
              <Icon icon="pencil" />
            </ButtonTooltip>
          )}
          {ability.can('destroy', 'AdvancePaymentProcess') && (
            <ButtonTooltip
              variant="circle-danger"
              className="btn-circle"
              onClick={() => clickHandler(item, 'destroy')}
              text="Eliminar"
            >
              <Icon icon="trash" />
            </ButtonTooltip>
          )}
        </>
      )}
      {item.status === 'inactive' && ability.can('destroy', 'AdvancePaymentProcess') && (
        <ButtonTooltip
          variant="circle-success"
          className="btn-circle"
          onClick={() => clickHandler(item, 'activate')}
          text="Activar"
        >
          <Icon icon="checkmark-circle" />
        </ButtonTooltip>
      )}
    </>
  );
}
