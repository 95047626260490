import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useAbility } from "@casl/react";
import { useDispatch, useSelector } from "react-redux";
import { ComponentDataTable, SimpleCenteredModal } from "../../../components";
import { AbilityContext } from "../../../config/abilityContext";
import columns from "./Columns";
import { debounceIndexShiftsAssignmentRequest } from "../../../requests/shiftAssignment";
import ShiftInfo from "../Shift/ShiftInfo";

const ShiftAssignmentsInfo = () => {
  const dispatch = useDispatch();
  const ability = useAbility(AbilityContext);
  const { currentEmployee } = useSelector(state => state.auth);
  const [onRequest, setOnRequest] = useState(false);
  const [amount, setAmount] = useState(0);
  const [shiftAssignments, setShiftAssignments] = useState([]);
  const [modalBody, setModalBody] = useState('');
  const [centerModalShow, setCenterModalShow] = useState(false);

  const handleModalClose = () => {
    setCenterModalShow(false);
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        setModalBody(<ShiftInfo customId={item.assigned_shift_id} />);
        setCenterModalShow(true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    const request = async () =>
      debounceIndexShiftsAssignmentRequest({
        dispatch,
        params: {
          display_length: 15,
          filter_employee_ids: currentEmployee,
          filter_status: 'pending',
          ...params
        },
        successCallback: response => {
          const { data } = response.data;
          setOnRequest(false);
          setAmount(response.data.metadata.amount);
          setShiftAssignments(data);
        }
      });
    request();
  }

  return (
    <>
      <Row className="mt-4 mb-5">
        <Col md={12}>
          <ComponentDataTable
            columns={columns(handleActions, ability)}
            data={shiftAssignments}
            onRequest={onRequest}
            preName="shift_assignments"
            rangePicker
            resourceRequest={handleIndexRequest}
            totalRows={amount}
            withStartDate
            withEndDate
          />
          <SimpleCenteredModal
            size="xl"
            body={modalBody}
            show={centerModalShow}
            onHide={handleModalClose}
          />
        </Col>
      </Row>
    </>
  );
}
export default ShiftAssignmentsInfo;