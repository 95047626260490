import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import snakecaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';
import PayrollBalancesForm from './PayrollBalancesForm';
import { updatePayrollBalancesRequest } from '../../../requests/payrollBalances';
import { debounceIndexBalancesRequest } from '../../../requests/balances';
import { showPayroll } from '../../../requests/payrolls';
import { DefaultModal } from '../../../components';
import BodyModalPayroll from './BodyModalPayroll';
import { sendAlert } from '../../../actions/utils';

const PayrollBalancesEdit = props => {
  const { match } = props;
  const payrollId = match.params.id;
  const location = useLocation();
  const { payrollProcess } = location.state;
  const [balance, setBalance] = useState([]);
  const [payroll, setPayroll] = useState({});
  const [modalBody, setModalBody] = useState(<></>);
  const [showModal, setShowModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [title, setTitle] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  const handleConfirmSuccess = () => {
    setShowModal(false);
    dispatch(sendAlert({ kind: 'success', message: 'Los conceptos se han actualizado exitosamente.' }));
    history.push(`/remuneration_processes/${payrollProcess.id}/edit`, { payrollProcess });
  };

  const handleUpdateBalances = (values, setSubmitting) => {
    const {
      payroll: { payrollBalances }
    } = values;
    const updatevalues = payrollBalances.map(item => {
      return { ...item, payroll_id: payrollId };
    });

    updatePayrollBalancesRequest({
      dispatch,
      params: { payroll_balance: snakecaseKeys(updatevalues) },
      successCallback: response => {
        if (response?.data?.message !== undefined) {
          const bodyMessages = (
            <>
              <p>
                Las fechas de inicio son superiores a la fecha del proceso, por lo que los conceptos ya no serán
                incluidos nómina actual.
              </p>
              <br />
              <p>Los conceptos afectados son:</p>
            </>
          );
          setTitle('Los conceptos se han actualizado exitosamente.');
          setSuccess(true);
          setModalBody(<BodyModalPayroll messages={response.data.message} bodyMessages={bodyMessages} />);
          setShowModal(true);
        } else {
          dispatch(sendAlert({ kind: 'success', message: 'Los conceptos se han actualizado exitosamente.' }));
          history.push(`/remuneration_processes/${payrollProcess.id}/edit`, { payrollProcess });
        }
      },
      failureCallback: errors => {
        const balances = errors?.response?.data?.message;
        const empty = errors?.response?.data?.empty;
        let text = '';
        let bodyMessages = <p />;
        if (balances) {
          if (balances.length === 1) {
            text = `El concepto ${balances?.join(', ')} ya existe en esta nómina.`;
          } else {
            text = `Los conceptos ${balances?.join(', ')} ya existe en esta nómina.`;
          }
          bodyMessages = <p>{text}</p>;
          setTitle('ERROR: Conceptos repetidos');
        }
        if (empty) {
          bodyMessages = <p>{empty}</p>;
          setTitle('ERROR: Concepto es requerido');
        }
        setSuccess(false);
        setModalBody(<BodyModalPayroll bodyMessages={bodyMessages} />);
        setShowModal(true);
      },
      callback: () => setSubmitting(false)
    });
  };

  const fetchBalances = () => {
    debounceIndexBalancesRequest({
      dispatch,
      params: {
        sort_column: 'name',
        payroll: true,
        available_on_payroll: true,
        show_payroll: true,
        paginate: false,
        filter_contractual: false
      },
      successCallback: response => {
        setBalance(response.data.data);
      }
    });
  };

  const fetchPayroll = () => {
    showPayroll(payrollId, {
      dispatch,
      successCallback: response => {
        setPayroll(response.data);
      }
    });
  };

  useEffect(fetchBalances, []);
  useEffect(fetchPayroll, []);

  return (
    <>
      {Object.keys(payroll).length > 0 && (
        <PayrollBalancesForm
          formRequest={handleUpdateBalances}
          payroll={camelCaseRecursive(payroll)}
          balance={balance}
          payrollProcess={payrollProcess}
        />
      )}

      <DefaultModal
        title={title}
        body={modalBody}
        show={showModal}
        titleBtnSave="Cerrar"
        variantBtnSave="light"
        handleConfirm={() => (success ? handleConfirmSuccess() : setShowModal(false))}
        withClose={false}
        closeButton={false}
        backdrop="static"
        handleClose={() => (success ? handleConfirmSuccess() : setShowModal(false))}
      />
    </>
  );
};

export default PayrollBalancesEdit;
