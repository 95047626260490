const orientations = [
  { label: 'Horizontal/Landscape', value: 'landscape' },
  { label: 'Vertical/Portrait', value: 'portrait' }
];

const sizes = [
  { label: 'A4', value: 'a4' },
  { label: 'Carta', value: 'letter' },
  { label: 'Oficio', value: 'legal' }
];

const templateTypes = [
  { label: 'Anexo de Contrato', value: 'contract_annex' },
  { label: 'Anticipo', value: 'payment_advance' },
  { label: 'Carta Aviso Término de Contrato', value: 'termination_contract_notice_letter' },
  { label: 'Carta de Amonestación', value: 'reprimand_document' },
  { label: 'Carta de Felicitación', value: 'congratulation_letter' },
  { label: 'Certificado', value: 'certificate' },
  { label: 'Contrato de Trabajo', value: 'work_contract' },
  { label: 'Documento de Préstamo', value: 'loan_document' },
  { label: 'Finiquito', value: 'work_settlement' },
  // { label: 'Liquidación', value: 'salary_settlement' },
  { label: 'Otros Documentos', value: 'other_documents' },
  { label: 'Carta de Aceptación', value: 'selection_process_acceptance_notice' },
  { label: 'Carta de Rechazo', value: 'selection_process_rejection_notice' },
  { label: 'Certificado Permiso con Goce de Sueldo', value: 'paid_leave_certificate' },
  { label: 'Certificado Permiso sin Goce de Sueldo', value: 'no_paid_leave_certificate' },
  { label: 'Certificado de Vacaciones', value: 'vacation_certificate' },
  { label: 'Certificado de Préstamo', value: 'loan_certificate' },
  { label: 'Certificado de Anticipo', value: 'advance_payment_certificate' },
  { label: 'Certificado de Días Administrativos', value: 'administrative_days_certificate' },
  { label: 'Certificado de Horas Extras', value: 'overtime_certificate' },
  { label: 'Reglamento interno de orden, higiene o seguridad', value: 'order_hygiene_safety' },
  { label: 'Certificado constitución Comité - Dirección del Trabajo', value: 'labor_direction' },
  { label: 'Plan de emergencia y Evacuación', value: 'emergency_evaluation' },
  { label: 'Carta conductora riohs - Recepción inspección del trabajo', value: 'riohs_labor_inspection' },
  { label: 'Carta conductora riohs - Recepción seremi de salud', value: 'riohs_seremi' },
  { label: 'Lista de trabajadores votantes de la empresa', value: 'voting_workers' },
  { label: 'Acta de constitución comité de higiene y seguridad / Faena', value: 'committee_hygiene_safety' },
  { label: 'Declaración individual de accidentes de trabajo', value: 'work_accidents' },
  {
    label: 'Acta de elección de representante de trabajadores del comité de higiene y seguridad / Faena',
    value: 'election_representatives_hygiene_safety'
  },
  { label: 'Acta de reunión comité de higiene y seguridad (CPHS)', value: 'meeting_hygiene_safety' },
  { label: 'Entrega de elementos de protección personal', value: 'personal_protection' },
  { label: 'Obligación de informar los riesgos laborales', value: 'report_occupational_hazards' },
  { label: 'Registro de aceptación del RIOHS', value: 'acceptance_riohs' },
  { label: 'Inducción en prevención de riesgos', value: 'induction_risk_prevention' },
  { label: 'Pactos de horas extras', value: 'overtime_agreements' }
];

export { orientations, sizes, templateTypes };
