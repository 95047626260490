import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import {
  debounceIndexOvertimeRequest,
  deleteOvertimeRequest,
  rejectOvertimeRequest,
  approveOvertimeRequest,
  indexOvertimeRequest
} from '../../requests/overtimes';
import { sendAlert } from '../../actions/utils';
import { useAuthorization } from '../../services/hooks';
import { ComponentDataTable, DefaultModal, SimpleCenteredModal } from '../index';
import OvertimeEdit from './OvertimeEdit';
import { ButtonTooltip } from '../Utils/Tooltips';
import Icon from '../Icons';
import WorkflowRequestRejectOvertime from '../../screens/WorkflowRequest/WorkflowRequestRejectOvertime';
import { downloadFile } from '../../services/utils';
import OvertimeInfo from './OvertimeInfo';

const OvertimeDataTable = ({ moreData, setMoreData, columns, customParams, preName }) => {
  const [overtimes, setOvertimes] = useState([]);
  const [amount, setAmount] = useState(0);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalAction, setModalAction] = useState(() => null);
  const [simpleModalBody, setSimpleModalBody] = useState('');
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(true);
  const [query, setQuery] = useState({});
  const dispatch = useDispatch();

  const handleModalClose = () => {
    setModalShow(false);
    setModalBody('');
    setModalItem(null);
    setSimpleModalShow(false);
    setSimpleModalBody('');
    setModalTitle('');
  };

  const handleSuccessAction = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    handleModalClose();
    setMoreData(!moreData);
  };

  const handleSuccessIndex = response => {
    const { amount: rAmount } = response.data.metadata;
    setOvertimes(response.data.data);
    setAmount(rAmount);
    setOnRequest(false);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error.response?.data?.message }));
    handleModalClose();
    setOnRequest(false);
  };

  const handleRequest = async params => {
    setOnRequest(true);
    setQuery(params);
    debounceIndexOvertimeRequest({
      dispatch,
      params: {
        ...customParams,
        ...params
      },
      successCallback: handleSuccessIndex
    });
  };

  const removeOvertime = item => {
    deleteOvertimeRequest(item.id, {
      dispatch,
      successCallback: () => handleSuccessAction('Hora Extra eliminada con éxito'),
      failureCallback: handleFailureRequest
    });
  };

  const setModal = (item, body) => {
    setModalItem(item);
    setModalBody(body);
    setModalShow(true);
  };

  const setEditModal = (title, body) => {
    setModalTitle(title);
    setSimpleModalBody(body);
    setSimpleModalShow(true);
  };

  const rejectOvertime = values => {
    setOnRequest(true);
    const {
      workflowRequest: { record }
    } = values;

    rejectOvertimeRequest(record.id, {
      params: snakeCaseKeys(values),
      dispatch,
      successCallback: () => handleSuccessAction('Hora Extra rechazada con éxito'),
      failureCallback: handleFailureRequest
    });
  };

  const approveOvertime = item => {
    approveOvertimeRequest(item.id, {
      dispatch,
      successCallback: () => handleSuccessAction('Hora Extra aprobada con éxito'),
      failureCallback: handleFailureRequest
    });
  };

  const handleDownload = () => {
    indexOvertimeRequest({
      dispatch,
      params: {
        ...customParams,
        ...query
      },
      format: '.xlsx',
      responseType: 'text',
      successCallback: downloadFile
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'approved':
        setModalTitle('Aprobar Hora Extra');
        setModal(item, '¿Estás seguro que deseas aprobar esta Hora Extra?');
        setModalAction(() => approveOvertime);
        break;
      case 'rejected':
        setModalTitle('Rechazar Hora Extra');
        setSimpleModalBody(
          <WorkflowRequestRejectOvertime
            record={item}
            workflowRequest={item}
            cancelAction={handleModalClose}
            formRequest={rejectOvertime}
          />
        );
        setSimpleModalShow(true);
        break;
      case 'show':
        setEditModal(`SOLICITUD DE HORAS EXTRAS`, <OvertimeInfo overtime={camelCaseRecursive(item)} />);
        break;
      case 'edit':
        setEditModal(
          `Horas Extra - ${item.employee.full_name}`,
          <OvertimeEdit
            overtime={camelCaseRecursive(item)}
            handleSuccessAction={handleSuccessAction}
            handleFailureAction={handleFailureRequest}
          />
        );
        break;
      case 'destroy':
        setModal(item, `¿Estás seguro que deseas eliminar esta Hora Extra de ${item.employee.full_name}?`);
        setModalAction(() => removeOvertime);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'name':
      case 'employee.full_name':
        return { sort_employee: 'employee.name' };
      case 'request_date':
        return { sort_column: 'created_at' };
      case 'status':
        return { sort_status: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick)}
        handleSortCase={sortColumnCase}
        data={overtimes}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={handleRequest}
        withMassActions={useAuthorization('download', 'Overtime')}
        massActions={
          <ButtonTooltip
            className="mt-4 mr-2"
            variant="circle-primary"
            text="Descargar"
            direction="right"
            onClick={handleDownload}
          >
            <Icon width={22} style={{ overflow: 'visible' }} className="text-primary" icon="download-2" />
          </ButtonTooltip>
        }
        withStartDate
        withEndDate
        preName={preName || 'overtimes'}
        onRowClicked={item => handleButtonClick(item, 'show')}
        pointerOnHover
      />
      <DefaultModal
        title="Eliminar Hora Extra"
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />

      <SimpleCenteredModal
        size="md"
        title={modalTitle}
        body={simpleModalBody}
        show={simpleModalShow}
        onHide={handleModalClose}
      />
    </>
  );
};

export default OvertimeDataTable;
