import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { getIn, useFormikContext } from 'formik';
import { FormikInput, FormikRangePicker, FormikSelect, Icon, NestedAttributes } from '../../../components';
import BalanceInput from './BalanceInput';
import AlimonyForm from './AlimonyForm';

const WithHoldings = ({ fetchBalanceDiscounts, ...props }) => {
  const {
    contract,
    errors,
    touched,
    values,
    canCreateDiscount,
    canDestroyDiscount,
    initialValueBalance,
    unitOfAccounts
  } = props;
  const { setFieldValue } = useFormikContext();
  const { retentions } = props;
  const { contract: vContract } = values.employee;
  const { contractBalancesRetentionsAttributes: valuesAttributes = [] } = vContract;
  const { contractBalancesRetentionsAttributes } = contract;
  const resultRetentions = [];

  valuesAttributes.forEach((body, index) => {
    if (body._destroy) {
      resultRetentions.push(undefined);
      return;
    }
    const title = 'Retencion';
    const key = `balance-retention-${index.toString()}`;
    const fetch = (inputValue, callback) => fetchBalanceDiscounts(inputValue, callback, { filter_retention: true });
    const defaultValue = initialValueBalance(contractBalancesRetentionsAttributes, body);
    const unitOfAccountValue = body.unitOfAccount ? body.unitOfAccount : 'pesos';
    const defaultOptions = retentions;
    const addon = unitOfAccounts.find(unit => unit.value === body.unitOfAccount)?.label || '';
    const disabledAmount = false;
    const response = (
      <>
        <Col md={10} className="d-flex">
          <BalanceInput
            contractValuePath="contractBalancesRetentionsAttributes"
            key={key}
            addon={addon}
            defaultOptions={defaultOptions}
            unitOfAccounts={unitOfAccounts}
            unitOfAccountValue={unitOfAccountValue}
            defaultValue={defaultValue}
            disabledAmount={disabledAmount}
            errors={errors}
            fetch={fetch}
            index={index}
            setFieldValue={setFieldValue}
            touched={touched}
            title={title}
            startDate={body.startDate}
            endDate={body.endDate}
          />
        </Col>
        <Col md={10}>
          <AlimonyForm
            retention={body.contractRetention}
            index={index}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
          />
        </Col>
      </>
    );
    resultRetentions.push(response);
  });

  resultRetentions.push(
    <>
      <Col md={3} className="sample-row">
        <FormikSelect label="Retencion" placeholder="Seleccionar Retencion" isDisabled />
      </Col>
      <Col md={2} className="sample-row">
        <FormikSelect label="Moneda" placeholder="Seleccionar Moneda" isDisabled />
      </Col>
      <Col md={2} className="sample-row">
        <FormikInput label="Monto" leftAddon="$" disabled />
      </Col>
      <Col md={4} className="sample-row">
        <FormikRangePicker startDateName="" endDateName="" labelLeft="Desde" labelRight="Hasta" showClearDates />
      </Col>
    </>
  );

  return (
    <>
      {getIn(errors, 'employee[contract][contractBalancesRetentionsAttributes]') ===
        'No pueden haber conceptos repetidos' && (
        <Row>
          <Col md={12}>
            <span style={{ color: 'red' }}>
              <Icon icon="alert-circle" style={{ marginRight: '5px', height: '15px', width: '15px' }} />
              {'No pueden haber conceptos repetidos'}
            </span>
          </Col>
        </Row>
      )}
      <h4 className="text-uppercase mb-1 mt-2">Retenciones</h4>
      <NestedAttributes
        mapInputs={resultRetentions}
        arrayValues={valuesAttributes}
        setFieldValue={setFieldValue}
        valuePath="employee[contract][contractBalancesRetentionsAttributes]"
        newAttributes={{
          balanceId: '',
          amount: '',
          balanceType: 'retention',
          code: '',
          retention: true,
          contractRetention: {}
        }}
        removeFirstItem
        canCreate={canCreateDiscount}
        canDestroy={canDestroyDiscount}
        unshiftAfterDelete
      />
    </>
  );
};

export default WithHoldings;
