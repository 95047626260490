import React from 'react';
import { Accordion, Card, Col } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import ShiftContractsForm from '../ShiftContractsForm';
import { DatatableEmployeeName } from '../../../components';

const columns = [
  {
    name: 'NOMBRE',
    selector: 'employee.fullName',
    cell: item => <DatatableEmployeeName item={item.employee} fileName="fileInfo" name="fullName" fileUrl="fileUrl" />,
    grow: '3',
    sortable: true
  },
  {
    name: 'JORNADA',
    selector: 'workSchedule',
    cell: item => (item.workSchedule ? <span>{item.workSchedule}</span> : <span>Sin Jornada</span>),
    grow: '3',
    sortable: true
  }
];

const Assignment = ({ currentTab, disabled, onChangeTab, shift }) => {
  const { contracts } = shift;

  return (
    <Card className="overflow-visible">
      <Col className={`${currentTab === 'assignment' ? 'top-header-green' : 'top-header-light-gray'}`}>
        <Accordion.Toggle
          as={Col}
          eventKey="5"
          className="card-header-title-dt"
          onClick={() => onChangeTab('assignment')}
        >
          Asignación
        </Accordion.Toggle>
      </Col>
      <Accordion.Collapse eventKey="5">
        <Card.Body className="div-content">
          {disabled ? (
            <div className="info-box">
              <h3 className="text-uppercase">Trabajadores</h3>
              <DataTable columns={columns} data={contracts} noDataComponent="No tiene trabajadores asociados" noHeader />
            </div>
          ) : (
            <ShiftContractsForm shift={shift} />
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};
export default Assignment;
