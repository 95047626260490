import { Field, Form, getIn, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormikSelect, FormikTimePicker } from '../../../components';
import { yearlyPayrollEmployeesRequest } from '../../../requests/payrolls';

const CerDjFrom = ({
  onHide,
  submitVariant,
  errors,
  touched,
  isSubmitting,
  setFieldValue,
  values,
  setFieldTouched
}) => {
  const dispatch = useDispatch();
  const [payrollsEmployees, setPayrollsEmployees] = useState([]);
  const ALL_SELECTED_LABEL = 'Todos';
  const { employeeDeclaration } = values;
  const fetchEmployeesPayrolls = params => {
    yearlyPayrollEmployeesRequest({
      dispatch,
      params: {
        ...params,
        paginate: false
      },
      successCallback: response => {
        const allEmployees = response.data;
        const dataToGroupSearch = [{ label: 'Todos', value: 'all' }, ...allEmployees];
        setPayrollsEmployees(response.data.length > 0 ? dataToGroupSearch : []);
      }
    });
  };
  const handleEmployeeFilters = () => {
    if (employeeDeclaration.year) {
      setFieldValue('employeeDeclaration[employees]', []);
      setFieldValue('employeeDeclaration[employeeIds]', []);
      const params = { year: employeeDeclaration.year };

      fetchEmployeesPayrolls(params);
    }
  };

  const handleNewMulti = (data, field, allowEmpty = false) => {
    const newData = data.map(element => element.value);
    if (allowEmpty && !newData.length) newData.push('');
    setFieldValue(field, newData.includes('all') ? [] : newData);
  };

  const hasAllSelection = (groupSelection = []) => {
    return groupSelection.some(option => option.label === ALL_SELECTED_LABEL);
  };

  const handleDisabledSelection = (options, selectedOptions) => {
    if (hasAllSelection(selectedOptions)) {
      return true;
    }
    if (selectedOptions.length === 0) return false;
    return options.label === ALL_SELECTED_LABEL;
  };

  useEffect(handleEmployeeFilters, [employeeDeclaration.year]);

  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col md={4}>
            <Field name="employeeDeclaration[year]">
              {({ field }) => (
                <FormikTimePicker
                  abbr
                  label="Año"
                  placeholder="Seleccione año"
                  dateFormat="yyyy"
                  showYearPicker
                  onChange={date => {
                    setFieldValue(field.name, date);
                  }}
                  selected={field.value}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={8}>
            <Field name="employeeDeclaration[employees]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Trabajadores"
                  placeholder="Seleccionar Trabajador"
                  isMulti
                  options={payrollsEmployees}
                  values={employeeDeclaration.employee_ids}
                  onChange={data => {
                    handleNewMulti(data || [], 'employeeDeclaration[employeeIds]', true);
                    setFieldValue(field.name, data || []);
                  }}
                  setFieldTouched={() => setFieldTouched(field.name)}
                  isOptionDisabled={handleDisabledSelection}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" className="btn" variant={submitVariant} disabled={isSubmitting} onClick={onHide}>
          Generar
        </Button>
      </Modal.Footer>
    </Form>
  );
};
const setInitialValues = props => {
  const { company, createdBy } = props;
  return {
    employeeDeclaration: {
      year: '',
      employeeIds: [],
      employees: [],
      company,
      createdBy
    }
  };
};

const validationSchema = Yup.object().shape({
  employeeDeclaration: Yup.object().shape({
    year: Yup.mixed().test('is-year', 'Debes seleccionar un año', value => {
      return value !== undefined && value !== '' && value !== null;
    }),
    employees: Yup.array().min(1, 'Debes escoger al menos un colaborador.')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(CerDjFrom);
