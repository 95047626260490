import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAbility } from '@casl/react';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { sendAlert } from '../../../actions/utils';
import { AbilityContext } from '../../../config/abilityContext';
import { ComponentDataTable, DefaultModal, SimpleCenteredModal, Reminder } from '../../../components';
import {
  debounceIndexOvertimeRequest,
  deleteOvertimeRequest,
  rejectOvertimeRequest
} from '../../../requests/overtimes';
import useCreateOvertimeHook from './useCreateOvertimeHook';
import SignatureKeyForm from '../../DocumentPreview/SignatureKeyForm';
import WorkflowRequestReject from '../../WorkflowRequest/WorkflowRequestReject';
import OvertimeShow from './OvertimeShow';

const OvertimeProfileDatatable = ({
  rrhh = false,
  columns,
  preName,
  moreData,
  setMoreData,
  defaultStartDate = null,
  defaultEndDate = null,
  customParams,
  nameRangePicker,
  withMassActions,
  currentEmployee
}) => {
  const ability = useAbility(AbilityContext);
  const [amount, setAmount] = useState(0);
  const [centerModalShow, setCenterModalShow] = useState(false);
  const [modalAction, setModalAction] = useState(() => null);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalButtons, setModalButtons] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(true);
  const [overtimeRequests, setOverimeRequests] = useState([]);
  const [reminderItem, setReminderItem] = useState({});
  const [reminderShow, setReminderShow] = useState(false);
  const [isDisabled, setIsDisable] = useState(false);

  const dispatch = useDispatch();

  const { handleApproveOvertime, handleSignApproveOvertime } = useCreateOvertimeHook();

  const handleRequest = params => {
    setOnRequest(true);
    const sendParams = {
      ...params,
      ...customParams,
      filter_by_employee: rrhh ? '' : currentEmployee
    };
    const request = async () => {
      debounceIndexOvertimeRequest({
        dispatch,
        params: sendParams,
        successCallback: response => {
          const { data, metadata } = response.data;
          setOverimeRequests(data);
          setAmount(metadata.amount);
          setOnRequest(false);
        }
      });
    };
    request();
  };

  const handleModalClose = () => {
    setCenterModalShow(false);
    setModalShow(false);
    setModalShow(false);
    setModalTitle('');
    setModalBody('');
    setOnRequest(false);
  };

  const handleSuccessRequest = message => {
    setMoreData(!moreData);
    dispatch(sendAlert({ kind: 'success', message }));
    handleModalClose();
  };

  const handleFailureRequest = error => {
    const { response } = error;
    setIsDisable(false);
    setOnRequest(false);
    setMoreData(!moreData);
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const removeOvertimeRequest = item => {
    setOnRequest(true);
    deleteOvertimeRequest(item.id, {
      dispatch,
      successCallback: () => handleSuccessRequest('Solicitud eliminada con éxito'),
      callback: () => handleModalClose()
    });
  };

  const aproveWorfklow = overtime => {
    setModalButtons(false);
    setModalTitle('Firmar y Aprobar Solicitud de Horas Extras');
    setModalBody(
      <SignatureKeyForm
        formRequest={(formKey, item, setSubmitting) => {
          handleSignApproveOvertime(formKey, item, setSubmitting, handleSuccessRequest, handleFailureRequest);
        }}
        onHide={handleModalClose}
        item={overtime}
        signatureKey=""
      />
    );
  };

  const handleApproveModal = overtime => {
    setIsDisable(false);
    setOnRequest(false);
    setModalItem(overtime);
    setModalButtons(true);
    setModalTitle('Aprobar Solicitud de Horas Extras');
    setModalBody('¿Estás seguro que deseas Aprobar Solicitud de Horas Extras?');
    setModalAction(() => (_item, setSubmitting) => {
      handleApproveOvertime(overtime, setSubmitting, handleSuccessRequest, handleFailureRequest);
    });
  };

  const rejectDocument = (values, setSubmitting) => {
    setOnRequest(true);
    const {
      workflowRequest: { record }
    } = values;

    rejectOvertimeRequest(record.id, {
      params: snakeCaseKeys(values),
      dispatch,
      successCallback: () => handleSuccessRequest('Solicitud de Horas Extras rechazada correctamente..!!'),
      callback: () => setSubmitting(false)
    });
  };

  const handleRejectModal = item => {
    setModalTitle('Rechazar Solicitud de Horas Extras');
    setModalBody(
      <WorkflowRequestReject
        record={item}
        workflowRequest={item}
        cancelAction={handleModalClose}
        formRequest={rejectDocument}
      />
    );
    setModalShow(true);
    setModalButtons(false);
  };

  const handleActions = (item, action) => {
    setModalItem(item);
    switch (action) {
      case 'approved': {
        const camelItem = camelCaseRecursive(item);
        if (camelItem.requestReviewerSignature) {
          aproveWorfklow(camelItem);
        } else {
          handleApproveModal(camelItem);
        }
        setModalShow(true);
        break;
      }
      case 'rejected':
        handleRejectModal(item);
        break;
      case 'resend':
        setReminderShow(true);
        setReminderItem(snakeCaseKeys(item));
        break;
      case 'show':
        setModalTitle('Solicitud de Horas Extras');
        setModalBody(<OvertimeShow item={item} />);
        setCenterModalShow(true);
        break;
      case 'destroy':
        setModalTitle('Eliminar Solicitud de Horas Extras');
        setModalBody('¿Estás seguro que deseas eliminar esta solicitud?');
        setModalShow(true);
        setModalAction(() => removeOvertimeRequest);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'employee':
      case 'employee.full_name':
        return { sort_employee: name };
      case 'request_date':
        return { sort_column: 'created_at' };
      case 'status':
        return { sort_status: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        columns={columns(handleActions, ability, 'list', rrhh)}
        defaultEndDate={defaultEndDate}
        defaultStartDate={defaultStartDate}
        data={overtimeRequests}
        handleSortCase={sortColumnCase}
        moreData={moreData}
        onRequest={onRequest}
        onRowClicked={item => handleActions(item, 'show')}
        pointerOnHover
        preName={preName || 'overtime'}
        rangePicker
        nameRangePicker={nameRangePicker}
        resourceRequest={handleRequest}
        selectableRows={withMassActions}
        totalRows={amount}
        withStartDate
        withEndDate
        withMassActions={withMassActions}
      />
      <SimpleCenteredModal
        title={modalTitle}
        size="md"
        body={modalBody}
        show={centerModalShow}
        onHide={handleModalClose}
      />
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={() => modalAction(modalItem)}
        disabled={onRequest || isDisabled}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        withClose={modalButtons}
        withConfirm={modalButtons}
      />
      <Reminder
        item={reminderItem}
        show={reminderShow}
        modalShow={setReminderShow}
        moreData={moreData}
        setOnRequest={setOnRequest}
        setMoreData={setMoreData}
      />
    </>
  );
};

export default OvertimeProfileDatatable;
