import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { createShiftRequest } from '../../requests/shifts';
import { sendAlert } from '../../actions/utils';
import { LinkBtn, Icon, DefaultModal } from '../../components';
import ShiftForm from './ShiftForm';
import ShiftMonth from './ShiftMonth';
import ShiftRotative from './ShiftRotative';
import basicShift from './shift';
import ShiftHours from './ShiftHours';

const ShiftNew = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState(false);
  const [shifts, setShifts] = useState();
  const [isSubmitting, setSubmitting] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const shiftType = new URLSearchParams(location.search).get('shiftType') || 'weekly';

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Turno creado con éxito' }));
    history.push('/shifts');
  };

  const handleOpenModal = shift => {
    setModalBody(
      <>
        <p>Los turnos asignados no podrán ser distintos de la jornada asignada a cada trabajador.</p>
        <br />
        <span>Recordatorio: No se pueden crear turnos con el mismo tipo y horario </span>
      </>
    );
    setModalShow(true);
    setShifts(shift);
  };

  const handleCloseModal = () => {
    setModalShow(false);
    setSubmitting(false);
    setDisableButton(false);
  };

  const handleCreateRequest = () => {
    setDisableButton(true);
    const daysAttributes = shifts.shift.shiftDaysAttributes;
    const daysAttributAdapte = daysAttributes.map((item, index) => {
      const { startDate } = shifts.shift;
      if (shiftType === 'rotative') {
        const initialDate = startDate ? moment(startDate, 'DD/MM/YYYY') : moment();
        const newItem = { ...item };
        newItem.date = initialDate
          .clone()
          .add(index, 'days')
          .format('YYYY-MM-DD');
        return newItem;
      }

      return item;
    });

    const myParams = snakeCaseKeys({
      shift: {
        ...shifts.shift,
        shiftDaysAttributes: daysAttributAdapte.filter(item => item && item.send)
      }
    });

    createShiftRequest({
      dispatch,
      params: myParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleCloseModal,
      callback: () => {
        setSubmitting(false);
        setDisableButton(false);
      }
    });
  };

  const title = () => {
    switch (shiftType) {
      case 'monthly':
        return 'Configuración Turno Mensual';
      case 'rotative':
        return 'Configuración Turno Rotativo';
      case 'hours':
        return 'Configuración Turno por Horas';
      default:
        return 'Nuevo Turno Semanal';
    }
  };

  const body = () => {
    switch (shiftType) {
      case 'monthly':
        return <ShiftMonth shift={basicShift} action="new" formRequest={handleOpenModal} isSubmitting={isSubmitting} />;
      case 'rotative':
        return (
          <ShiftRotative shift={basicShift} action="new" formRequest={handleOpenModal} isSubmitting={isSubmitting} />
        );
      case 'hours':
        return <ShiftHours shift={basicShift} action="new" formRequest={handleOpenModal} isSubmitting={isSubmitting} />;
      default:
        return <ShiftForm shift={basicShift} action="new" formRequest={handleOpenModal} isSubmitting={isSubmitting} />;
    }
  };

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <Row className="mt-4 mb-4 align-items-center">
        <Col md={1}>
          <LinkBtn variant="circle-dark" className="btn-circle mt-3" block to="/shifts">
            <Icon icon="chevron-back" />
          </LinkBtn>
        </Col>
        <Col>
          <h2 className="text-uppercase mb-0 mt-3">{title()}</h2>
        </Col>
      </Row>
      {body()}
      <DefaultModal
        title="Recordatorio"
        body={modalBody}
        show={modalShow}
        handleClose={handleCloseModal}
        handleConfirm={handleCreateRequest}
        withClose={false}
        titleBtnSave="Confirmar"
        disabled={disableButton}
      />
    </>
  );
};

export default ShiftNew;
