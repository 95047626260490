import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Spinner } from 'react-bootstrap';
import { downloadFile, delayMethod } from '../../../services/utils';
import { DefaultModal, DocumentsTabContainer, SimpleCenteredModal } from '../../../components';

import {
  deleteDirectoryFileRequest,
  deleteDirectoryNodeRequest,
  downloadDirectoryNodeRequest,
  updateFolderRequest,
  exportDirectoryNodeRequest,
  indexDirectoryNodesRequest
} from '../../../requests/directoryNodes';
import { updateDocumentRequest } from '../../../requests/documents';
import { sendAlert } from '../../../actions/utils';
import DocumentPreview from '../../DocumentPreview/DocumentPreview';

const DirectoryNodeBoxes = ({
  employeeId,
  moreDataCenterModal,
  handleOpenCenterModal,
  handleOpenImportModal,
  handleEditModal,
  rrhh = false
}) => {
  const [directoryNodes, setDirectoryNodes] = useState([]);
  const [modalAction, setModalAction] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [documentModalShow, setDocumentModalShow] = useState(false);
  const [documentModalBody, setDocumentModalBody] = useState('');
  const [moreData, setMoreData] = useState([]);
  const [onRequest, setOnRequest] = useState(true);
  const dispatch = useDispatch();

  const addFirstIcons = directoryNodesResponse => {
    const folders = directoryNodesResponse;
    folders.forEach((folder, i) => {
      switch (folder.documentType) {
        case 'work_contract':
          folders[i].icon = 'document';
          break;
        case 'certificate':
          folders[i].icon = 'school';
          break;
        case 'contract_annex':
          folders[i].icon = 'document-attach';
          break;
        case 'work_settlement':
          folders[i].icon = 'exit';
          break;
        case 'salary_settlement':
          folders[i].icon = 'barcode';
          break;
        case 'employee_requests':
          folders[i].icon = 'document-text';
          break;
        case 'other_documents':
          folders[i].icon = 'grid';
          break;
        case 'reprimand_document':
          folders[i].icon = 'warning';
          break;
        default:
          break;
      }
    });
    return folders;
  };

  const handleSuccessIndex = response => {
    setDirectoryNodes(addFirstIcons(camelCaseRecursive(response.data.data)));
  };

  const handleSuccessRequest = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setMoreData(!moreData);
  };

  const handleFailureRequest = error => {
    const { response } = error;

    let errorMessage = response.statusText;
    if (response.data && response.data.message) {
      errorMessage = response.data.message;
    }

    if (response.data.message.indexOf('trabajador') > 0) {
      setModalTitle(
        <span role="img" aria-label="">
          {' '}
          ¡UPS! &#128559;{' '}
        </span>
      );
      setModalBody(errorMessage);
      setModalAction('close');
      setModalShow(true);
    }
  };

  const defaultCallback = () => {
    setModalShow(false);
    setOnRequest(false);
  };

  const handleRequest = () => {
    setOnRequest(true);

    const params = {
      tree_data: '100'
    };

    if (rrhh === false) {
      params.documents_filter_guidelines = JSON.stringify({
        work_contract: ['approved'],
        contract_annex: ['approved'],
        salary_settlement: ['approved'],
        work_settlement: ['approved'],
        reprimand_document: ['approved'],
        congratulation_letter: ['approved'],
        salary_certificate: ['approved']
      });
    }

    const request = async () =>
      indexDirectoryNodesRequest({
        employeeId,
        dispatch,
        params,
        successCallback: handleSuccessIndex,
        callback: () => setOnRequest(false)
      });
    request();
  };

  useEffect(handleRequest, [moreData, moreDataCenterModal]);

  const handleMoveDocument = (directoryNodeId, documentId) => {
    setOnRequest(true);

    updateDocumentRequest(documentId, {
      employeeId,
      dispatch,
      params: {
        directory_node_id: directoryNodeId
      },
      successCallback: () => handleSuccessRequest('Archivo movido con éxito'),
      callback: defaultCallback
    });
  };

  const handleMoveFolder = (directoryNodeId, folderId) => {
    setOnRequest(true);

    updateFolderRequest(folderId, {
      employeeId,
      dispatch,
      params: {
        parent_id: directoryNodeId
      },
      successCallback: () => handleSuccessRequest('Carpeta movida con éxito'),
      callback: defaultCallback
    });
  };

  const removeDirectoryFolder = directoryFolder => {
    delayMethod(() => setModalShow(false), 160);
    setOnRequest(true);

    deleteDirectoryNodeRequest(directoryFolder[0], {
      employeeId,
      dispatch,
      successCallback: () => handleSuccessRequest('Carpeta eliminada con éxito'),
      callback: () => setOnRequest(false)
    });
  };

  const removeDirectoryFile = directoryFile => {
    delayMethod(() => setModalShow(false), 160);
    setOnRequest(true);

    const [document, folderId] = directoryFile;
    deleteDirectoryFileRequest({
      params: {
        documents: [
          {
            directory_node_id: folderId,
            document_id: document.id,
            employee_id: employeeId
          }
        ]
      },
      dispatch,
      successCallback: () => handleSuccessRequest('Archivo eliminado con éxito'),
      failureCallback: error => handleFailureRequest(error),
      callback: () => setOnRequest(false)
    });
  };

  const downloadDirectoryFolder = directoryFolder => {
    downloadDirectoryNodeRequest(directoryFolder.id, {
      employeeId,
      dispatch,
      successCallback: downloadFile,
      callback: defaultCallback
    });
  };

  const exportDirectoryNode = directoryNode => {
    exportDirectoryNodeRequest(directoryNode.id, {
      employeeId,
      dispatch,
      successCallback: downloadFile,
      callback: defaultCallback
    });
  };

  const handleButtonClick = (item, action, parentId) => {
    switch (action) {
      case 'show':
        if (item.documentType === 'risk_prevention') {
          window.open(item.fileInfo?.fileUrl, '_blank');
        } else {
          setDocumentModalBody(<DocumentPreview documentId={item.id} employeeId={item.employeeId} />);
          setDocumentModalShow(true);
        }
        break;
      case 'download':
        window.open(item.fileInfo.fileUrl, '_blank');
        break;
      case 'remove':
        setModalAction('remove');
        setModalShow(true);
        setModalItem([item, parentId]);
        setModalTitle('Eliminar Archivo');
        setModalBody(`¿Estás seguro que deseas eliminar ${item.fileInfo.filename}?`);
        break;
      case 'download-folder':
        setModalAction('download-folder');
        setModalShow(true);
        setModalItem(item);
        setModalTitle('Exportar Plantilla de Actualización');
        setModalBody(`¿Estás seguro que deseas descargar la plantilla de actualización para la carpeta ${item.name}?`);
        break;
      case 'export-folder':
        setModalAction('export-folder');
        setModalShow(true);
        setModalItem(item);
        setModalTitle('Exportar carpeta');
        setModalBody(`¿Estás seguro que deseas exportar la carpeta ${item.name}?`);
        break;
      case 'remove-folder':
        setModalAction('remove-folder');
        setModalShow(true);
        setModalItem([item.id]);
        setModalTitle('Eliminar carpeta');
        setModalBody(`¿Estás seguro que deseas eliminar ${item.name}?`);
        break;
      case 'import-folder':
        handleOpenImportModal(item);
        break;
      case 'change-name':
        handleEditModal(item.id, item.name, setOnRequest);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const handleDocumentModalClose = () => {
    setDocumentModalShow(false);
  };

  const handleModalConfirm = () => {
    switch (modalAction) {
      case 'remove-folder':
        removeDirectoryFolder(modalItem);
        break;
      case 'remove':
        removeDirectoryFile(modalItem);
        break;
      case 'download-folder':
        downloadDirectoryFolder(modalItem);
        break;
      case 'export-folder':
        exportDirectoryNode(modalItem);
        break;
      case 'close':
        setModalShow(false);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Error: Action not found');
    }
  };

  return (
    <>
      {onRequest && (
        <div className="container-documents-spinner">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <div className={`${onRequest && 'bg-opacity'}`}>
        <DocumentsTabContainer
          items={directoryNodes}
          handleOpenCenterModal={handleOpenCenterModal}
          handleButtonClick={handleButtonClick}
          handleMoveDocument={handleMoveDocument}
          handleMoveFolder={handleMoveFolder}
          permitedActions={['show', 'remove']}
          permitedCreations={['node', 'file']}
          maxDepth={100}
        />
        <DefaultModal
          title={modalTitle}
          body={modalBody}
          show={modalShow}
          titleBtnClose="Cancelar"
          handleClose={() => setModalShow(false)}
          titleBtnSave="Confirmar"
          handleConfirm={handleModalConfirm}
        />
        <SimpleCenteredModal
          body={documentModalBody}
          onHide={handleDocumentModalClose}
          show={documentModalShow}
          size="xl"
        />
      </div>
    </>
  );
};

export default DirectoryNodeBoxes;
