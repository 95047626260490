import React, { useEffect } from 'react';
import { withFormik, Field, Form } from 'formik';
import { Button, Row, Col, Card, Accordion } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { FormikSelect } from '../../components';
import { advancePaymentsTypes } from '../Employee/EmployeeForm/FormOptions';
import {
  EmployeesFilter,
  BalancesFilter,
  CostCentersFilter,
  BranchOfficesFilter,
  JobManagementsFilter
} from '../../components/Utils/Filters';
import { searchEmployeeRequest } from '../../requests/employees';

const clearAllBalues = values => {
  const newValues = { ...values };
  Object.keys(newValues).forEach(key => {
    if (newValues[key] === 'all' || newValues[key] === '') {
      newValues[key] = '';
    }
  });
  return newValues;
};

const EmployeeFilterAp = props => {
  const { setFieldValue, values } = props;
  const dispatch = useDispatch();
  const employeeStatus = [
    { label: 'Todos', value: 'all' },
    { label: 'Activo', value: '1' },
    { label: 'Inactivo', value: '0' }
  ];

  const apTypes = [
    { label: 'Todos', value: 'all' },
    ...advancePaymentsTypes.filter(asItem => asItem.value !== 'contract_balances')
  ];

  const searchEmployeesParams = () => {
    const request = async () =>
      searchEmployeeRequest({
        dispatch,
        params: {
          sort_column: 'name',
          paginate: false,
          advance: true,
          filter_employee_status: '1'
        },
        successCallback: response => camelCaseRecursive([{ label: 'Todos', value: 'all' }, ...response.data.data])
      });
    request();
  };

  const initialFetch = () => {
    searchEmployeesParams();
  };

  useEffect(initialFetch, []);

  return (
    <Form>
      <Card>
        <Col className="top-header-green">
          <Accordion.Toggle as={Col} eventKey="0" className="card-header-title">
            FILTROS DE BÚSQUEDA
          </Accordion.Toggle>
        </Col>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="div-content">
            <Row>
              <Col xs={12} md={4}>
                <EmployeesFilter formikValue="filterId" />
              </Col>
              <Col xs={12} md={4}>
                <BalancesFilter formikValue="filterByBalanceId" />
              </Col>
              <Col xs={12} md={4}>
                <Field name="filterByAdvanceType">
                  {({ field }) => (
                    <FormikSelect
                      options={apTypes}
                      label="Origen del Anticipo"
                      placeholder="Seleccionar por Anticipo"
                      value={values.filterByAdvanceType}
                      defaultValue={values.filterByAdvanceType}
                      onChange={data => {
                        setFieldValue(field.name, data ? data.value : '');
                      }}
                    />
                  )}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <CostCentersFilter formikValue="filterCostCenter" />
              </Col>
              <Col xs={12} md={4}>
                <BranchOfficesFilter formikValue="filterBranchOffice" />
              </Col>
              <Col xs={12} md={4}>
                <JobManagementsFilter formikValue="filterJobManagement" />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Field name="filterEmployeeStatus">
                  {({ field }) => (
                    <FormikSelect
                      options={employeeStatus}
                      label="Estado del Trabajador"
                      value={values.filterEmployeeStatus}
                      defaultValue={values.filterEmployeeStatus}
                      onChange={data => {
                        setFieldValue(field.name, data ? data.value : '');
                      }}
                    />
                  )}
                </Field>
              </Col>
              <Col xs={12} md={4}>
                <Field name="filterProcessStatus">
                  {({ field }) => (
                    <FormikSelect
                      options={employeeStatus}
                      label="Estado del Proceso"
                      value={values.filterProcessStatus}
                      defaultValue={values.filterProcessStatus}
                      onChange={data => {
                        setFieldValue(field.name, data ? data.value : '');
                      }}
                    />
                  )}
                </Field>
              </Col>
            </Row>
            <Row className="d-flex justify-content-end mt-4">
              <Col md={2}>
                <Button className="btn-block" type="submit">
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Form>
  );
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  const newValues = clearAllBalues(values);
  formRequest(newValues);
};

export default withFormik({
  mapPropsToValues: () => ({
    filterJobManagement: 'all',
    filterCostCenter: 'all',
    filterBranchOffice: 'all',
    filterByAdvanceType: 'all',
    filterByBalanceId: 'all',
    filterId: 'all',
    filterEmployeeStatus: 'all',
    filterProcessStatus: 'all'
  }),
  handleSubmit,
  enableReinitialize: true
})(EmployeeFilterAp);
