import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import { BasicTextArea } from '../../../components';
import { delayMethod } from '../../../services/utils';

const VacationRejectForm = ({ errors, handleModalClose, onHide, touched, isSubmitting }) => (
  <Form className="mx-3">
    <Row>
      <Col md={12}>
        <Field name="vacation[observation]">
          {({ field }) => (
            <BasicTextArea
              {...field}
              abbr
              label="Observaciones"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
    </Row>
    <Row className="d-flex justify-content-end mt-3 mb-2">
      <Col xs={6} sm={4}>
        <Button block variant="outline-info" onClick={() => delayMethod(handleModalClose)}>
          Cancelar
        </Button>
      </Col>
      <Col xs={6} sm={4}>
        <Button block type="submit" disabled={isSubmitting} variant="danger" onClick={onHide}>
          Rechazar
        </Button>
      </Col>
    </Row>
  </Form>
);

const setInitialValues = ({ vacation }) => {
  return {
    vacation: {
      id: vacation.id,
      observation: ''
    }
  };
};

const validationSchema = Yup.object().shape({
  vacation: Yup.object().shape({
    observation: Yup.string()
      .required('No puede estar en blanco')
      .min(5, 'Debe tener minimo 5 caracteres')
      .max(250, 'Debe tener máximo 250 caracteres')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(VacationRejectForm);
