import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import useConceptHook from './useConceptHook';
import { conceptTypeEnum, ineCodeEnum } from './Form/FormOptions';
import { LinkBtn, Icon } from '../../components';

const ConceptShow = ({ match }) => {
  const balanceId = match.params.id;

  const [concept, setBalance] = useState({});
  const { handleFetchConcept } = useConceptHook();

  const fetchConcept = () => {
    handleFetchConcept(balanceId, setBalance);
  };
  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(fetchConcept, [balanceId]);

  if (Object.keys(concept).length > 2) {
    const conceptLabel = conceptTypeEnum.find(item => item.value === concept.balanceType).label ?? '';
    const conceptType = concept.balanceType;
    const ineCode = concept.ineCode && ineCodeEnum.find(item => item.value === concept.ineCode).label;

    return (
      <>
        <Row className="mt-4 mb-4 align-items-center">
          <Col xs={1}>
            <LinkBtn variant="circle-dark" className="btn-circle mt-3" block to="/remunerations_settings">
              <Icon icon="chevron-back" />
            </LinkBtn>
          </Col>
        </Row>
        <div className="info-box mt-5">
          <h3 className="text-uppercase mb-5">Información del Concepto</h3>
          <Row>
            <Col md={6}>
              <p className="info">
                <span>Tipo de Concepto:</span>
                {conceptLabel}
              </p>
            </Col>
            <Col md={6}>
              <p className="info">
                <span>Código:</span> {concept.code}
              </p>
            </Col>
          </Row>
          <hr />
        </div>
        <div className="info-box">
          <Row>
            <Col md={6}>
              <p className="info">
                <span>Descripción:</span> {concept.name}
              </p>
            </Col>

            {conceptType === 'input_parameters' && (
              <Col md={6}>
                <p className="info">
                  <span>Máximo:</span> {concept.maxInput}
                </p>
              </Col>
            )}
            <Col md={6}>
              <p className="info">
                <span>Cuenta Contable:</span>{' '}
                {concept.bookkeepers ? concept.bookkeepers.map(b => b.label).join(', ') : ''}
              </p>
            </Col>
          </Row>
          <hr />
        </div>

        {conceptType === 'asset' && (
          <div className="info-box">
            <Row>
              <>
                <Col md={6}>
                  <p className="info">
                    <span>Imponible:</span> {concept.taxable ? 'Si' : 'No'}
                  </p>
                </Col>

                <Col md={6}>
                  <p className="info">
                    <span>Tributable:</span> {concept.tributable ? 'Si' : 'No'}
                  </p>
                </Col>
              </>
            </Row>
            <hr />
          </div>
        )}

        {['asset'].includes(conceptType) && (
          <div className="info-box">
            <Row>
              <Col md={6}>
                <p className="info">
                  <span>Proporcional:</span> {concept.proportional ? 'Si' : 'No'}
                </p>
              </Col>
              <Col md={6}>
                <p className="info">
                  <span>Base de Gratificación:</span> {concept.gratificationBase ? 'Si' : 'No'}
                </p>
              </Col>
            </Row>
            <hr />
          </div>
        )}

        {conceptType === 'discount' && (
          <div className="info-box">
            <Row>
              <Col md={6}>
                <p className="info">
                  <span>Legal:</span> {concept.legalDiscount ? 'Si' : 'No'}
                </p>
              </Col>
              <Col md={6}>
                <p className="info">
                  <span>Anticipo de Liquidación:</span> {concept.settlementAdvance ? 'Si' : 'No'}
                </p>
              </Col>
            </Row>
            <hr />
          </div>
        )}

        <div className="info-box">
          <Row>
            {['asset', 'discount'].includes(conceptType) && (
              <Col md={6}>
                <p className="info">
                  <span>Se imprime en liquidación:</span> {concept.printOnLiquidation ? 'Si' : 'No'}
                </p>
              </Col>
            )}

            <Col md={6}>
              <p className="info">
                <span>Activo:</span> {concept.active ? 'Si' : 'No'}
              </p>
            </Col>
          </Row>
          <hr />
        </div>

        {conceptType === 'asset' && (
          <div className="info-box">
            <Row>
              <>
                <Col md={6}>
                  <p className="info">
                    <span>Base de Sobretiempo:</span> {concept.overtimeBase ? 'Si' : 'No'}
                  </p>
                </Col>
                <Col md={6}>
                  <p className="info">
                    <span>Semana Corrida:</span> {concept.runningWeek ? 'Si' : 'No'}
                  </p>
                </Col>
              </>
            </Row>
            <hr />
          </div>
        )}

        {['asset', 'discount'].includes(conceptType) && (
          <div className="info-box">
            <Row>
              <Col md={6}>
                <p className="info">
                  <span>Orden en la liquidación:</span> {concept.calculationOrder}
                </p>
              </Col>
              <Col md={6} lg={2}>
                <p className="info">
                  <span>Concepto LRE:</span>
                </p>
              </Col>
              <Col md={6} lg={2} xl={4}>
                <p className="info">{concept.conceptLre ? concept.conceptLre.label : ''}</p>
              </Col>
            </Row>
            <hr />
          </div>
        )}

        {['asset'].includes(conceptType) && (
          <div className="info-box">
            <Row>
              {conceptType === 'asset' && (
                <>
                  <Col md={3} lg={3} xl={2}>
                    <p className="info">
                      <span>Agrupación INE:</span>
                    </p>
                  </Col>

                  <Col md={3} lg={3} xl={4} style={{ padding: 0 }}>
                    <p className="info" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                      {ineCode}
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className="info">
                      <span>Reliquidación:</span> {concept.reliquidation ? 'Si' : 'No'}
                    </p>
                  </Col>
                </>
              )}
            </Row>
            <hr />
          </div>
        )}
        <div className="info-box">
          <Row>
            <Col md={12}>
              <p className="info">
                <span>Observación:</span> {concept.observation}
              </p>
            </Col>
          </Row>
          <hr />
        </div>

        {['asset'].includes(conceptType) && (
          <>
            <div className="info-box">
              <Row>
                <Col md={6}>
                  <p className="info">
                    <span>Indemnización Años de Servicio:</span> {concept.compensationYearsService ? 'Si' : 'No'}
                  </p>
                </Col>
                <Col md={6}>
                  <p className="info">
                    <span>Indemnización Feriado:</span> {concept.holidayCompensation ? 'Si' : 'No'}
                  </p>
                </Col>
              </Row>
              <hr />
            </div>
            <div className="info-box">
              <Row>
                <Col md={6}>
                  <p className="info">
                    <span>Indemnización Aviso Previo:</span> {concept.priorNoticeCompensation ? 'Si' : 'No'}
                  </p>
                </Col>
                <Col md={6}>
                  <p className="info">
                    <span>Indemnización Voluntaria:</span> {concept.voluntaryCompensation ? 'Si' : 'No'}
                  </p>
                </Col>
              </Row>
              <hr />
            </div>
            <div className="info-box">
              <Row>
                <Col md={12}>
                  <p className="info">
                    <span>Concepto Finiquito Electrónico:</span> {concept.translatedElectronicSettlement}
                  </p>
                </Col>
              </Row>
              <hr />
            </div>
            <div className="info-box">
              <Row>
                <Col md={6}>
                  <p className="info">
                    <span>Regalías en especie:</span> {concept.royalties ? 'Si' : 'No'}
                  </p>
                </Col>
                <Col md={6}>
                  <p className="info">
                    <span>Haber variable:</span> {concept.assetVariable ? 'Si' : 'No'}
                  </p>
                </Col>
              </Row>
              <hr />
            </div>
          </>
        )}
      </>
    );
  }

  return <></>;
};

export default ConceptShow;
