import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAbility } from '@casl/react';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import EmployeeFilterAp from './EmployeeFilterAp';
import {
  ComponentDataTable,
  DefaultModal,
  ImportActions,
  ImportModal,
  LongCard,
  SimpleCenteredModal
} from '../../components';
import { updateAdvancePaymentProcessRequest } from '../../requests/advancePaymentProcess';
import { sendAlert } from '../../actions/utils';
import columns from './ApProcessColumns';
import { AbilityContext } from '../../config/abilityContext';
import DataProcessInfo from './DataProcessInfo';
import DataProcessesEdit from './DataProcessesEdit';
import {
  importAdvancePaymentRequest,
  importTemplateAdvancePaymentRequest,
  preImportAdvancePaymentRequest
} from '../../requests/advancePayments';
import {
  exportDataProcessRequest,
  indexDataProcessRequest,
  massiveDeactivateDataProcesses,
  updateDataProcessRequest
} from '../../requests/dataProcess';
import { downloadFile } from '../../services/utils';
import DataProcessMassiveAction from '../../components/DatatableActions/DataProcessMassiveAction';
import DataProcessAbsences from './DataProcessAbsences';

const conditionalRowStyles = [
  {
    when: row => row.status === 'inactive',
    style: {
      backgroundColor: 'rgba(255, 0, 0, 0.1) !important'
    }
  }
];

const EmployeeApDataTable = props => {
  const ability = useAbility(AbilityContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const { advancePaymentProcess, apProcessId, month, moreData, setMoreData, setOnRequest, onRequest } = props;
  const [employees, setEmployees] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalAction, setModalAction] = useState(() => null);
  const [modalItem, setModalItem] = useState('');
  const [simpleModalTitle, setSimpleModalTitle] = useState('');
  const [simpleModalBody, setSimpleModalBody] = useState('');
  const [simpleModalShow, setSimpleModalShow] = useState(false);
  const [size, setSize] = useState('xl');
  const [modalWithClose, setModalWithClose] = useState(false);
  const [modalWithConfirm, setModalWithConfirm] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [clearSelection, setClearSelection] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const [alertInfo, setAlertInfo] = useState('');
  const [filter, setFilter] = useState('');
  const employeesAmount = employees.reduce((acc, current) => acc + current.amount, 0);

  const handleSuccessIndex = response => {
    const { data, metadata } = response.data;
    setEmployees(data);
    setTotalRows(metadata.amount);
  };

  const handleRequest = (advanceFilters = []) => {
    setOnRequest(true);
    indexDataProcessRequest({
      dispatch,
      params: {
        ...advanceFilters,
        filter_process_id: apProcessId,
        ...filter
      },
      successCallback: handleSuccessIndex,
      callback: () => {
        setOnRequest(false);
      }
    });
  };

  const handleFilterAdvance = params => {
    setFilter({ ...params });
    setMoreData(!moreData);
  };

  const handleDefaultModalClose = () => {
    setModalShow(false);
  };

  const handleSimpleModalClose = () => {
    setSimpleModalShow(false);
  };

  const handleFailureRequest = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response?.data?.message }));
  };

  const handleDeleteDataProcessRequest = item => {
    setOnRequest(true);
    updateDataProcessRequest(item.data_process_id, {
      dispatch,
      params: { status: 'inactive' },
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: `Eliminado con éxito` }));
        setMoreData(!moreData);
      },
      failureCallback: error => handleFailureRequest(error),
      callback: () => {
        setOnRequest(false);
        setModalShow(false);
      }
    });
  };

  const handleActivateDataProcessRequest = item => {
    setOnRequest(true);
    updateDataProcessRequest(item.data_process_id, {
      dispatch,
      params: { status: 'active' },
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: `Activado con éxito` }));
        setMoreData(!moreData);
      },
      failureCallback: error => handleFailureRequest(error),
      callback: () => {
        setOnRequest(false);
        setModalShow(false);
      }
    });
  };

  const handleSuccessData = response => {
    const { id } = response.data;
    dispatch(sendAlert({ kind: 'success', message: `actualizado con éxito` }));
    history.push(`/advance_payments_process/${id}/cards`);
  };

  const handleUpdateSuccess = () => {
    setMoreData(!moreData);
    dispatch(sendAlert({ kind: 'success', message: `actualizado con éxito` }));
    setSimpleModalShow(false);
  };

  const handleProcessData = values => {
    setIsSubmitting(true);
    const dataProcessesAttributes = [];
    // eslint-disable-next-line
    values.map(item => {
      dataProcessesAttributes.push({
        advance_payment_id: item.advance_payment_id,
        advance_payment_process_id: apProcessId,
        amount: item.amount,
        balance_id: item.balance_id,
        employee_id: item.employee_id,
        id: item.data_process_id,
        payment_type: item.payment_type,
        source_type: item.type
      });
    });

    const dataProcessesParams = {
      data_processes_attributes: dataProcessesAttributes
    };

    updateAdvancePaymentProcessRequest(apProcessId, {
      dispatch,
      params: dataProcessesParams,
      successCallback: handleSuccessData
    });
  };

  const handleImportModalExceptions = error => {
    if (error?.response?.status === 422) {
      const alertInf = <pre>{error?.response?.data?.message}</pre>;
      setAlertInfo(alertInf);
      setAlertModalShow(true);
      setModalShow(false);
    } else {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    }
  };

  const massiveDestroyRequestDataProcesses = () => {
    const dataProcessesSelected = selectedRows.map(item => item.id);
    setOnRequest(true);
    massiveDeactivateDataProcesses({
      params: { ids: dataProcessesSelected },
      dispatch,
      successCallback: () => {
        setMoreData(!moreData);
        setClearSelectedRows(!clearSelectedRows);
        dispatch(sendAlert({ kind: 'success', message: `Elementos eliminados con éxito` }));
      },
      callback: () => {
        setOnRequest(false);
        setModalShow(false);
      }
    });
  };

  const handleExport = () => {
    const dataProcessIds = selectedRows.map(item => item.id);
    setOnRequest(true);
    exportDataProcessRequest({
      dispatch,
      params: {
        ...filter,
        filter_data_process_ids: dataProcessIds,
        filter_process_id: apProcessId,
        active: 'active',
        active_ap: true,
        paginate: false
      },
      successCallback: response => {
        setClearSelectedRows(!clearSelectedRows);
        downloadFile(response);
      },
      failureCallback: handleFailureRequest,
      callback: () => {
        setModalShow(false);
        setOnRequest(false);
      }
    });
  };

  const handleActions = (item, action) => {
    switch (action) {
      case 'show':
        setModalWithConfirm(false);
        setModalWithClose(false);
        setModalShow(true);
        setModalTitle('Anticipo');
        setModalBody(<DataProcessInfo item={item} advancePaymentProcess={advancePaymentProcess} />);
        setModalItem('show');
        break;
      case 'edit':
        setSimpleModalShow(true);
        setSimpleModalTitle(`Editar Anticipo de ${item.full_name}`);
        setSimpleModalBody(
          <DataProcessesEdit
            action="items"
            nextMonth={month}
            advancePayment={item}
            employeeId={item.employee_id}
            onSuccess={handleUpdateSuccess}
            apProcessId={apProcessId}
            cancelAction={() => setSimpleModalShow(false)}
          />
        );
        setSize('xl');
        setModalItem('edit');
        break;
      case 'destroy':
        setModalWithConfirm(true);
        setModalWithClose(true);
        setModalShow(true);
        setModalItem(item);
        setModalTitle('Eliminar Anticipo');
        setModalBody('¿Estás seguro que deseas eliminar este proceso de Anticipo?');
        setModalAction(() => handleDeleteDataProcessRequest);
        break;
      case 'activate':
        handleActivateDataProcessRequest(item);
        break;
      case 'absence_details': {
        const absenceDetails = Object.keys(item.absence_details).map(key => {
          const absence = item.absence_details[key];
          const name = key === 'total_absences' ? 'Total de Ausencias' : key;
          return {
            name,
            value: absence
          };
        });
        setSimpleModalShow(true);
        setSimpleModalTitle(`Detalle de Ausencias de ${item.full_name}`);
        setSimpleModalBody(<DataProcessAbsences info={absenceDetails} />);
        setSize('lg');
        break;
      }
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
        break;
    }
  };

  const handleMassAction = action => {
    switch (action) {
      case 'import':
        setSimpleModalShow(true);
        setSimpleModalTitle('Importar anticipos');
        setSimpleModalBody(
          <ImportModal
            onDropUploaded={preImportAdvancePaymentRequest}
            handleTemplate={importTemplateAdvancePaymentRequest}
            onHide={importAdvancePaymentRequest}
            hideModal={handleSimpleModalClose}
            updateData={() => setMoreData(!moreData)}
            handleExceptions={handleImportModalExceptions}
            params={{
              advance_processed: true,
              advance_payment_process_id: apProcessId
            }}
          />
        );
        setSize('md');
        break;
      case 'export':
        setModalWithConfirm(true);
        setModalWithClose(true);
        setModalShow(true);
        setModalTitle('Exportar anticipos');
        setModalBody('¿Estás seguro que deseas descargar los anticipos?');
        setModalAction(() => handleExport);
        break;
      case 'massive_destroy':
        setModalWithConfirm(true);
        setModalWithClose(true);
        setModalShow(true);
        setModalTitle('Eliminar trabajadores procesados');
        setModalBody('¿Estás seguro que deseas eliminar los elementos seleccionados?');
        setModalAction(() => massiveDestroyRequestDataProcesses);
        setClearSelection(true);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const handleSelectedRows = item => {
    setSelectedRows(item.selectedRows);
    setSelectedCount(item.selectedCount);
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'employee_status':
        return { sort_employee_status: 'sort_employee_status' };
      case 'national_identification':
        return { sort_rut: 'sort_rut' };
      case 'full_name':
        return { sort_employee_name: 'sort_employee_name' };
      case 'cost_center':
        return { sort_cost_center: 'sort_cost_center' };
      case 'job_management':
        return { sort_job_management: 'sort_job_management' };
      case 'balance_name':
        return { sort_balance_name: 'sort_balance_name' };
      case 'parsed_amount':
        return { sort_column: 'amount' };
      case 'created_at':
        return { sort_column: 'advance_payment_process_id' };
      case 'data_process_status':
        return { sort_status: 'sort_status' };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      {totalRows > 0 && (
        <Row className="sm-12">
          <Col>
            <Card>
              <Row>
                <Col md={3} xs={12} sm={3} className="mt-3">
                  <LongCard variant="primary" title={totalRows} label="Anticipos a Procesar" />
                </Col>

                <Col md={3} xs={12} sm={3} className="mt-3">
                  <LongCard
                    variant="warning"
                    title={new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(
                      employeesAmount
                    )}
                    label="Monto Mensual de Anticipos"
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      )}

      <Accordion defaultActiveKey="1" className="ml-2 mt-2 mr-2">
        <EmployeeFilterAp formRequest={handleFilterAdvance} />
      </Accordion>

      <ComponentDataTable
        resourceRequest={handleRequest}
        columns={columns(handleActions, ability)}
        data={employees}
        handleSortCase={sortColumnCase}
        massActions={
          <>
            <ImportActions handleClick={handleMassAction} model="AdvancePayment" />
            <DataProcessMassiveAction
              model="AdvancePaymentProcess"
              handleClick={handleMassAction}
              disabled={!selectedCount}
            />
          </>
        }
        totalRows={totalRows}
        moreData={moreData}
        onRequest={onRequest}
        onRowClicked={item => handleActions(item, 'show')}
        onSelectedRowsChange={handleSelectedRows}
        selectedRowCount={selectedCount}
        clearSelectedRows={clearSelection}
        withMassActions
        selectableRows
        conditionalRowStyles={conditionalRowStyles}
        withSearch={false}
      />

      {employees.length > 0 && (
        <Col md={{ span: 2, offset: 10 }} className="mb-2">
          <Button
            className="btn-block"
            type="button"
            disabled={isSubmitting || !totalRows}
            onClick={() => handleProcessData(employees)}
          >
            Procesar
          </Button>
        </Col>
      )}
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleDefaultModalClose}
        handleConfirm={() => modalAction(modalItem)}
        disabled={onRequest}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        withClose={modalWithClose}
        withConfirm={modalWithConfirm}
      />
      <SimpleCenteredModal
        title={simpleModalTitle}
        body={simpleModalBody}
        size={size}
        onHide={handleSimpleModalClose}
        show={simpleModalShow}
      />
      <DefaultModal
        title="Información Relevante"
        body={alertInfo}
        show={alertModalShow}
        withClose={false}
        handleConfirm={() => setAlertModalShow(false)}
        handleClose={() => setAlertModalShow(false)}
        titleBtnSave="Confirmar"
        scrollable
      />
    </>
  );
};

export default EmployeeApDataTable;
