const selectorTypes = [{ label: 'Todos', value: 'all_employees' }];

const hourValues = [
  { label: '50%', value: 'fifty' },
  { label: '100%', value: 'hundred' },
  { label: '150%', value: 'hundred_and_fifty' }
];

const extraHoursTypes = [
  { value: 'begin_journey', label: 'Inicio de Jornada' },
  { value: 'end_journey', label: 'Fin de Jornada' }
];

export { hourValues, selectorTypes, extraHoursTypes };
