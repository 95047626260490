import React, { useState, useEffect } from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { Icon } from '../../components';

const FieldWorkdayReportEarlyOvertimeEffectiveDate = ({ item, initialItemData, canUpdateSettings, settingUpdate }) => {
  const [date, setDate] = useState('');
  const [copyItem, setCopyItem] = useState({ ...item });
  const [copyIntialItemData, setCopyIntialItemData] = useState({ ...initialItemData });
  const [onFocused, setOnFocused] = useState(false);

  useEffect(() => {
    if (item?.textValue) {
      const dateValue = moment(item.textValue, 'DD/MM/YYYY');
      setDate(dateValue.isValid() ? dateValue : '');
      setCopyItem({ ...item });
      setCopyIntialItemData({ ...initialItemData });
    }
  }, [item, initialItemData]);

  const onDateChange = newDate => {
    setDate(newDate);
    const parseDate = moment.isMoment(newDate) ? newDate.format('DD/MM/YYYY') : '';
    setCopyItem({ ...copyItem, textValue: parseDate });
  };

  const onSettingUpdate = () => {
    settingUpdate(copyItem);
    const dateValue = moment(copyItem.textValue, 'DD/MM/YYYY');
    setDate(dateValue.isValid() ? dateValue : '');
    setCopyIntialItemData({ ...copyItem });
  };

  const handleReset = () => {
    if (copyIntialItemData?.textValue) {
      const dateValue = moment(copyIntialItemData.textValue, 'DD/MM/YYYY');
      setDate(dateValue.isValid() ? dateValue : '');
      const textValue = dateValue.isValid() ? dateValue.format('DD/MM/YYYY') : '';
      setCopyItem({ ...copyItem, textValue });
    } else {
      setDate('');
      setCopyItem({ ...copyItem, textValue: '' });
    }
  };

  return (
    <div className="d-flex flex-column justify-content-end mt-2">
      <SingleDatePicker
        date={date}
        onDateChange={newDate => onDateChange(newDate)}
        focused={onFocused}
        onFocusChange={({ focused }) => setOnFocused(focused)}
        id="workday_report_early_overtime_effective_date"
        numberOfMonths={1}
        isOutsideRange={() => false}
        disabled={!canUpdateSettings}
        placeholder="dd/mm/aaaa"
      />

      {copyIntialItemData?.textValue !== copyItem?.textValue && (
        <div style={{ marginLeft: '5px' }}>
          <Icon
            width={22}
            disabled={!canUpdateSettings}
            className="as-button text-warning"
            icon="close-circle"
            onClick={handleReset}
          />
          <Icon
            width={22}
            disabled={!canUpdateSettings}
            className="as-button text-primary"
            icon="checkmark-circle"
            onClick={() => onSettingUpdate()}
          />
        </div>
      )}
    </div>
  );
};

export default FieldWorkdayReportEarlyOvertimeEffectiveDate;
