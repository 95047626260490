import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { updateAdvanceSettingRequest } from '../../requests/advanceSettings';
import { FormikSelect } from '../../components';

const hourValues = [
  { label: '50%', value: 0 },
  { label: '100%', value: 1 },
  { label: '150%', value: 2 }
];

const FormOvertimeRequests = ({ errors, touched, setFieldValue, values, isSubmitting }) => {
  const hourValueIn = values?.hourValueData?.integer_value;

  return (
    <Row className="justify-content-center align-items-center h-100">
      <Col md={11}>
        <Form className="d-flex flex-column justify-content-end mt-2">
          <Row>
            <Col md={6}>
              <Field name="hourValueData[integer_value]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    label="VALOR DE HORAS EXTRAS SOLICITUDES"
                    tooltipText="Valor por defecto horas extras solicitadas"
                    options={hourValues}
                    defaultValue={hourValueIn}
                    onChange={data => setFieldValue(field.name, data ? data.value : 0)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row className="d-flex justify-content-end mt-3 mb-1">
            <Col md={6}>
              <Button type="submit" block variant="primary" disabled={isSubmitting}>
                GUARDAR
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  const { user, currentEmployee } = state.auth;
  return { user, currentEmployee };
};

const initialValues = ({ hourValueData, handleSuccessUpdate }) => {
  return { hourValueData, handleSuccessUpdate };
};

const handleSubmit = (values, { props, setSubmitting }) => {
  const { dispatch } = props;
  const { handleSuccessUpdate } = values;
  const advanceSettingId = values.hourValueData.id;
  setSubmitting(true);
  const hourValueNew = values.hourValueData.integer_value;

  updateAdvanceSettingRequest(advanceSettingId, {
    dispatch,
    params: {
      advance_setting: snakeCaseKeys({ integerValue: hourValueNew })
    },
    formData: true,
    successCallback: () => {
      handleSuccessUpdate('Configuración modificada con éxito');
      setSubmitting(false);
    }
  });
};

const validationSchema = () => {
  return Yup.object().shape({});
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: params => initialValues(params),
      validationSchema,
      handleSubmit
    })(FormOvertimeRequests)
  )
);
