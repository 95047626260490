export default {
  acronym: '',
  agreedSchedule: false,
  beginExceptionalWorkday: '',
  collationTime: true,
  contractIds: [],
  contracts: [],
  description: '',
  exceptionalWorkday: false,
  expirationExceptionalWorkday: '',
  flexibleShift: false,
  imputableColation: false,
  initialTime: '',
  lunchCheck: false,
  lunchTime: '',
  maximumStartTime: '',
  name: '',
  autorenew: '0',
  shiftDaysAttributes: [],
  shiftExtension: 'weekly_extension',
  shiftType: 'weekly',
  startDate: '',
  tolerance: 0,
  uniqueTolerance: true,
  weekends: false,
  workHolidays: false,
  workHours: '',
  workdayNumber: ''
};
