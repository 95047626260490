import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, useFormikContext } from 'formik';
import { debounceIndexBranchOfficesRequest } from '../../../requests/branchOffices';
import { pairValue, resultFetchData } from './utils';
import { InputSelect } from '../Select';

const BranchOfficesFilter = ({ formikValue, params = {} }) => {
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext();
  const [branchOffices, setBranchOffices] = useState([]);
  const fetchBranchOffices = (inputValue, callback) => {
    const request = async () => {
      debounceIndexBranchOfficesRequest({
        dispatch,
        params: {
          actives: true,
          name: inputValue,
          sort_column: 'name',
          paginate: false,
          ...params
        },
        successCallback: data => {
          setBranchOffices(resultFetchData(data));
          callback(resultFetchData(data));
        }
      });
    };
    request();
  };

  return (
    <Field name={formikValue}>
      {({ field }) => (
        <InputSelect
          {...field}
          label="Lugar de Prestación de Servicio"
          placeholder="Seleccionar Lugar de Prestación"
          request={fetchBranchOffices}
          value={values.filterBranchOffice}
          defaultValue={pairValue(branchOffices, values[formikValue])}
          onChange={data => setFieldValue(field.name, data ? data.value : '')}
          isClearable
        />
      )}
    </Field>
  );
};

export default BranchOfficesFilter;
