import moment from 'moment';

const employeeStatuses = [
  { label: 'Todos', value: '' },
  { label: 'Activos', value: true },
  { label: 'Inactivos', value: false }
];

const workSchedules = [
  {
    label: 'Semanal',
    value: [
      moment()
        .subtract(1, 'weeks')
        .format('L'),
      moment().format('L')
    ]
  },
  {
    label: 'Quincenal',
    value: [
      moment()
        .subtract(15, 'days')
        .format('L'),
      moment().format('L')
    ]
  },
  {
    label: 'Mensual',
    value: [
      moment()
        .subtract(1, 'months')
        .format('L'),
      moment().format('L')
    ]
  },
  {
    label: 'Anual',
    value: [
      moment()
        .subtract(1, 'years')
        .format('L'),
      moment().format('L')
    ]
  }
];

export { employeeStatuses, workSchedules };
