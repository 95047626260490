import React from 'react';
import memoize from 'memoize-one';
import moment from 'moment';
import RowActions from './RowActions';

const columns = memoize(clickHandler => [
  {
    name: 'RUT Y NOMBRE',
    selector: 'full_name',
    cell: item => {
      return (
        <>
          <div className="d-flex flex-column" role="presentation">
            <span className="text-muted" data-tag="allowRowEvents">
              {item?.nationalIdentification}
            </span>
            <span data-tag="allowRowEvents">{item?.fullName}</span>
          </div>
        </>
      );
    },
    sortable: true,
    grow: '2'
  },
  {
    name: 'CENTRO DE COSTO',
    selector: 'cost_center',
    cell: item => item?.costCenterName,
    sortable: true,
    grow: '2'
  },
  {
    name: 'ÁREA',
    selector: 'job_management',
    cell: item => item?.jobManagementName,
    sortable: true,
    grow: '2'
  },
  {
    name: 'AÑO',
    selector: 'year',
    cell: item => item?.year,
    sortable: true,
    grow: '1'
  },
  {
    name: 'ESTADO',
    selector: 'status',
    cell: item => item.translateStatus,
    sortable: true,
    grow: '1'
  },
  {
    name: 'FECHA DE ESTADO',
    selector: 'updated_at',
    cell: item => moment(item.updatedAt).format('DD/MM/YYYY HH:mm'),
    sortable: true,
    grow: '2',
    center: true,
    minWidth: '123px'
  },
  {
    name: 'ACCIONES',
    cell: item => (
      <>
        <RowActions handleClick={clickHandler} item={item} />
      </>
    ),
    grow: '2',
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    center: true,
    minWidth: '150px'
  }
]);

export default columns;
